import * as Sentry from '@sentry/react'
import { env } from 'config/env'
import mixpanel from 'mixpanel-browser'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './app'
import './i18n'
import './index.css'

if (env.SENTRY_DNS) {
  Sentry.init({
    dsn: env.SENTRY_DNS,
    release: env.VERSION ? env.VERSION : '',
    integrations: [Sentry.browserTracingIntegration()],
    environment: env.SENTRY_ENV,
    tracesSampleRate: 1, // 100%
  })
}
mixpanel.init(env.MIXPANEL_TOKEN)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
