// @generated by protoc-gen-es v2.2.3 with parameter "target=ts"
// @generated from file proto/auth/auth.proto (package auth, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage, GenService } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import { file_google_protobuf_wrappers } from "@bufbuild/protobuf/wkt";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file proto/auth/auth.proto.
 */
export const file_proto_auth_auth: GenFile = /*@__PURE__*/
  fileDesc("ChVwcm90by9hdXRoL2F1dGgucHJvdG8SBGF1dGgiLQoVR2V0U2hvcEJ5VG9rZW5SZXF1ZXN0EhQKDHNlc3Npb25Ub2tlbhgBIAEoCSJBChZHZXRTaG9wQnlUb2tlblJlc3BvbnNlEhAKCHNob3BOYW1lGAEgASgJEhUKDXRva2VuVmVyaWZpZWQYAiABKAgiWAoNU2lnbnVwUmVxdWVzdBINCgVlbWFpbBgBIAEoCRIQCghwYXNzd29yZBgCIAEoCRIUCgxzZXNzaW9uVG9rZW4YAyABKAkSEAoIbGFuZ3VhZ2UYBCABKAkiEAoOU2lnbnVwUmVzcG9uc2UiaQoTTWVtYmVyU2lnbnVwUmVxdWVzdBINCgVlbWFpbBgBIAEoCRIQCghwYXNzd29yZBgCIAEoCRIQCghzaG9wTmFtZRgDIAEoCRINCgV0b2tlbhgEIAEoCRIQCghsYW5ndWFnZRgFIAEoCSIWChRNZW1iZXJTaWdudXBSZXNwb25zZSIeChxWZXJpZnlBcHBJbnN0YWxsYXRpb25SZXF1ZXN0Ik4KHVZlcmlmeUFwcEluc3RhbGxhdGlvblJlc3BvbnNlEi0KCWluc3RhbGxlZBgBIAEoCzIaLmdvb2dsZS5wcm90b2J1Zi5Cb29sVmFsdWUywAIKC0F1dGhTZXJ2aWNlEk0KDkdldFNob3BCeVRva2VuEhsuYXV0aC5HZXRTaG9wQnlUb2tlblJlcXVlc3QaHC5hdXRoLkdldFNob3BCeVRva2VuUmVzcG9uc2UiABI1CgZTaWdudXASEy5hdXRoLlNpZ251cFJlcXVlc3QaFC5hdXRoLlNpZ251cFJlc3BvbnNlIgASRwoMTWVtYmVyU2lnbnVwEhkuYXV0aC5NZW1iZXJTaWdudXBSZXF1ZXN0GhouYXV0aC5NZW1iZXJTaWdudXBSZXNwb25zZSIAEmIKFVZlcmlmeUFwcEluc3RhbGxhdGlvbhIiLmF1dGguVmVyaWZ5QXBwSW5zdGFsbGF0aW9uUmVxdWVzdBojLmF1dGguVmVyaWZ5QXBwSW5zdGFsbGF0aW9uUmVzcG9uc2UiAEIqWihlY3Bvd2VyLmlvL2FwaS1zZXJ2ZXIvcHJvdG8vYXV0aDthdXRoX3BiYgZwcm90bzM", [file_google_protobuf_wrappers]);

/**
 * GetShopByToken
 *
 * @generated from message auth.GetShopByTokenRequest
 */
export type GetShopByTokenRequest = Message<"auth.GetShopByTokenRequest"> & {
  /**
   * @generated from field: string sessionToken = 1;
   */
  sessionToken: string;
};

/**
 * Describes the message auth.GetShopByTokenRequest.
 * Use `create(GetShopByTokenRequestSchema)` to create a new message.
 */
export const GetShopByTokenRequestSchema: GenMessage<GetShopByTokenRequest> = /*@__PURE__*/
  messageDesc(file_proto_auth_auth, 0);

/**
 * @generated from message auth.GetShopByTokenResponse
 */
export type GetShopByTokenResponse = Message<"auth.GetShopByTokenResponse"> & {
  /**
   * @generated from field: string shopName = 1;
   */
  shopName: string;

  /**
   * @generated from field: bool tokenVerified = 2;
   */
  tokenVerified: boolean;
};

/**
 * Describes the message auth.GetShopByTokenResponse.
 * Use `create(GetShopByTokenResponseSchema)` to create a new message.
 */
export const GetShopByTokenResponseSchema: GenMessage<GetShopByTokenResponse> = /*@__PURE__*/
  messageDesc(file_proto_auth_auth, 1);

/**
 * Signup
 *
 * @generated from message auth.SignupRequest
 */
export type SignupRequest = Message<"auth.SignupRequest"> & {
  /**
   * @generated from field: string email = 1;
   */
  email: string;

  /**
   * @generated from field: string password = 2;
   */
  password: string;

  /**
   * @generated from field: string sessionToken = 3;
   */
  sessionToken: string;

  /**
   * @generated from field: string language = 4;
   */
  language: string;
};

/**
 * Describes the message auth.SignupRequest.
 * Use `create(SignupRequestSchema)` to create a new message.
 */
export const SignupRequestSchema: GenMessage<SignupRequest> = /*@__PURE__*/
  messageDesc(file_proto_auth_auth, 2);

/**
 * @generated from message auth.SignupResponse
 */
export type SignupResponse = Message<"auth.SignupResponse"> & {
};

/**
 * Describes the message auth.SignupResponse.
 * Use `create(SignupResponseSchema)` to create a new message.
 */
export const SignupResponseSchema: GenMessage<SignupResponse> = /*@__PURE__*/
  messageDesc(file_proto_auth_auth, 3);

/**
 * MemberSignup
 *
 * @generated from message auth.MemberSignupRequest
 */
export type MemberSignupRequest = Message<"auth.MemberSignupRequest"> & {
  /**
   * @generated from field: string email = 1;
   */
  email: string;

  /**
   * @generated from field: string password = 2;
   */
  password: string;

  /**
   * @generated from field: string shopName = 3;
   */
  shopName: string;

  /**
   * @generated from field: string token = 4;
   */
  token: string;

  /**
   * @generated from field: string language = 5;
   */
  language: string;
};

/**
 * Describes the message auth.MemberSignupRequest.
 * Use `create(MemberSignupRequestSchema)` to create a new message.
 */
export const MemberSignupRequestSchema: GenMessage<MemberSignupRequest> = /*@__PURE__*/
  messageDesc(file_proto_auth_auth, 4);

/**
 * @generated from message auth.MemberSignupResponse
 */
export type MemberSignupResponse = Message<"auth.MemberSignupResponse"> & {
};

/**
 * Describes the message auth.MemberSignupResponse.
 * Use `create(MemberSignupResponseSchema)` to create a new message.
 */
export const MemberSignupResponseSchema: GenMessage<MemberSignupResponse> = /*@__PURE__*/
  messageDesc(file_proto_auth_auth, 5);

/**
 * VerifyAppInstallation
 *
 * @generated from message auth.VerifyAppInstallationRequest
 */
export type VerifyAppInstallationRequest = Message<"auth.VerifyAppInstallationRequest"> & {
};

/**
 * Describes the message auth.VerifyAppInstallationRequest.
 * Use `create(VerifyAppInstallationRequestSchema)` to create a new message.
 */
export const VerifyAppInstallationRequestSchema: GenMessage<VerifyAppInstallationRequest> = /*@__PURE__*/
  messageDesc(file_proto_auth_auth, 6);

/**
 * @generated from message auth.VerifyAppInstallationResponse
 */
export type VerifyAppInstallationResponse = Message<"auth.VerifyAppInstallationResponse"> & {
  /**
   * @generated from field: google.protobuf.BoolValue installed = 1;
   */
  installed?: boolean;
};

/**
 * Describes the message auth.VerifyAppInstallationResponse.
 * Use `create(VerifyAppInstallationResponseSchema)` to create a new message.
 */
export const VerifyAppInstallationResponseSchema: GenMessage<VerifyAppInstallationResponse> = /*@__PURE__*/
  messageDesc(file_proto_auth_auth, 7);

/**
 * @generated from service auth.AuthService
 */
export const AuthService: GenService<{
  /**
   * @generated from rpc auth.AuthService.GetShopByToken
   */
  getShopByToken: {
    methodKind: "unary";
    input: typeof GetShopByTokenRequestSchema;
    output: typeof GetShopByTokenResponseSchema;
  },
  /**
   * @generated from rpc auth.AuthService.Signup
   */
  signup: {
    methodKind: "unary";
    input: typeof SignupRequestSchema;
    output: typeof SignupResponseSchema;
  },
  /**
   * @generated from rpc auth.AuthService.MemberSignup
   */
  memberSignup: {
    methodKind: "unary";
    input: typeof MemberSignupRequestSchema;
    output: typeof MemberSignupResponseSchema;
  },
  /**
   * @generated from rpc auth.AuthService.VerifyAppInstallation
   */
  verifyAppInstallation: {
    methodKind: "unary";
    input: typeof VerifyAppInstallationRequestSchema;
    output: typeof VerifyAppInstallationResponseSchema;
  },
}> = /*@__PURE__*/
  serviceDesc(file_proto_auth_auth, 0);

