import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ChromeReaderModeOutlinedIcon from '@mui/icons-material/ChromeReaderModeOutlined'
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined'
import { Box, Button, Divider, IconButton, Skeleton, SwipeableDrawer, Typography } from '@mui/material'
import { CustomerSegmentState } from 'features/customerSegments/types/types'
import { useCurrency } from 'hooks/useCurrency'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { formatDate } from 'utils/timeUtil'

const DRAWER_WIDTH = 360

type Props = {
  open: boolean
  handleClose: () => void
  handleOpen: () => void
  segment: CustomerSegmentState | undefined
}

export const SegmentDrawer: FC<Props> = ({ open, handleClose, handleOpen, segment }) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { formatCurrency } = useCurrency()

  return (
    <SwipeableDrawer anchor='right' open={open} onOpen={handleOpen} onClose={handleClose}>
      <Box width={DRAWER_WIDTH} padding='12px' role='presentation'>
        <Box display='flex' justifyContent='space-between' marginBottom='8px'>
          <IconButton onClick={handleClose} size='small' sx={{ borderRadius: '8px' }}>
            <ArrowBackIcon fontSize='small' />
          </IconButton>
        </Box>

        <Box padding='12px'>
          <Box marginBottom='12px'>
            <Typography>{segment?.name}</Typography>

            {segment?.description && (
              <Typography variant='body2' color='text.secondary' sx={{ marginTop: '4px' }}>
                {segment.description}
              </Typography>
            )}
          </Box>

          <Box display='flex' justifyContent='space-between'>
            <Typography variant='body2' color='textSecondary'>
              {t('features.customerSegments.group.canvasView.createdAt')}
            </Typography>
            <Typography variant='body2'>{segment && formatDate(segment.createdAt, i18n.language)}</Typography>
          </Box>

          <Divider sx={{ marginY: '20px' }} />

          <Box>
            {[
              { context: 'count', value: segment?.metrics?.count.toLocaleString() },
              { context: 'repeaterRate', value: segment && segment.metrics && `${(segment.metrics.repeaterRate * 100).toFixed(1)}%` },
              { context: 'ltv', value: segment && segment.metrics && formatCurrency(segment.metrics.ltv) },
              { context: 'avgOrderValue', value: segment && segment.metrics && formatCurrency(segment.metrics.avgOrderValue) },
              { context: 'avgOrderCount', value: segment && segment.metrics && segment.metrics.avgOrderCount.toFixed(2) },
              { context: 'avgDaysBetweenOrders', value: segment && segment.metrics && segment.metrics.avgDaysBetweenOrders.toFixed(1) },
            ].map((item, index) => (
              <Box key={index} display='flex' justifyContent='space-between' marginBottom='16px'>
                <Typography variant='body2' color='textSecondary'>
                  {t('common.customerMetrics.title', { context: item.context })}
                </Typography>
                <Typography variant='body2'>{item.value || <Skeleton width='48px' />}</Typography>
              </Box>
            ))}
          </Box>

          <Divider sx={{ marginBottom: '18px' }} />

          <Box display='flex' gap={1.5}>
            <Button
              variant='outlined'
              onClick={() => {
                if (!segment) return
                const to = generatePath(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_DETAIL, { id: segment.id })
                navigate(to, { state: { from: location.pathname } })
              }}
              startIcon={<ChromeReaderModeOutlinedIcon fontSize='small' />}
              fullWidth
            >
              {t('features.customerSegments.group.canvasView.viewDetail')}
            </Button>
            <Button
              variant='outlined'
              onClick={() => navigate(`${AUTHORIZED_ROUTE.INSIGHTS}?customer_segment_id=${segment?.id}`)}
              startIcon={<EmojiObjectsOutlinedIcon fontSize='small' />}
              fullWidth
            >
              {t('features.customerSegments.group.canvasView.viewInsights')}
            </Button>
          </Box>
        </Box>
      </Box>
    </SwipeableDrawer>
  )
}
