import { PageContentWrapper } from '@/components/pageContentWrapper'
import { PageHeader } from '@/components/pageHeader'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Skeleton } from '@/components/ui/skeleton'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { EmptyState } from 'components/emptyState'
import { SkeletonTable } from 'components/skeletonTable'
import { billingStatus } from 'config/plan'
import dayjs from 'dayjs'
import { OP_CONTAIN_ANY, OP_NOT_CONTAIN } from 'features/customerSegments/querySetForm/schema/const'
import { convertFormToQuerySet } from 'features/customerSegments/querySetForm/schema/converter'
import { QuerySetFormState } from 'features/customerSegments/querySetForm/schema/querySetFormSchema'
import { getIdToken } from 'firebase/auth'
import { query } from 'firebase/firestore'
import { ShopBillingStatus } from 'gen/firestore'
import { ProductService } from 'gen/proto/product/product_pb'
import { useAccount } from 'hooks/useAccount'
import { useAuthUser } from 'hooks/useAuthUser'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useCollection } from 'hooks/useFirestoreData'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useActionTracker } from 'hooks/useMixpanel'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { ExternalLinkIcon, UserPlus } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, generatePath } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'

const DEFAULT_VISIBLE_COUNT = 3

type PreferredSegment = {
  id: string
  name: string
  customerCount: number
  ratio: number
  isVisible: boolean
}

type RelevantProduct = {
  name: string
  customerCount: number
  isVisible: boolean
}

export const ProductInsights = () => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { account, shop } = useAccount()
  const shopBillingStatus = billingStatus(shop, dayjs())
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { dispatch } = useActionTracker()
  const productService = useGrpcClient(ProductService)

  const { dimensionOptionsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data } = useCollection(query(dimensionOptionsRef))

  const products = useMemo(() => {
    if (!data) return undefined
    const productDoc = data.find((doc) => doc.dimension === 'product')
    return productDoc ? productDoc.options : []
  }, [data])

  const [loading, setLoading] = useState(false)
  const [targetProduct, setTargetProduct] = useState<string>()
  const [preferredSegments, setPreferredSegments] = useState<PreferredSegment[]>()
  const [relevantProducts, setRelevantProducts] = useState<RelevantProduct[]>()

  useEffect(() => {
    if (!products || products.length === 0) return
    setTargetProduct(products[0])
  }, [products])

  useEffect(() => {
    if (!targetProduct) return
    dispatch('SearchProductInsight', { product: targetProduct })

    const fetchData = async () => {
      setLoading(true)
      try {
        const token = await getIdToken(authUser!)
        const [preferredSegmentsResponse, relevantProductsResponse] = await Promise.all([
          productService.getPreferredSegments({ name: targetProduct }, { headers: { Authorization: `Bearer ${token}` } }),
          productService.getRelevantProducts({ name: targetProduct }, { headers: { Authorization: `Bearer ${token}` } }),
        ])
        setPreferredSegments(
          preferredSegmentsResponse.segments.map((segment, i) => ({
            id: segment.id,
            name: segment.name,
            customerCount: Number(segment.customerCount),
            ratio: Number(segment.ratio.toFixed(1)),
            isVisible: shopBillingStatus !== ShopBillingStatus.free || i < DEFAULT_VISIBLE_COUNT,
          }))
        )
        setRelevantProducts(
          relevantProductsResponse.products.map((product, i) => ({
            name: product.name,
            customerCount: Number(product.customerCount),
            isVisible: shopBillingStatus !== ShopBillingStatus.free || i < DEFAULT_VISIBLE_COUNT,
          }))
        )
      } catch (err) {
        enqueueSnackbar(t('features.productInsights.messageError'), { severity: 'error' })
        notifySentry(err)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetProduct])

  const generateQuerySet = (targetProduct: string, relevantProduct: string): QuerySetFormState => {
    return {
      join_operator: 'and',
      query_subsets: [
        {
          join_operator: 'and',
          query_items: [
            { dimension: 'product', operator: OP_CONTAIN_ANY, value: [relevantProduct], filters: [] },
            { dimension: 'product', operator: OP_NOT_CONTAIN, value: [targetProduct], filters: [] },
          ],
        },
      ],
    }
  }

  return (
    <>
      <PageHeader
        title={t('features.productInsights.title')}
        origin={{
          title: t('features.productInsights.insights'),
          path: AUTHORIZED_ROUTE.INSIGHTS,
        }}
        menuComponent={
          <Select
            value={targetProduct}
            disabled={!products}
            onValueChange={(value) => {
              setTargetProduct(value)
            }}
          >
            <SelectTrigger className='w-[280px]'>
              <SelectValue placeholder={t('features.productInsights.selectProduct')} />
            </SelectTrigger>
            <SelectContent>
              {products?.map((product) => (
                <SelectItem key={product} value={product}>
                  {product}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        }
      />

      <PageContentWrapper>
        <div className='grid grid-cols-1 sm:grid-cols-2 items-stretch gap-6'>
          <div>
            <Card>
              <CardHeader>
                <CardTitle>{t('features.productInsights.preferredSegmentsTitle', { product: targetProduct })}</CardTitle>
                <CardDescription>{t('features.productInsights.preferredSegmentsDesc')}</CardDescription>
              </CardHeader>
              <CardContent>
                {loading ? (
                  <SkeletonTable rowCount={5} columnCount={3} />
                ) : !preferredSegments ? (
                  <EmptyState title={t('features.productInsights.preferredSegmentsUndefined')} />
                ) : preferredSegments.length === 0 ? (
                  <EmptyState title={t('features.productInsights.preferredSegmentsEmpty')} />
                ) : (
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>{t('features.productInsights.segment')}</TableHead>
                        <TableHead className='text-center'>{t('features.productInsights.customerCount')}</TableHead>
                        <TableHead className='text-center'>{t('features.productInsights.ratio')}</TableHead>
                        <TableHead />
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {preferredSegments.map((segment) => (
                        <TableRow key={segment.id}>
                          <TableCell>
                            {segment.isVisible ? (
                              segment.name
                            ) : (
                              <Tooltip>
                                <TooltipTrigger>
                                  <Skeleton className='w-[120px] h-[8px] rounded-full' />
                                </TooltipTrigger>
                                <TooltipContent>{t('features.productInsights.freeDesciption')}</TooltipContent>
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell className='text-center'>{segment.customerCount.toLocaleString()}</TableCell>
                          <TableCell className='text-center'>{segment.ratio * 100}%</TableCell>
                          <TableCell className='text-right'>
                            {segment.isVisible ? (
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <Button
                                    variant='ghost'
                                    size='icon'
                                    onClick={() => {
                                      dispatch('ClickPreferredSegment', { product: targetProduct, segment: segment.name })
                                      const to = generatePath(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_DETAIL, { id: segment.id })
                                      window.open(to, '_blank')
                                    }}
                                  >
                                    <ExternalLinkIcon />
                                  </Button>
                                </TooltipTrigger>
                                <TooltipContent>{t('features.productInsights.openInNew')}</TooltipContent>
                              </Tooltip>
                            ) : (
                              <Button variant='ghost' size='icon' disabled>
                                <ExternalLinkIcon />
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </CardContent>
            </Card>
          </div>

          <div>
            <Card>
              <CardHeader>
                <CardTitle>{t('features.productInsights.relevantProductsTitle', { product: targetProduct })}</CardTitle>
                <CardDescription>{t('features.productInsights.relevantProductsDesc')}</CardDescription>
              </CardHeader>
              <CardContent>
                {loading ? (
                  <SkeletonTable rowCount={5} columnCount={3} />
                ) : !relevantProducts ? (
                  <EmptyState title={t('features.productInsights.relevantProductsUndefined')} />
                ) : relevantProducts.length === 0 ? (
                  <EmptyState title={t('features.productInsights.relevantProductsEmpty')} />
                ) : (
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>{t('features.productInsights.product')}</TableHead>
                        <TableHead className='text-center'>{t('features.productInsights.customerCount')}</TableHead>
                        <TableHead />
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {relevantProducts.map((product) => (
                        <TableRow key={product.name}>
                          <TableCell>
                            {product.isVisible ? (
                              product.name
                            ) : (
                              <Tooltip>
                                <TooltipTrigger>
                                  <Skeleton className='w-[120px] h-[8px] rounded-full' />
                                </TooltipTrigger>
                                <TooltipContent>{t('features.productInsights.freeDesciption')}</TooltipContent>
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell className='text-center'>{product.customerCount.toLocaleString()}</TableCell>
                          <TableCell className='text-right'>
                            {product.isVisible ? (
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <Button
                                    variant='ghost'
                                    size='icon'
                                    onClick={() => {
                                      dispatch('ClickRelevantProduct', { targetProduct: targetProduct, relevantProduct: product.name })
                                      const querySet = generateQuerySet(targetProduct!, product.name)
                                      const querySetJsonString = JSON.stringify(convertFormToQuerySet(querySet as QuerySetFormState))
                                      window.open(
                                        `${AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_CREATE}?${createSearchParams({
                                          querySet: encodeURIComponent(querySetJsonString),
                                        }).toString()}`,
                                        '_blank'
                                      )
                                    }}
                                  >
                                    <UserPlus size='xs' />
                                  </Button>
                                </TooltipTrigger>
                                <TooltipContent>{t('features.productInsights.createSegment')}</TooltipContent>
                              </Tooltip>
                            ) : (
                              <Button variant='ghost' size='icon' disabled>
                                <UserPlus />
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </CardContent>
            </Card>
          </div>
        </div>
      </PageContentWrapper>
    </>
  )
}
