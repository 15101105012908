// @generated by protoc-gen-es v2.2.3 with parameter "target=ts"
// @generated from file proto/order/order.proto (package order, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage, GenService } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file proto/order/order.proto.
 */
export const file_proto_order_order: GenFile = /*@__PURE__*/
  fileDesc("Chdwcm90by9vcmRlci9vcmRlci5wcm90bxIFb3JkZXIiGAoKR2V0UmVxdWVzdBIKCgJpZBgBIAEoCSLxAQoLR2V0UmVzcG9uc2USCgoCaWQYASABKAkSDAoEbmFtZRgCIAEoCRISCgpvcmRlclZhbHVlGAMgASgBEhEKCW9yZGVyZWRBdBgEIAEoCRIQCghwcm9kdWN0cxgFIAMoCRIMCgR0YWdzGAYgAygJEhAKCHJlZmVycmVyGAcgASgJEhMKC2xhbmRpbmdQYWdlGAggASgJEg8KB2NoYW5uZWwYCSABKAkSDgoGY291cG9uGAogASgJEhEKCXV0bVNvdXJjZRgLIAEoCRIRCgl1dG1NZWRpdW0YDCABKAkSEwoLdXRtQ2FtcGFpZ24YDSABKAkyPgoMT3JkZXJTZXJ2aWNlEi4KA0dldBIRLm9yZGVyLkdldFJlcXVlc3QaEi5vcmRlci5HZXRSZXNwb25zZSIAQixaKmVjcG93ZXIuaW8vYXBpLXNlcnZlci9wcm90by9vcmRlcjtvcmRlcl9wYmIGcHJvdG8z");

/**
 * Get
 *
 * @generated from message order.GetRequest
 */
export type GetRequest = Message<"order.GetRequest"> & {
  /**
   * @generated from field: string id = 1;
   */
  id: string;
};

/**
 * Describes the message order.GetRequest.
 * Use `create(GetRequestSchema)` to create a new message.
 */
export const GetRequestSchema: GenMessage<GetRequest> = /*@__PURE__*/
  messageDesc(file_proto_order_order, 0);

/**
 * @generated from message order.GetResponse
 */
export type GetResponse = Message<"order.GetResponse"> & {
  /**
   * @generated from field: string id = 1;
   */
  id: string;

  /**
   * @generated from field: string name = 2;
   */
  name: string;

  /**
   * @generated from field: double orderValue = 3;
   */
  orderValue: number;

  /**
   * format: "2006-01-02 15:04:05"
   *
   * @generated from field: string orderedAt = 4;
   */
  orderedAt: string;

  /**
   * @generated from field: repeated string products = 5;
   */
  products: string[];

  /**
   * @generated from field: repeated string tags = 6;
   */
  tags: string[];

  /**
   * @generated from field: string referrer = 7;
   */
  referrer: string;

  /**
   * @generated from field: string landingPage = 8;
   */
  landingPage: string;

  /**
   * @generated from field: string channel = 9;
   */
  channel: string;

  /**
   * @generated from field: string coupon = 10;
   */
  coupon: string;

  /**
   * @generated from field: string utmSource = 11;
   */
  utmSource: string;

  /**
   * @generated from field: string utmMedium = 12;
   */
  utmMedium: string;

  /**
   * @generated from field: string utmCampaign = 13;
   */
  utmCampaign: string;
};

/**
 * Describes the message order.GetResponse.
 * Use `create(GetResponseSchema)` to create a new message.
 */
export const GetResponseSchema: GenMessage<GetResponse> = /*@__PURE__*/
  messageDesc(file_proto_order_order, 1);

/**
 * @generated from service order.OrderService
 */
export const OrderService: GenService<{
  /**
   * @generated from rpc order.OrderService.Get
   */
  get: {
    methodKind: "unary";
    input: typeof GetRequestSchema;
    output: typeof GetResponseSchema;
  },
}> = /*@__PURE__*/
  serviceDesc(file_proto_order_order, 0);

