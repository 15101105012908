import { PageHeader } from '@/components/pageHeader'
import { Button } from '@/components/ui/button'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { UpgradeRecommendDialog } from 'components/upgradeRecommendDialog'
import { billingStatus, customerSegmentCountLimit } from 'config/plan'
import dayjs from 'dayjs'
import { DimensionOptionsProvider } from 'features/customerSegments/context/dimensionOptionsContext'
import { defaultQuerySet } from 'features/customerSegments/querySetForm/defalutValues'
import {
  convertFormToQuerySet,
  convertQuerySetToForm,
  convertStringToApiQuerySetState,
} from 'features/customerSegments/querySetForm/schema/converter'
import { useQuerySetForm } from 'features/customerSegments/querySetForm/useQuerySetForm'
import { query } from 'firebase/firestore'
import { ShopBillingStatus } from 'gen/firestore'
import { useAccount } from 'hooks/useAccount'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useCollectionSubscription } from 'hooks/useFirestoreData'
import { useActionTracker } from 'hooks/useMixpanel'
import { Link } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { CreateDialog } from './components/createDialog'
import { PreviewMetricsDialog } from './components/previewMetricsDialog'
import { PageContentWrapper } from '@/components/pageContentWrapper'

export const Create = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { dispatch } = useActionTracker()

  const queryParams = new URLSearchParams(location.search)
  const querySetParam = queryParams.get('querySet')
  const nameParam = queryParams.get('name')
  const descriptionParam = queryParams.get('description')
  const defaultQuerySetFromParam = querySetParam && convertQuerySetToForm(convertStringToApiQuerySetState(decodeURIComponent(querySetParam)))

  const { account, shop } = useAccount()
  const shopBillingStatus = billingStatus(shop, dayjs())
  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: customerSegments } = useCollectionSubscription(query(customerSegmentsRef))

  const { QuerySetForm, querySetFormMethods } = useQuerySetForm({
    mode: 'onChange',
    defaultValues: defaultQuerySetFromParam || defaultQuerySet,
  })

  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [previewMetricsDialogOpen, setPreviewMetricsDialogOpen] = useState(false)
  const [upgradeRecommendDialogOpen, setUpgradeRecommendDialogOpen] = useState(false)

  const handleMetricsDialogOpen = async () => {
    const ok = await querySetFormMethods.trigger()
    if (ok) {
      setPreviewMetricsDialogOpen(true)
      dispatch('PreviewSegmentMetrics')
    }
  }

  const handleCopyLink = async () => {
    const ok = await querySetFormMethods.trigger()
    if (ok) {
      const querySetFormValues = querySetFormMethods.getValues()
      const querySet = convertFormToQuerySet(querySetFormValues)
      const url = `${window.location.origin}${AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_CREATE}?querySet=${encodeURIComponent(JSON.stringify(querySet))}`
      navigator.clipboard.writeText(url)
      enqueueSnackbar(t('features.customerSegments.create.messageLinkCopied'), { severity: 'success' })
    }
  }

  const handleCreateDialogOpen = async () => {
    if (!customerSegments) return
    if (shopBillingStatus === ShopBillingStatus.free && customerSegments.length >= customerSegmentCountLimit(shopBillingStatus)) {
      setUpgradeRecommendDialogOpen(true)
      return
    }
    const ok = await querySetFormMethods.trigger()
    if (ok) setCreateDialogOpen(true)
  }

  return (
    <>
      <PageHeader
        title={t('features.customerSegments.create.title')}
        origin={{ title: t('features.customerSegments.create.segments'), path: AUTHORIZED_ROUTE.CUSTOMER_SEGMENTS }}
        menuComponent={
          <>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button size='sm' variant='ghost' onClick={handleCopyLink}>
                  <Link />
                </Button>
              </TooltipTrigger>
              <TooltipContent>{t('features.customerSegments.create.copyLink')}</TooltipContent>
            </Tooltip>
            <Button size='sm' variant='outline' onClick={handleMetricsDialogOpen} className='ml-3'>
              {t('features.customerSegments.create.previewMetrics')}
            </Button>
            <Button size='sm' onClick={handleCreateDialogOpen} className='ml-3'>
              {t('features.customerSegments.create.submit')}
            </Button>
          </>
        }
      />

      <PageContentWrapper>
        <DimensionOptionsProvider>
          <QuerySetForm />
        </DimensionOptionsProvider>
      </PageContentWrapper>

      {previewMetricsDialogOpen && (
        <PreviewMetricsDialog
          open={previewMetricsDialogOpen}
          querySetFormMethods={querySetFormMethods}
          handleClose={() => setPreviewMetricsDialogOpen(false)}
        />
      )}

      {createDialogOpen && (
        <CreateDialog
          open={createDialogOpen}
          querySetFormMethods={querySetFormMethods}
          name={decodeURIComponent(nameParam || '')}
          description={decodeURIComponent(descriptionParam || '')}
          handleClose={() => setCreateDialogOpen(false)}
        />
      )}

      {upgradeRecommendDialogOpen && (
        <UpgradeRecommendDialog
          open={upgradeRecommendDialogOpen}
          handleClose={() => setUpgradeRecommendDialogOpen(false)}
          referrer='customerSegmentCreate'
        />
      )}
    </>
  )
}
