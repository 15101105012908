import { env } from 'config/env'
import { useAccount } from 'hooks/useAccount'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { DialogWrapper } from './dialogWrapper'

type Props = {
  open: boolean
}

// AppUninstalledDialog is shown when the user has an account but has not installed the Shopify App.
export const AppUninstalledDialog: FC<Props> = ({ open }) => {
  const { t } = useTranslation()
  const { account } = useAccount()

  return (
    <DialogWrapper
      open={open}
      handleClose={() => {}}
      title={t('common.appUninstalledDialog.title')}
      mainContent={<p className='text-sm'>{t('common.appUninstalledDialog.description')}</p>}
      onSubmit={() => {
        let url = env.SHOPIFY_APP_STORE_URL
        if (account.language === 'ja') url += '?locale=ja'
        window.open(url, '_blank')
      }}
      loading={false}
      cancelButtonText={''}
      submitButtonText={t('common.appUninstalledDialog.submit')}
    />
  )
}
