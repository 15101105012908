import { CustomDateRange, DateRangePicker } from '@/components/dateRangePicker'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { UpgradeRecommendDialog } from '@/components/upgradeRecommendDialog'
import { billingStatus } from '@/config/plan'
import { ShopBillingStatus } from '@/gen/firestore'
import dayjs from 'dayjs'
import { CustomerSegmentState } from 'features/customerSegments/types/types'
import { useAccount } from 'hooks/useAccount'
import { useCsvDownload } from 'hooks/useCsvDownload'
import { Download, Info } from 'lucide-react'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDate } from 'utils/timeUtil'
import { MetricsHistory } from '../types/types'
import { MetricsChartCard } from './components/metricsChartCard'

type Props = {
  customerSegment: CustomerSegmentState | undefined
  metricsHistory: MetricsHistory[] | undefined
  loading: boolean
  handleTimeRangeChange: (startDate: string, endDate: string) => void
}

export enum MetricsIndex {
  count = 'count',
  repeaterRate = 'repeaterRate',
  ltv = 'ltv',
  avgOrderValue = 'avgOrderValue',
  avgOrderCount = 'avgOrderCount',
  avgDaysBetweenOrders = 'avgDaysBetweenOrders',
}

export const Metrics: FC<Props> = ({ customerSegment, metricsHistory, loading, handleTimeRangeChange }) => {
  const { t, i18n } = useTranslation()
  const { shop } = useAccount()
  const shopBillingStatus = billingStatus(shop, dayjs())

  const [upgradeRecommendDialogOpen, setUpgradeRecommendDialogOpen] = useState(false)

  const { handleDownload, loading: downloadLoading } = useCsvDownload({
    data: metricsHistory
      ? metricsHistory.map((item) => ({
          date: item.date,
          ...item.metrics,
        }))
      : [],
    headers: [
      t('features.customerSegments.detail.metrics.date'),
      t('common.customerMetrics.title_count'),
      t('common.customerMetrics.title_repeaterRate'),
      t('common.customerMetrics.title_ltv'),
      t('common.customerMetrics.title_avgOrderValue'),
      t('common.customerMetrics.title_avgOrderCount'),
      t('common.customerMetrics.title_avgDaysBetweenOrders'),
    ],
    fileName: t('features.customerSegments.detail.metrics.downloadFileName', {
      name: customerSegment?.name,
      date: formatDate(dayjs(), i18n.language),
    }),
    page: 'customerSegment_metricsHistory',
  })

  return (
    <>
      <div className='flex flex-col gap-4'>
        <div className='flex items-center justify-between'>
          <DateRangePicker
            defaultDateRange={{ from: dayjs().subtract(29, 'day').toDate(), to: dayjs().toDate() }}
            defaultCustomDateRange={CustomDateRange.last30Days}
            onDateChange={(date) => {
              if (shopBillingStatus === ShopBillingStatus.free) {
                setUpgradeRecommendDialogOpen(true)
                return
              }
              handleTimeRangeChange(dayjs(date.from).format('YYYY-MM-DD'), dayjs(date.to).format('YYYY-MM-DD'))
            }}
            timezone={shop.timezone}
          />
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant='ghost'
                size='icon'
                disabled={!customerSegment || !metricsHistory || downloadLoading}
                onClick={() => {
                  if (shopBillingStatus === ShopBillingStatus.free) {
                    setUpgradeRecommendDialogOpen(true)
                    return
                  }
                  handleDownload()
                }}
              >
                <Download />
              </Button>
            </TooltipTrigger>
            <TooltipContent>{t('features.customerSegments.detail.metrics.download')}</TooltipContent>
          </Tooltip>
        </div>

        {metricsHistory && metricsHistory.length === 1 && (
          <Card className='p-6'>
            <div className='flex items-center gap-2'>
              <Info className='h-4 w-4' />
              <p className='text-sm'>{t('features.customerSegments.detail.metrics.alert')}</p>
            </div>
          </Card>
        )}

        <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4'>
          {Object.values(MetricsIndex).map((metric, index) => (
            <MetricsChartCard key={index} metricsIndex={metric} metricsHistory={metricsHistory} loading={loading} />
          ))}
        </div>
      </div>

      {upgradeRecommendDialogOpen && (
        <UpgradeRecommendDialog
          open={upgradeRecommendDialogOpen}
          handleClose={() => setUpgradeRecommendDialogOpen(false)}
          referrer='customerSegmentMetricsHistory'
        />
      )}
    </>
  )
}
