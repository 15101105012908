// @generated by protoc-gen-es v2.2.3 with parameter "target=ts"
// @generated from file proto/segment_transition/segment_transition.proto (package segment_transition, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage, GenService } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file proto/segment_transition/segment_transition.proto.
 */
export const file_proto_segment_transition_segment_transition: GenFile = /*@__PURE__*/
  fileDesc("CjFwcm90by9zZWdtZW50X3RyYW5zaXRpb24vc2VnbWVudF90cmFuc2l0aW9uLnByb3RvEhJzZWdtZW50X3RyYW5zaXRpb24i6AEKHkFnZ3JlZ2F0ZUN1c3RvbWVyQ291bnRzUmVxdWVzdBJSCgt0cmFuc2l0aW9ucxgBIAMoCzI9LnNlZ21lbnRfdHJhbnNpdGlvbi5BZ2dyZWdhdGVDdXN0b21lckNvdW50c1JlcXVlc3QuVHJhbnNpdGlvbhIRCglzdGFydERhdGUYAiABKAkSDwoHZW5kRGF0ZRgDIAEoCRpOCgpUcmFuc2l0aW9uEh8KF3NvdXJjZUN1c3RvbWVyU2VnbWVudElkGAEgASgJEh8KF3RhcmdldEN1c3RvbWVyU2VnbWVudElkGAIgASgJIo4CCh9BZ2dyZWdhdGVDdXN0b21lckNvdW50c1Jlc3BvbnNlElwKB3Jlc3VsdHMYASADKAsySy5zZWdtZW50X3RyYW5zaXRpb24uQWdncmVnYXRlQ3VzdG9tZXJDb3VudHNSZXNwb25zZS5UcmFuc2l0aW9uQ3VzdG9tZXJDb3VudBqMAQoXVHJhbnNpdGlvbkN1c3RvbWVyQ291bnQSHwoXc291cmNlQ3VzdG9tZXJTZWdtZW50SWQYASABKAkSHwoXdGFyZ2V0Q3VzdG9tZXJTZWdtZW50SWQYAiABKAkSFQoNY3VzdG9tZXJDb3VudBgDIAEoAxIYChBpc0RhdGFJbmNvbXBsZXRlGAQgASgIIocBCh9BZ2dyZWdhdGVDdXN0b21lck1ldHJpY3NSZXF1ZXN0Eh8KF3NvdXJjZUN1c3RvbWVyU2VnbWVudElkGAEgASgJEh8KF3RhcmdldEN1c3RvbWVyU2VnbWVudElkGAIgASgJEhEKCXN0YXJ0RGF0ZRgDIAEoCRIPCgdlbmREYXRlGAQgASgJIsYCCiBBZ2dyZWdhdGVDdXN0b21lck1ldHJpY3NSZXNwb25zZRIVCg1jdXN0b21lckNvdW50GAEgASgDEhQKDHJlcGVhdGVyUmF0ZRgCIAEoARILCgNsdHYYAyABKAESFQoNYXZnT3JkZXJWYWx1ZRgEIAEoARIVCg1hdmdPcmRlckNvdW50GAUgASgBEhwKFGF2Z0RheXNCZXR3ZWVuT3JkZXJzGAYgASgBElAKCWN1c3RvbWVycxgHIAMoCzI9LnNlZ21lbnRfdHJhbnNpdGlvbi5BZ2dyZWdhdGVDdXN0b21lck1ldHJpY3NSZXNwb25zZS5DdXN0b21lchpKCghDdXN0b21lchIKCgJpZBgBIAEoCRINCgVlbWFpbBgCIAEoCRIRCglmaXJzdE5hbWUYAyABKAkSEAoIbGFzdE5hbWUYBCABKAkyqwIKGFNlZ21lbnRUcmFuc2l0aW9uU2VydmljZRKEAQoXQWdncmVnYXRlQ3VzdG9tZXJDb3VudHMSMi5zZWdtZW50X3RyYW5zaXRpb24uQWdncmVnYXRlQ3VzdG9tZXJDb3VudHNSZXF1ZXN0GjMuc2VnbWVudF90cmFuc2l0aW9uLkFnZ3JlZ2F0ZUN1c3RvbWVyQ291bnRzUmVzcG9uc2UiABKHAQoYQWdncmVnYXRlQ3VzdG9tZXJNZXRyaWNzEjMuc2VnbWVudF90cmFuc2l0aW9uLkFnZ3JlZ2F0ZUN1c3RvbWVyTWV0cmljc1JlcXVlc3QaNC5zZWdtZW50X3RyYW5zaXRpb24uQWdncmVnYXRlQ3VzdG9tZXJNZXRyaWNzUmVzcG9uc2UiAEJGWkRlY3Bvd2VyLmlvL2FwaS1zZXJ2ZXIvcHJvdG8vc2VnbWVudF90cmFuc2l0aW9uO3NlZ21lbnRfdHJhbnNpdGlvbl9wYmIGcHJvdG8z");

/**
 * AggregateCustomerCounts
 *
 * @generated from message segment_transition.AggregateCustomerCountsRequest
 */
export type AggregateCustomerCountsRequest = Message<"segment_transition.AggregateCustomerCountsRequest"> & {
  /**
   * @generated from field: repeated segment_transition.AggregateCustomerCountsRequest.Transition transitions = 1;
   */
  transitions: AggregateCustomerCountsRequest_Transition[];

  /**
   * YYYY-MM-DD
   *
   * @generated from field: string startDate = 2;
   */
  startDate: string;

  /**
   * YYYY-MM-DD
   *
   * @generated from field: string endDate = 3;
   */
  endDate: string;
};

/**
 * Describes the message segment_transition.AggregateCustomerCountsRequest.
 * Use `create(AggregateCustomerCountsRequestSchema)` to create a new message.
 */
export const AggregateCustomerCountsRequestSchema: GenMessage<AggregateCustomerCountsRequest> = /*@__PURE__*/
  messageDesc(file_proto_segment_transition_segment_transition, 0);

/**
 * @generated from message segment_transition.AggregateCustomerCountsRequest.Transition
 */
export type AggregateCustomerCountsRequest_Transition = Message<"segment_transition.AggregateCustomerCountsRequest.Transition"> & {
  /**
   * @generated from field: string sourceCustomerSegmentId = 1;
   */
  sourceCustomerSegmentId: string;

  /**
   * @generated from field: string targetCustomerSegmentId = 2;
   */
  targetCustomerSegmentId: string;
};

/**
 * Describes the message segment_transition.AggregateCustomerCountsRequest.Transition.
 * Use `create(AggregateCustomerCountsRequest_TransitionSchema)` to create a new message.
 */
export const AggregateCustomerCountsRequest_TransitionSchema: GenMessage<AggregateCustomerCountsRequest_Transition> = /*@__PURE__*/
  messageDesc(file_proto_segment_transition_segment_transition, 0, 0);

/**
 * @generated from message segment_transition.AggregateCustomerCountsResponse
 */
export type AggregateCustomerCountsResponse = Message<"segment_transition.AggregateCustomerCountsResponse"> & {
  /**
   * @generated from field: repeated segment_transition.AggregateCustomerCountsResponse.TransitionCustomerCount results = 1;
   */
  results: AggregateCustomerCountsResponse_TransitionCustomerCount[];
};

/**
 * Describes the message segment_transition.AggregateCustomerCountsResponse.
 * Use `create(AggregateCustomerCountsResponseSchema)` to create a new message.
 */
export const AggregateCustomerCountsResponseSchema: GenMessage<AggregateCustomerCountsResponse> = /*@__PURE__*/
  messageDesc(file_proto_segment_transition_segment_transition, 1);

/**
 * @generated from message segment_transition.AggregateCustomerCountsResponse.TransitionCustomerCount
 */
export type AggregateCustomerCountsResponse_TransitionCustomerCount = Message<"segment_transition.AggregateCustomerCountsResponse.TransitionCustomerCount"> & {
  /**
   * @generated from field: string sourceCustomerSegmentId = 1;
   */
  sourceCustomerSegmentId: string;

  /**
   * @generated from field: string targetCustomerSegmentId = 2;
   */
  targetCustomerSegmentId: string;

  /**
   * @generated from field: int64 customerCount = 3;
   */
  customerCount: bigint;

  /**
   * @generated from field: bool isDataIncomplete = 4;
   */
  isDataIncomplete: boolean;
};

/**
 * Describes the message segment_transition.AggregateCustomerCountsResponse.TransitionCustomerCount.
 * Use `create(AggregateCustomerCountsResponse_TransitionCustomerCountSchema)` to create a new message.
 */
export const AggregateCustomerCountsResponse_TransitionCustomerCountSchema: GenMessage<AggregateCustomerCountsResponse_TransitionCustomerCount> = /*@__PURE__*/
  messageDesc(file_proto_segment_transition_segment_transition, 1, 0);

/**
 * AggregateCustomerMetrics
 *
 * @generated from message segment_transition.AggregateCustomerMetricsRequest
 */
export type AggregateCustomerMetricsRequest = Message<"segment_transition.AggregateCustomerMetricsRequest"> & {
  /**
   * @generated from field: string sourceCustomerSegmentId = 1;
   */
  sourceCustomerSegmentId: string;

  /**
   * @generated from field: string targetCustomerSegmentId = 2;
   */
  targetCustomerSegmentId: string;

  /**
   * YYYY-MM-DD
   *
   * @generated from field: string startDate = 3;
   */
  startDate: string;

  /**
   * YYYY-MM-DD
   *
   * @generated from field: string endDate = 4;
   */
  endDate: string;
};

/**
 * Describes the message segment_transition.AggregateCustomerMetricsRequest.
 * Use `create(AggregateCustomerMetricsRequestSchema)` to create a new message.
 */
export const AggregateCustomerMetricsRequestSchema: GenMessage<AggregateCustomerMetricsRequest> = /*@__PURE__*/
  messageDesc(file_proto_segment_transition_segment_transition, 2);

/**
 * @generated from message segment_transition.AggregateCustomerMetricsResponse
 */
export type AggregateCustomerMetricsResponse = Message<"segment_transition.AggregateCustomerMetricsResponse"> & {
  /**
   * @generated from field: int64 customerCount = 1;
   */
  customerCount: bigint;

  /**
   * @generated from field: double repeaterRate = 2;
   */
  repeaterRate: number;

  /**
   * @generated from field: double ltv = 3;
   */
  ltv: number;

  /**
   * @generated from field: double avgOrderValue = 4;
   */
  avgOrderValue: number;

  /**
   * @generated from field: double avgOrderCount = 5;
   */
  avgOrderCount: number;

  /**
   * @generated from field: double avgDaysBetweenOrders = 6;
   */
  avgDaysBetweenOrders: number;

  /**
   * @generated from field: repeated segment_transition.AggregateCustomerMetricsResponse.Customer customers = 7;
   */
  customers: AggregateCustomerMetricsResponse_Customer[];
};

/**
 * Describes the message segment_transition.AggregateCustomerMetricsResponse.
 * Use `create(AggregateCustomerMetricsResponseSchema)` to create a new message.
 */
export const AggregateCustomerMetricsResponseSchema: GenMessage<AggregateCustomerMetricsResponse> = /*@__PURE__*/
  messageDesc(file_proto_segment_transition_segment_transition, 3);

/**
 * @generated from message segment_transition.AggregateCustomerMetricsResponse.Customer
 */
export type AggregateCustomerMetricsResponse_Customer = Message<"segment_transition.AggregateCustomerMetricsResponse.Customer"> & {
  /**
   * @generated from field: string id = 1;
   */
  id: string;

  /**
   * @generated from field: string email = 2;
   */
  email: string;

  /**
   * @generated from field: string firstName = 3;
   */
  firstName: string;

  /**
   * @generated from field: string lastName = 4;
   */
  lastName: string;
};

/**
 * Describes the message segment_transition.AggregateCustomerMetricsResponse.Customer.
 * Use `create(AggregateCustomerMetricsResponse_CustomerSchema)` to create a new message.
 */
export const AggregateCustomerMetricsResponse_CustomerSchema: GenMessage<AggregateCustomerMetricsResponse_Customer> = /*@__PURE__*/
  messageDesc(file_proto_segment_transition_segment_transition, 3, 0);

/**
 * @generated from service segment_transition.SegmentTransitionService
 */
export const SegmentTransitionService: GenService<{
  /**
   * @generated from rpc segment_transition.SegmentTransitionService.AggregateCustomerCounts
   */
  aggregateCustomerCounts: {
    methodKind: "unary";
    input: typeof AggregateCustomerCountsRequestSchema;
    output: typeof AggregateCustomerCountsResponseSchema;
  },
  /**
   * @generated from rpc segment_transition.SegmentTransitionService.AggregateCustomerMetrics
   */
  aggregateCustomerMetrics: {
    methodKind: "unary";
    input: typeof AggregateCustomerMetricsRequestSchema;
    output: typeof AggregateCustomerMetricsResponseSchema;
  },
}> = /*@__PURE__*/
  serviceDesc(file_proto_segment_transition_segment_transition, 0);

