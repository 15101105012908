import { DialogWrapper } from '@/components/dialogWrapper'
import { Autocomplete, AutocompleteRenderInputParams, FormControl, TextField } from '@mui/material'
import { CustomerSegmentState } from 'features/customerSegments/types/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  onClose: () => void
  onSelect: () => void
  isLoading: boolean
  customerSegments: CustomerSegmentState[] | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nodes: any[]
  selectedTarget: string[]
  setSelectedTarget: (value: string[]) => void
}

export const AddNodeDialog: FC<Props> = ({ open, onClose, onSelect, isLoading, customerSegments, nodes, selectedTarget, setSelectedTarget }) => {
  const { t } = useTranslation()

  return (
    <DialogWrapper
      open={open}
      handleClose={onClose}
      title={t('features.customerSegments.group.addSegmentDialog.title')}
      mainContent={
        <FormControl fullWidth>
          <Autocomplete
            loading={isLoading}
            disabled={!customerSegments}
            options={customerSegments?.filter((segment) => !nodes.some((node) => node.id === segment.id)) || []}
            getOptionLabel={(cl) => cl.name || ''}
            onChange={(e, value) => setSelectedTarget(value ? [value.id] : [])}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            value={customerSegments?.find((cl: any) => cl.id === selectedTarget) || null}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField {...params} label={t('features.customerSegments.group.addSegmentDialog.fieldCustomerSegments')} size='small' />
            )}
          />
        </FormControl>
      }
      onSubmit={onSelect}
      loading={false}
      cancelButtonText={t('features.customerSegments.create.createDialog.cancel')}
      submitButtonText={t('features.customerSegments.create.createDialog.submit')}
    />
  )
}
