import { Badge } from '@/components/ui/badge'
import { FC } from 'react'

type Props = {
  label: string
  icon: JSX.Element
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DimensionChip: FC<Props> = ({ label, icon }) => {
  return (
    <Badge variant='outline' className='mr-1'>
      {label}
    </Badge>
  )
}
