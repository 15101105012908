import { SidebarGroup, SidebarGroupLabel, SidebarMenu } from '@/components/ui/sidebar'
import { CreateDialog } from '@/features/customerSegments/pages/group/components/createDialog'
import { useAccount } from '@/hooks/useAccount'
import { useSubCollectionRef } from '@/hooks/useCollectionRef'
import { useCollectionSubscription } from '@/hooks/useFirestoreData'
import { AUTHORIZED_ROUTE } from '@/routing'
import { orderBy, query } from 'firebase/firestore'
import { Plus } from 'lucide-react'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { Button } from '../../../../components/ui/button'
import { DraggableItem, SidebarDraggableItems } from './sidebarDraggableItems'

export const NavGroups: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { account } = useAccount()
  const { segmentGroupsRef } = useSubCollectionRef(account!.shopRef!.id)
  const { data: groups } = useCollectionSubscription(query(segmentGroupsRef, orderBy('createdAt', 'desc')))

  const draggableSegmentGroups: DraggableItem[] = useMemo(() => {
    if (!groups) return []
    return groups.map((segmentGroup) => {
      return {
        id: segmentGroup.ref.id,
        title: segmentGroup.name,
        selected: location.pathname === generatePath(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_GROUP, { id: segmentGroup.ref.id }),
        onClick: () => {
          const to = generatePath(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_GROUP, { id: segmentGroup.ref.id })
          navigate(to)
        },
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups])

  const [createDialogOpen, setCreateDialogOpen] = useState(false)

  return (
    <>
      {groups && groups.length > 0 && (
        <SidebarGroup className='group-data-[collapsible=icon]:hidden'>
          <SidebarGroupLabel>
            {t('layouts.dashboardLayout.groups')}
            <Button variant='ghost' size='sm' onClick={() => setCreateDialogOpen(true)} className='ml-auto p-0 h-4'>
              <Plus size='sm' />
            </Button>
          </SidebarGroupLabel>

          <SidebarMenu>
            <SidebarDraggableItems draggableItems={draggableSegmentGroups} localStorageKey='SegmentGroupIDSequence' />
          </SidebarMenu>
        </SidebarGroup>
      )}
      {createDialogOpen && <CreateDialog open={createDialogOpen} handleClose={() => setCreateDialogOpen(false)} />}
    </>
  )
}
