import { PageContentWrapper } from '@/components/pageContentWrapper'
import { PageHeader } from '@/components/pageHeader'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { AUTHORIZED_ROUTE } from '@/routing'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { MonthlyCohort } from './components/monthlyCohort'
import { NthOrderCohort } from './components/nthOrderCohort'

enum CohortType {
  MONTHLY = 'monthly',
  NTH_ORDER = 'nth_order',
}

const cohortRoutes = {
  [CohortType.MONTHLY]: AUTHORIZED_ROUTE.COHORTS_MONTHLY,
  [CohortType.NTH_ORDER]: AUTHORIZED_ROUTE.COHORTS_NTH_ORDER,
}

export const Cohort = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const getCohortType = (pathname: string): CohortType => {
    return Object.entries(cohortRoutes).find(([, route]) => route === pathname)?.[0] as CohortType
  }

  const [cohortType, setCohortType] = useState<CohortType>(getCohortType(location.pathname))

  const handleTabChange = (newCohortType: string) => {
    const route = cohortRoutes[newCohortType as CohortType]
    if (route) {
      navigate(route)
      setCohortType(newCohortType as CohortType)
    }
  }

  return (
    <>
      <PageHeader
        title={t('features.cohorts.title')}
        origin={{
          title: t('features.cohorts.insights'),
          path: AUTHORIZED_ROUTE.INSIGHTS,
        }}
        menuComponent={
          <Tabs defaultValue={cohortType} onValueChange={handleTabChange}>
            <TabsList>
              <TabsTrigger value={CohortType.MONTHLY}>{t('features.cohorts.cohortType_monthly')}</TabsTrigger>
              <TabsTrigger value={CohortType.NTH_ORDER}>{t('features.cohorts.cohortType_nthOrder')}</TabsTrigger>
            </TabsList>
          </Tabs>
        }
      />

      <PageContentWrapper>
        {cohortType === CohortType.MONTHLY && <MonthlyCohort />}
        {cohortType === CohortType.NTH_ORDER && <NthOrderCohort />}
      </PageContentWrapper>
    </>
  )
}
