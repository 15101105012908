// useCustomerSegment.ts
import { CustomerSegmentState } from 'features/customerSegments/types/types'
import { doc } from 'firebase/firestore'
import { useAccount } from 'hooks/useAccount'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useDocumentSubscription } from 'hooks/useFirestoreData'
import { useMemo } from 'react'
import { timestampToDayjs } from 'utils/timeUtil'

export const useCustomerSegment = (id: string | undefined) => {
  const { account } = useAccount()
  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data } = useDocumentSubscription(doc(customerSegmentsRef, id))

  return useMemo(() => {
    if (!data) return
    const res: CustomerSegmentState = {
      id: data.ref.id,
      name: data.name,
      description: data.description,
      querySet: data.querySet,
      favorited: data.favorited,
      metrics: data.metrics,
      tagOperationSetting: data.tagOperationSetting,
      createdAt: timestampToDayjs(data.createdAt),
    }
    return res
  }, [data])
}
