import { PageContentWrapper } from '@/components/pageContentWrapper'
import { PageHeader } from '@/components/pageHeader'
import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table'
import { AUTHORIZED_ROUTE } from '@/routing'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'
import { insightDimensions } from '../types/insightDimensions'

export const Overview: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const customerSegmentId = searchParams.get('customer_segment_id')

  return (
    <>
      <PageHeader
        title={t('features.insights.overview.title')}
        origin={{
          title: t('features.insights.overview.insights'),
          path: AUTHORIZED_ROUTE.INSIGHTS + (customerSegmentId ? `?customer_segment_id=${customerSegmentId}` : ''),
        }}
      />

      <PageContentWrapper>
        <Table>
          <TableBody>
            {insightDimensions.map((dimension) => (
              <TableRow
                key={dimension.name}
                className='cursor-pointer border-none group [&>*]:py-3'
                onClick={() => {
                  navigate(
                    generatePath(AUTHORIZED_ROUTE.INSIGHT_DETAIL, {
                      id: dimension.name,
                    }) + (customerSegmentId ? `?customer_segment_id=${customerSegmentId}` : '')
                  )
                }}
              >
                <TableCell className='w-[240px]'>{t('features.insights.dimension', { context: dimension.name })}</TableCell>
                <TableCell className='text-muted-foreground'>
                  {t(`features.insights.questions.${dimension.name}_${dimension.reportTypes[0]}`)}
                </TableCell>
                <TableCell className='text-right text-xs text-muted-foreground group-hover:visible invisible'>
                  {t('features.insights.overview.viewMore')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PageContentWrapper>
    </>
  )
}
