import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { zodResolver } from '@hookform/resolvers/zod'
import { auth } from 'config/firebaseClient'
import { FirebaseError } from 'firebase/app'
import { sendPasswordResetEmail } from 'firebase/auth'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { Loader2 } from 'lucide-react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PUBLIC_ROUTE } from 'routing'
import { z } from 'zod'
import { AuthHeader } from './components/authHeader'

export const ResetPassword = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const loginInputSchema = z.object({
    email: z
      .string()
      .min(1, { message: t('features.auth.reset.messageValidationEmailIsRequired') })
      .email(t('features.auth.reset.messageValidationEmailIsInvalid')),
  })

  type InputSchema = z.infer<typeof loginInputSchema>

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isValid, submitCount, isSubmitting },
  } = useForm<InputSchema>({
    resolver: zodResolver(loginInputSchema),
  })

  const handleResetPassword: SubmitHandler<InputSchema> = async (input) => {
    try {
      const actionCodeSettings = {
        url: `${window.location.origin}/${PUBLIC_ROUTE.LOGIN}`,
      }
      auth.languageCode = i18n.language
      await sendPasswordResetEmail(auth, input.email, actionCodeSettings)
      enqueueSnackbar(t('features.auth.reset.messageSuccess'), { severity: 'success' })
    } catch (err) {
      if (err instanceof FirebaseError) {
        if (err.code === 'auth/user-not-found') {
          enqueueSnackbar(t('features.auth.reset.messageValidationEmailIsInvalid'), { severity: 'error' })
          return
        }
      }
      enqueueSnackbar(t('features.auth.reset.messageError'), { severity: 'error' })
      notifySentry(err, { inputEmail: input.email })
    }
  }

  return (
    <div className='grid min-h-svh lg:grid-cols-2'>
      <div className='flex flex-col gap-4 p-6 md:p-10'>
        <AuthHeader />
        <div className='flex flex-1 items-center justify-center'>
          <div className='w-full max-w-xs'>
            <form className='flex flex-col gap-6'>
              <div className='flex flex-col gap-2'>
                <h1 className='text-2xl font-bold'>{t('features.auth.reset.title')}</h1>
                <p className='text-balance text-sm text-muted-foreground'>{t('features.auth.reset.description')}</p>
              </div>
              <div className='grid gap-6'>
                <div className='grid gap-2'>
                  <Label htmlFor='email'>{t('features.auth.reset.email')}</Label>
                  <Input id='email' type='email' required {...register('email')} />
                  {formErrors.email && <p className='text-red-500 text-xs'>{formErrors.email.message}</p>}
                </div>
                <Button type='submit' className='w-full' onClick={handleSubmit(handleResetPassword)} disabled={submitCount > 0 && !isValid}>
                  {isSubmitting ? <Loader2 className='animate-spin' /> : t('features.auth.reset.submit')}
                </Button>
              </div>
              <div className='text-center text-sm'>
                <a className='underline underline-offset-4 cursor-pointer' onClick={() => navigate(PUBLIC_ROUTE.LOGIN)}>
                  {t('features.auth.reset.backToLogin')}
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className='relative hidden bg-muted lg:block'>
        <div className='absolute inset-0 h-full w-full object-cover dark:brightness-[0.2] dark:grayscale'>
          <div className='flex justify-center items-center h-full'>
            <img src='images/auth/reset.svg' alt='Image' className='w-2/3' />
          </div>
        </div>
      </div>
    </div>
  )
}
