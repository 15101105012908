import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { zodResolver } from '@hookform/resolvers/zod'
import { env } from 'config/env'
import { auth } from 'config/firebaseClient'
import { AuthError, signInWithEmailAndPassword } from 'firebase/auth'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { Loader2 } from 'lucide-react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PUBLIC_ROUTE } from 'routing'
import { z } from 'zod'
import { AuthHeader } from './components/authHeader'

export const Login = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const loginInputSchema = z.object({
    email: z
      .string()
      .min(1, { message: t('features.auth.login.messageValidationEmailIsRequired') })
      .email(t('features.auth.login.messageValidationEmailIsInvalid')),
    password: z.string().min(3, { message: t('features.auth.login.messageValidationPasswordIsRequired') }),
  })

  type InputSchema = z.infer<typeof loginInputSchema>

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isValid, submitCount, isSubmitting },
  } = useForm<InputSchema>({
    resolver: zodResolver(loginInputSchema),
  })

  const handleLogin: SubmitHandler<InputSchema> = async (input) => {
    try {
      await signInWithEmailAndPassword(auth, input.email, input.password)
    } catch (err) {
      const { message, errorType } = getLoginErrorMessage(err as AuthError)
      enqueueSnackbar(message, { severity: 'error' })
      if (errorType === 'unknown') {
        // 予想外のエラーのみsentryに通知する
        notifySentry(err, { inputEmail: input.email, inputPassword: input.password })
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getLoginErrorMessage = (err: any): { message: string; errorType: 'badRequest' | 'unknown' } => {
    if (!err.code) return { message: t('features.auth.login.messageError'), errorType: 'unknown' }
    switch (err.code) {
      case 'auth/invalid-email':
      case 'auth/user-not-found':
      case 'auth/user-mismatch':
      case 'auth/wrong-password':
        return { message: t('features.auth.login.messageValidationEmailOrPasswordIsInvalid'), errorType: 'badRequest' }
      default:
        return { message: t('features.auth.login.messageError'), errorType: 'unknown' }
    }
  }

  return (
    <div className='grid min-h-svh lg:grid-cols-2'>
      <div className='flex flex-col gap-4 p-6 md:p-10'>
        <AuthHeader />
        <div className='flex flex-1 items-center justify-center'>
          <div className='w-full max-w-xs'>
            <form className='flex flex-col gap-6'>
              <div className='flex flex-col gap-2'>
                <h1 className='text-2xl font-bold'>{t('features.auth.login.title')}</h1>
                <p className='text-balance text-sm text-muted-foreground'>{t('features.auth.login.description')}</p>
              </div>
              <div className='grid gap-6'>
                <div className='grid gap-2'>
                  <Label htmlFor='email'>{t('features.auth.login.email')}</Label>
                  <Input id='email' type='email' required {...register('email')} />
                  {formErrors.email && <p className='text-red-500 text-xs'>{formErrors.email.message}</p>}
                </div>
                <div className='grid gap-2'>
                  <div className='flex items-center'>
                    <Label htmlFor='password'>{t('features.auth.login.password')}</Label>
                    <a
                      className='ml-auto text-sm underline-offset-4 hover:underline cursor-pointer'
                      onClick={() => navigate(PUBLIC_ROUTE.RESET_PASSWORD)}
                    >
                      {t('features.auth.login.forget')}
                    </a>
                  </div>
                  <Input id='password' type='password' required {...register('password')} />
                  {formErrors.password && <p className='text-red-500 text-xs'>{formErrors.password.message}</p>}
                </div>
                <Button type='submit' className='w-full' onClick={handleSubmit(handleLogin)} disabled={submitCount > 0 && !isValid}>
                  {isSubmitting ? <Loader2 className='animate-spin' /> : t('features.auth.login.submit')}
                </Button>
              </div>
              <div className='text-center text-sm'>
                {t('features.auth.login.new')}
                <a
                  className='underline underline-offset-4 cursor-pointer'
                  onClick={() => {
                    let url = env.SHOPIFY_APP_STORE_URL
                    if (i18n.language === 'ja') url += '?locale=ja'
                    window.open(url, '_blank')
                  }}
                >
                  {t('features.auth.login.newLink')}
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className='relative hidden bg-muted lg:block'>
        <div className='absolute inset-0 h-full w-full object-cover dark:brightness-[0.2] dark:grayscale'>
          <div className='flex justify-center items-center h-full'>
            <img src='images/auth/login.svg' alt='Image' className='w-2/3' />
          </div>
        </div>
      </div>
    </div>
  )
}
