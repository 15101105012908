import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { useActionTracker } from '@/hooks/useMixpanel'
import { extractTextFromTextWithIcon } from '@/utils/iconUtil'
import { EmptyState } from 'components/emptyState'
import { InsightDimension } from 'gen/firestore'
import { FileSearch, LoaderCircle } from 'lucide-react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { Bar, BarChart, CartesianGrid, LabelList, XAxis } from 'recharts'
import { AUTHORIZED_ROUTE } from 'routing'
import { InsightValue } from '../insights'

type Props = {
  title: string
  customerSegmentId: string
  insightDimension: InsightDimension
  insightValues: InsightValue[] | undefined
  loading: boolean
  insightDimensions: InsightDimension[]
  setInsightDimension: (dimension: InsightDimension) => void
}

export const InsightNumericValues: FC<Props> = ({
  title,
  customerSegmentId,
  insightDimension,
  insightValues,
  loading,
  insightDimensions,
  setInsightDimension,
}) => {
  const { t } = useTranslation()
  const { dispatch } = useActionTracker()
  const navigate = useNavigate()

  const chartData = insightValues?.map((v) => ({
    name: v.name,
    customerCount: v.customerCount,
  }))

  const chartConfig = {
    customerCount: {
      label: 'Customers',
      color: 'hsl(var(--chart-3))',
    },
  } satisfies ChartConfig

  return (
    <Card>
      <CardHeader>
        <div className='flex items-center justify-between'>
          <div className='flex items-center gap-1'>
            <CardTitle>{title}</CardTitle>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant='ghost'
                  size='icon'
                  onClick={() => {
                    navigate(
                      generatePath(AUTHORIZED_ROUTE.INSIGHT_DETAIL, { id: insightDimension }) +
                        (customerSegmentId ? `?customer_segment_id=${customerSegmentId}` : '')
                    )
                  }}
                >
                  <FileSearch />
                </Button>
              </TooltipTrigger>
              <TooltipContent>{t('features.insights.viewMore')}</TooltipContent>
            </Tooltip>
          </div>
          <Select
            defaultValue={insightDimension}
            onValueChange={(value) => {
              setInsightDimension(value as InsightDimension)
              dispatch('SwitchInsightDimension', { name: value as InsightDimension })
            }}
          >
            <SelectTrigger className='w-auto h-[32px]'>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {insightDimensions.map((d) => (
                  <SelectItem key={d} value={d}>
                    {extractTextFromTextWithIcon(t(`features.insights.dimension_${d}`))}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </CardHeader>
      <CardContent>
        {loading || !insightValues ? (
          <div className='flex justify-center items-center h-[140px]'>
            <LoaderCircle className='animate-spin' />
          </div>
        ) : insightValues.length === 0 ? (
          <EmptyState title={t('features.insights.empty')} />
        ) : (
          <ChartContainer config={chartConfig} className='w-full h-[240px]'>
            <BarChart accessibilityLayer data={chartData}>
              <CartesianGrid vertical={false} />
              <XAxis dataKey='name' tickLine={false} axisLine={false} />
              <ChartTooltip content={<ChartTooltipContent />} />
              <Bar dataKey='customerCount' fill='var(--color-customerCount)' radius={4}>
                {insightDimension !== InsightDimension.hour && <LabelList dataKey='customerCount' position='top' offset={10} />}
              </Bar>
            </BarChart>
          </ChartContainer>
        )}
      </CardContent>
    </Card>
  )
}
