import { DialogWrapper } from '@/components/dialogWrapper'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  handleClose: () => void
  handleSubmit: () => void
}

export const DeleteNodeDialog: FC<Props> = ({ open, handleClose, handleSubmit }) => {
  const { t } = useTranslation()

  return (
    <DialogWrapper
      open={open}
      handleClose={handleClose}
      title={t('features.customerSegments.group.deleteNodeDialog.title')}
      mainContent={<p className='text-sm'>{t('features.customerSegments.group.deleteNodeDialog.description')}</p>}
      onSubmit={handleSubmit}
      loading={false}
      cancelButtonText={t('features.customerSegments.group.deleteNodeDialog.cancel')}
      submitButtonText={t('features.customerSegments.group.deleteNodeDialog.submit')}
    />
  )
}
