import { ErrorInfo } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { env } from 'config/env'
import { FatalError } from 'features/error/fatalError'
import { useSubscribeAuth } from 'hooks/useAuthUser'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { SnackbarProvider } from 'notistack'
import { AppThemeProvider } from 'provider/themeContext'
import { FC, ReactNode, useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { AppRoutes } from './appRoutes'

type Props = {
  children: JSX.Element
}

const SubscribeAuth = ({ children }: Props): JSX.Element => {
  const { loading } = useSubscribeAuth()
  return loading ? <></> : children
}
if (env.DEBUG_LOG) {
  console.log('env is..', import.meta.env)
}

export const App = () => {
  return (
    <RecoilRoot>
      <FatalErrorBoundary>
        <BrowserRouter>
          <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
            <SubscribeAuth>
              <InitClarity>
                <AppThemeProvider>
                  <div className='App'>
                    <CssBaseline />
                    <AppRoutes />
                  </div>
                </AppThemeProvider>
              </InitClarity>
            </SubscribeAuth>
          </SnackbarProvider>
        </BrowserRouter>
      </FatalErrorBoundary>
    </RecoilRoot>
  )
}

const FatalErrorBoundary: FC<{ children: ReactNode }> = ({ children }) => {
  const { notifySentry } = useSentryNotifier()
  const onFatalError = (err: Error, info: ErrorInfo) => {
    const stack = info.componentStack || 'No stack trace available'
    notifySentry(err, { componentStack: stack })
  }
  return (
    <ErrorBoundary FallbackComponent={() => <FatalError />} onError={onFatalError}>
      {children}
    </ErrorBoundary>
  )
}

const InitClarity: FC<{ children: ReactNode }> = ({ children }) => {
  useEffect(() => {
    const addClarityScript = () => {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.text = `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "${env.CLARITY_PROJECT_ID}");`

      document.head.appendChild(script)
    }

    if (!document.querySelector(`script[src*="clarity.ms/tag/${env.CLARITY_PROJECT_ID}"]`)) {
      addClarityScript()
    }
  }, [])

  return <>{children}</>
}
