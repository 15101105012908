import { Box, Grid, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, useTheme } from '@mui/material'
import { EmptyState } from 'components/emptyState'
import { SkeletonTable } from 'components/skeletonTable'
import { addIndexToName, chartColors, chartColorsDark } from 'features/insights/types/insight'
import { LoaderCircle } from 'lucide-react'
import { FC, useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { MonthlyInsightValue, ViewType } from '../detail'
import { FreePlanSkeletonText } from './components/freePlanSkeletonText'
import { NameCheckBoxList } from './components/nameCheckBoxList'

const DEFAULT_VISIBLE_COUNT = 3
const VISUAL_PAPER_HEIGHT = '80vh'
const TABLE_ROWS_PER_PAGE = 10

type Props = {
  viewType: ViewType
  insightValues: MonthlyInsightValue[] | undefined
  loading: boolean
  onChartPointClick: (name: string, month: string) => void
}

export const MonthlyInsight: FC<Props> = ({ viewType, insightValues, loading, onChartPointClick }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const [selectedNames, setSelectedNames] = useState<string[]>([])
  const [page, setPage] = useState(0)

  useEffect(() => {
    setSelectedNames(insightValues?.slice(0, DEFAULT_VISIBLE_COUNT).map((value) => value.name) || [])
  }, [insightValues])

  useEffect(() => {
    if (loading) setPage(0) // Reset page
  }, [loading])

  return (
    <>
      {viewType === ViewType.visual && (
        <Grid container spacing={2}>
          <Grid item xs={9}>
            {loading || !insightValues ? (
              <Paper
                variant='outlined'
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: VISUAL_PAPER_HEIGHT, borderRadius: '12px' }}
              >
                <LoaderCircle className='animate-spin' />
              </Paper>
            ) : insightValues.length === 0 ? (
              <Paper
                variant='outlined'
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: VISUAL_PAPER_HEIGHT,
                  borderRadius: '12px',
                }}
              >
                <EmptyState title={t('features.insights.detail.noData')} />
              </Paper>
            ) : (
              <Paper variant='outlined' sx={{ padding: '20px', borderRadius: '12px', height: VISUAL_PAPER_HEIGHT }}>
                <Chart
                  type='line'
                  height='100%'
                  series={insightValues
                    .filter((value) => selectedNames.includes(value.name))
                    .map((value) => ({
                      name: value.name,
                      data: value.values.map((v) => ({
                        x: v.yearMonth,
                        y: v.customerCount,
                      })),
                    }))}
                  options={{
                    xaxis: {
                      type: 'category',
                      categories: insightValues[0].values.map((v) => v.yearMonth),
                      labels: { style: { colors: theme.palette.text.primary } },
                      tickAmount: 18,
                    },
                    yaxis: {
                      title: {
                        text: t('features.insights.detail.customerCount'),
                        style: { color: theme.palette.text.primary },
                      },
                      labels: { style: { colors: theme.palette.text.primary } },
                    },
                    chart: {
                      zoom: { enabled: false },
                      toolbar: { show: false },
                      events: {
                        markerClick: (_, __, config) => {
                          const selectedName = insightValues[config.seriesIndex as number].name
                          const selectedMonth = insightValues[config.seriesIndex as number].values[config.dataPointIndex].yearMonth
                          onChartPointClick(selectedName, selectedMonth)
                          document.body.style.cursor = 'default'
                        },
                        mouseMove: () => {
                          document.body.style.cursor = 'pointer'
                        },
                      },
                    },
                    tooltip: {
                      shared: false,
                      theme: theme.palette.mode,
                      y: { formatter: (val) => t('features.insights.detail.customers', { count: val }) },
                    },
                    legend: { labels: { colors: theme.palette.text.primary } },
                    stroke: { curve: 'smooth', width: 3 },
                    markers: { size: 0 },
                    colors: theme.palette.mode === 'dark' ? chartColorsDark : chartColors,
                  }}
                />
              </Paper>
            )}
          </Grid>
          <Grid item xs={3}>
            <NameCheckBoxList
              loading={loading}
              items={insightValues?.map((value) => ({ name: value.name, isVisible: value.isVisible })) || []}
              selectedNames={selectedNames}
              setSelectedNames={setSelectedNames}
              height={VISUAL_PAPER_HEIGHT}
            />
          </Grid>
        </Grid>
      )}

      {viewType === ViewType.table && (
        <Paper variant='outlined' sx={{ padding: '20px 36px', borderRadius: '12px' }}>
          {loading || !insightValues ? (
            <SkeletonTable columnCount={12} rowCount={10} />
          ) : insightValues.length === 0 ? (
            <EmptyState title={t('features.insights.detail.noData')} />
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      '& .MuiTableCell-head': {
                        color: (theme) => theme.palette.text.secondary,
                        fontSize: '13px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  >
                    <TableCell>{t('features.insights.detail.name')}</TableCell>
                    {insightValues[0].values.map((v, i) => (
                      <TableCell key={i} align='center'>
                        {v.yearMonth}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {insightValues.slice(page * TABLE_ROWS_PER_PAGE, page * TABLE_ROWS_PER_PAGE + TABLE_ROWS_PER_PAGE).map((row) => (
                    <TableRow key={row.name}>
                      <TableCell
                        component='th'
                        scope='row'
                        style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                      >
                        <Tooltip title={row.name} placement='top'>
                          <span>{addIndexToName(row.name, row.rank)}</span>
                        </Tooltip>
                      </TableCell>
                      {row.values.map((v, index) => (
                        <TableCell key={index} align='center'>
                          {row.isVisible ? (
                            <Tooltip title={t('features.insights.detail.customers', { count: v.customerCount })} placement='top'>
                              <span>{v.customerCount}</span>
                            </Tooltip>
                          ) : (
                            <FreePlanSkeletonText />
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {insightValues && insightValues.length > 0 && (
            <Box display='flex' justifyContent='center'>
              <Pagination
                shape='rounded'
                count={insightValues ? Math.ceil(insightValues.length / TABLE_ROWS_PER_PAGE) : 0}
                page={page + 1}
                onChange={(_, v) => setPage(v - 1)}
                sx={{ marginY: '20px' }}
              />
            </Box>
          )}
        </Paper>
      )}
    </>
  )
}
