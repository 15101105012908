import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarRail,
  useSidebar,
} from '@/components/ui/sidebar'
import { getGuideUrl } from '@/config/guide'
import { billingStatus } from '@/config/plan'
import { ShopBillingStatus } from '@/gen/firestore'
import { useAccount } from '@/hooks/useAccount'
import { useActionTracker } from '@/hooks/useMixpanel'
import { AUTHORIZED_ROUTE } from '@/routing'
import dayjs from 'dayjs'
import { Book, FileText, Lightbulb, MessageCircleMore, Settings, Users } from 'lucide-react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ChannelTalkClient from 'utils/channelTalk'
import { NavActions } from './components/navActions'
import { NavFavorites } from './components/navFavorites'
import { NavGroups } from './components/navGroups'
import { NavMain } from './components/navMain'
import { NavSecondary } from './components/navSecondary'
import { NavUser } from './components/navUser'
import { UpgradeCard } from './components/upgradeCard'

export const AppSidebar = ({ ...props }: React.ComponentProps<typeof Sidebar>) => {
  const { t, i18n } = useTranslation()
  const { shop } = useAccount()
  const shopBillingStatus = billingStatus(shop, dayjs())
  const navigate = useNavigate()
  const { dispatch } = useActionTracker()
  const { state } = useSidebar()

  const data = {
    navMain: [
      {
        title: t('layouts.dashboardLayout.segments'),
        path: AUTHORIZED_ROUTE.CUSTOMER_SEGMENTS,
        icon: Users,
        isActive: location.pathname.includes(AUTHORIZED_ROUTE.CUSTOMER_SEGMENTS) && !location.pathname.includes('groups'),
      },
      {
        title: t('layouts.dashboardLayout.insights'),
        path: AUTHORIZED_ROUTE.INSIGHTS,
        icon: Lightbulb,
        isActive:
          location.pathname.includes(AUTHORIZED_ROUTE.INSIGHTS) ||
          location.pathname === AUTHORIZED_ROUTE.PRODUCT_INSIGHTS ||
          location.pathname.includes('cohort'),
      },
      {
        title: t('layouts.dashboardLayout.templates'),
        path: AUTHORIZED_ROUTE.TEMPLATES,
        icon: FileText,
        isActive: location.pathname === AUTHORIZED_ROUTE.TEMPLATES,
      },
    ],
    navSecondary: [
      {
        title: t('layouts.dashboardLayout.docs'),
        onClick: () => {
          const guideURL = getGuideUrl(i18n.language, 'Home')
          dispatch('ViewGuide', { guideType: 'Home', url: guideURL })
          window.open(guideURL, '_blank')
        },
        icon: Book,
      },
      {
        title: t('layouts.dashboardLayout.support'),
        onClick: () => ChannelTalkClient.openChat(),
        icon: MessageCircleMore,
      },
      {
        title: t('layouts.dashboardLayout.settings'),
        onClick: () => navigate(AUTHORIZED_ROUTE.SETTINGS_WORKSPACE),
        icon: Settings,
      },
    ],
  }

  return (
    <Sidebar collapsible='icon' {...props}>
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton asChild>
              <img
                src={state === 'collapsed' ? '/logo512.png' : '/logo.png'}
                alt='ecpower-logo'
                onClick={() => navigate(AUTHORIZED_ROUTE.HOME)}
                className='cursor-pointer h-auto max-w-[128px]'
              />
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        {state === 'expanded' && <NavActions />}
        <NavMain items={data.navMain} />
        <NavGroups />
        <NavFavorites />
        {state === 'expanded' && shopBillingStatus !== ShopBillingStatus.paid && <UpgradeCard shopBillingStatus={shopBillingStatus} />}
        <NavSecondary items={data.navSecondary} className='mt-auto' />
      </SidebarContent>
      <SidebarFooter>
        <NavUser />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  )
}
