import { LoadingButton } from '@mui/lab'
import { Box, Container, Typography } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { DataLoaderService } from 'gen/proto/data_loader/data_loader_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

export const DataSyncNotStarted: React.FC = () => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const dataLoaderService = useGrpcClient(DataLoaderService)
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const token = await getIdToken(authUser!)
      await dataLoaderService.startLoadFromShopify({}, { headers: { Authorization: `Bearer ${token}` } })
    } catch (e) {
      enqueueSnackbar(t('features.dataSyncNotStarted.messageError'), { severity: 'error' })
      notifySentry(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container maxWidth='lg' fixed sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: '100vh' }}>
      <Box>
        <Typography fontSize='32px' fontWeight='bold' marginBottom='24px'>
          {t('features.dataSyncNotStarted.title')}
        </Typography>

        <Typography fontSize='14px' marginBottom='24px'>
          <Trans i18nKey='features.dataSyncNotStarted.description' />
        </Typography>

        <LoadingButton size='large' loading={loading} disabled={loading} onClick={handleSubmit} variant='contained'>
          {t('features.dataSyncNotStarted.submit')}
        </LoadingButton>
      </Box>
    </Container>
  )
}
