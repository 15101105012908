import { SidebarGroup, SidebarGroupLabel, SidebarMenu } from '@/components/ui/sidebar'
import { useAccount } from '@/hooks/useAccount'
import { useSubCollectionRef } from '@/hooks/useCollectionRef'
import { useCollectionSubscription } from '@/hooks/useFirestoreData'
import { AUTHORIZED_ROUTE } from '@/routing'
import { orderBy, query } from 'firebase/firestore'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { DraggableItem, SidebarDraggableItems } from './sidebarDraggableItems'

export const NavFavorites: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { account } = useAccount()
  const { customerSegmentsRef } = useSubCollectionRef(account!.shopRef!.id)
  const { data: segments } = useCollectionSubscription(query(customerSegmentsRef, orderBy('createdAt', 'desc')))

  const favoriteSegments = useMemo(() => {
    return segments ? segments.filter((s) => s.favorited) : []
  }, [segments])

  const draggableFavoriteSegments: DraggableItem[] = useMemo(() => {
    if (!segments) return []
    return segments
      .filter((s) => s.favorited)
      .map((favoriteSegment) => ({
        id: favoriteSegment.ref.id,
        title: favoriteSegment.name,
        selected: location.pathname === generatePath(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_DETAIL, { id: favoriteSegment.ref.id }),
        onClick: () => {
          const to = generatePath(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_DETAIL, { id: favoriteSegment.ref.id })
          navigate(to)
        },
      }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segments])

  return (
    <>
      {favoriteSegments && favoriteSegments.length > 0 && (
        <SidebarGroup className='group-data-[collapsible=icon]:hidden'>
          <SidebarGroupLabel>{t('layouts.dashboardLayout.favorites')}</SidebarGroupLabel>
          <SidebarMenu>
            <SidebarDraggableItems draggableItems={draggableFavoriteSegments} localStorageKey='FavoriteSegmentIDSequence' />
          </SidebarMenu>
        </SidebarGroup>
      )}
    </>
  )
}
