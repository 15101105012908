import { CustomDateRange, DateRangePicker } from '@/components/dateRangePicker'
import { Alert, AlertDescription } from '@/components/ui/alert'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart'
import { Skeleton } from '@/components/ui/skeleton'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { Pagination } from '@mui/material'
import { EmptyState } from 'components/emptyState'
import { UpgradeRecommendDialog } from 'components/upgradeRecommendDialog'
import { billingStatus } from 'config/plan'
import dayjs from 'dayjs'
import { CustomerSegmentState } from 'features/customerSegments/types/types'
import { ShopBillingStatus } from 'gen/firestore'
import { useAccount } from 'hooks/useAccount'
import { useCsvDownload } from 'hooks/useCsvDownload'
import { useCurrency } from 'hooks/useCurrency'
import { useActionTracker } from 'hooks/useMixpanel'
import { ChartSpline, Download, LoaderCircle, MessageCircleWarning, Rows3 } from 'lucide-react'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from 'recharts'
import { formatDate } from 'utils/timeUtil'
import { ActivityState } from '../types/types'

type Props = {
  customerSegment: CustomerSegmentState | undefined
  activities: ActivityState[] | undefined
  loading: boolean
  handleTimeRangeChange: (startDate: string, endDate: string) => void
}

enum ViewType {
  chart = 'chart',
  table = 'table',
}

export const Activities: FC<Props> = ({ customerSegment, activities, loading, handleTimeRangeChange }) => {
  const { t, i18n } = useTranslation()
  const { shop } = useAccount()
  const shopBillingStatus = billingStatus(shop, dayjs())
  const { formatCurrency } = useCurrency()
  const { dispatch } = useActionTracker()

  const [viewType, setViewType] = useState<ViewType>(ViewType.chart)
  const [upgradeRecommendDialogOpen, setUpgradeRecommendDialogOpen] = useState(false)
  const [page, setPage] = useState(0)
  const rowsPerPage = 14

  const chartData = useMemo(() => {
    if (!activities) return []
    return activities.map((item) => ({
      date: item.date,
      revenue: item.orderAmount,
    }))
  }, [activities])

  const chartConfig = {
    revenue: {
      label: t('features.customerSegments.detail.activities.orderAmount'),
      color: 'hsl(var(--chart-1))',
    },
  }

  const { handleDownload, loading: downloadLoading } = useCsvDownload({
    data: activities?.map((o) => ({
      date: o.date,
      orderAmount: o.orderAmount,
      orderCount: o.orderCount,
    })),
    headers: [
      t('features.customerSegments.detail.activities.date'),
      t('features.customerSegments.detail.activities.orderAmount'),
      t('features.customerSegments.detail.activities.orderCount'),
    ],
    fileName: `${customerSegment?.name}_activities_${formatDate(dayjs(), i18n.language)}`,
    page: 'customerSegment_activities',
  })

  return (
    <>
      <Card>
        <CardHeader>
          <div className='flex items-center justify-between'>
            <div className='flex flex-col gap-1'>
              <CardTitle>{t('features.customerSegments.detail.activities.title')}</CardTitle>
              <CardDescription>
                {loading ? (
                  <Skeleton className='w-[240px] h-3' />
                ) : (
                  <span className='text-sm text-muted-foreground'>
                    {t('features.customerSegments.detail.activities.totalRevenueAndCount', {
                      revenue: formatCurrency(activities?.reduce((total, activity) => total + activity.orderAmount, 0) || 0),
                      count: activities?.reduce((total, activity) => total + activity.orderCount, 0) || 0,
                    })}
                  </span>
                )}
              </CardDescription>
            </div>
            <div className='flex items-center gap-2'>
              <DateRangePicker
                defaultDateRange={{
                  from: dayjs().subtract(6, 'day').toDate(),
                  to: dayjs().toDate(),
                }}
                defaultCustomDateRange={CustomDateRange.last7Days}
                onDateChange={(date) => {
                  if (shopBillingStatus === ShopBillingStatus.free) {
                    setUpgradeRecommendDialogOpen(true)
                    return
                  }
                  handleTimeRangeChange(dayjs(date.from).format('YYYY-MM-DD'), dayjs(date.to).format('YYYY-MM-DD'))
                }}
                timezone={shop.timezone}
              />
              <div className='flex items-center'>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant='ghost'
                      size='icon'
                      onClick={() => {
                        const newViewType = viewType === ViewType.chart ? ViewType.table : ViewType.chart
                        dispatch('SwitchSegmentMetricsViewType', { name: newViewType })
                        setViewType(newViewType)
                      }}
                    >
                      {viewType === ViewType.chart ? <Rows3 /> : <ChartSpline />}
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    {viewType === ViewType.chart
                      ? t('features.customerSegments.detail.metrics.viewTable')
                      : t('features.customerSegments.detail.metrics.viewChart')}
                  </TooltipContent>
                </Tooltip>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant='ghost'
                      size='icon'
                      disabled={!activities || downloadLoading}
                      onClick={() => {
                        if (shopBillingStatus === ShopBillingStatus.free) {
                          setUpgradeRecommendDialogOpen(true)
                          return
                        }
                        handleDownload()
                      }}
                    >
                      <Download />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>{t('features.customerSegments.detail.metrics.download')}</TooltipContent>
                </Tooltip>
              </div>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          {activities && activities.length === 1 && (
            <Alert className='mb-8 pt-4'>
              <MessageCircleWarning className='h-4 w-4' />
              <AlertDescription>{t('features.customerSegments.detail.activities.alert')}</AlertDescription>
            </Alert>
          )}

          {loading || !activities ? (
            <div className='flex justify-center items-center h-[400px]'>
              <LoaderCircle className='animate-spin' />
            </div>
          ) : activities.length === 0 ? (
            <EmptyState title={t('features.customerSegments.detail.activities.empty')} />
          ) : viewType === ViewType.chart ? (
            <ChartContainer config={chartConfig} className='h-[400px] w-full'>
              <AreaChart accessibilityLayer data={chartData} margin={{ right: 24 }}>
                <CartesianGrid vertical={false} />
                <XAxis dataKey='date' tickLine={false} axisLine={false} />
                <YAxis dataKey='revenue' tickLine={false} axisLine={false} />
                <ChartTooltip cursor={false} content={<ChartTooltipContent indicator='line' />} />
                <Area dataKey='revenue' type='natural' fill='var(--color-revenue)' fillOpacity={0.4} stroke='var(--color-revenue)' />
              </AreaChart>
            </ChartContainer>
          ) : (
            <>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>{t('features.customerSegments.detail.activities.date')}</TableHead>
                    <TableHead className='text-center'>{t('features.customerSegments.detail.activities.orderAmount')}</TableHead>
                    <TableHead className='text-center'>{t('features.customerSegments.detail.activities.orderCount')}</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {activities &&
                    activities.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                      <TableRow key={row.date}>
                        <TableCell>{row.date}</TableCell>
                        <TableCell className='text-center'>{formatCurrency(row.orderAmount) || <Skeleton />}</TableCell>
                        <TableCell className='text-center'>{row.orderCount}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>

              <div className='flex justify-center'>
                <Pagination
                  shape='rounded'
                  count={activities ? Math.ceil(activities.length / rowsPerPage) : 0}
                  page={page + 1}
                  onChange={(_, v) => setPage(v - 1)}
                  sx={{ marginTop: '20px' }}
                />
              </div>
            </>
          )}
        </CardContent>
      </Card>

      {upgradeRecommendDialogOpen && (
        <UpgradeRecommendDialog
          open={upgradeRecommendDialogOpen}
          handleClose={() => setUpgradeRecommendDialogOpen(false)}
          referrer='customerSegmentOrders'
        />
      )}
    </>
  )
}
