import { useActionTracker } from 'hooks/useMixpanel'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { Button } from '@/components/ui/button'

export const SegmentEmptyState: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { dispatch } = useActionTracker()

  return (
    <div className='flex flex-col items-center justify-center h-full gap-6 p-16'>
      <p className='text-sm text-muted-foreground'>{t('features.customerSegments.overview.createFirstSegment')}</p>
      <div className='flex justify-center gap-3'>
        <Button
          size='sm'
          variant='outline'
          onClick={() => {
            dispatch('ClickCreateFirstSegmentFromTemplate')
            navigate(AUTHORIZED_ROUTE.TEMPLATES)
          }}
        >
          {t('features.customerSegments.overview.createFromTemplates')}
        </Button>
        <Button
          size='sm'
          onClick={() => {
            dispatch('ClickCreateFirstSegmentFromScratch')
            navigate(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_CREATE)
          }}
        >
          {t('features.customerSegments.overview.createFromScratch')}
        </Button>
      </div>
    </div>
  )
}
