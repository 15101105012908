import { Box, Grid, Paper, Skeleton, useTheme } from '@mui/material'
import { EmptyState } from 'components/emptyState'
import { chartColors, chartColorsDark } from 'features/insights/types/insight'
import { InsightDimension } from 'gen/firestore'
import { LoaderCircle } from 'lucide-react'
import { FC } from 'react'
import Chart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { HistogramInsightValue } from '../detail'

const VISUAL_PAPER_HEIGHT = '80vh'

type Props = {
  dimension: InsightDimension
  insightValues: HistogramInsightValue | undefined
  loading: boolean
  onChartPointClick: (min: number, max: number) => void
}

export const HistogramInsight: FC<Props> = ({ dimension, insightValues, loading, onChartPointClick }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          {loading || !insightValues ? (
            <Paper
              variant='outlined'
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: VISUAL_PAPER_HEIGHT, borderRadius: '12px' }}
            >
              <LoaderCircle className='animate-spin' />
            </Paper>
          ) : insightValues.values.length === 0 ? (
            <Paper
              variant='outlined'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: VISUAL_PAPER_HEIGHT,
                borderRadius: '12px',
              }}
            >
              <EmptyState title={t('features.insights.detail.noData')} />
            </Paper>
          ) : (
            <Paper variant='outlined' sx={{ padding: '20px', borderRadius: '12px', height: VISUAL_PAPER_HEIGHT }}>
              <Chart
                type='bar'
                height='100%'
                series={[
                  {
                    name: t('features.insights.detail.customerCount'),
                    data: insightValues.values.map((v) => ({
                      x: `${v.min} - ${v.max}`,
                      y: v.customerCount,
                    })),
                  },
                ]}
                options={{
                  xaxis: {
                    type: 'category',
                    categories: insightValues.values.map((v) => `${v.min} - ${v.max}`),
                    title: {
                      text: t('features.insights.dimension', { context: dimension }),
                      style: { color: theme.palette.text.primary },
                    },
                    labels: { style: { colors: theme.palette.text.primary } },
                  },
                  yaxis: {
                    title: {
                      text: t('features.insights.detail.customerCount'),
                      style: { color: theme.palette.text.primary },
                    },
                    labels: { style: { colors: theme.palette.text.primary } },
                  },
                  chart: {
                    toolbar: { show: false },
                    events: {
                      dataPointSelection: (_, __, config) => {
                        const selectedValue = insightValues.values[config.dataPointIndex]
                        onChartPointClick(selectedValue.min, selectedValue.max)
                        document.body.style.cursor = 'default'
                      },
                      dataPointMouseEnter: () => {
                        document.body.style.cursor = 'pointer'
                      },
                      dataPointMouseLeave: () => {
                        document.body.style.cursor = 'default'
                      },
                    },
                  },
                  tooltip: { theme: theme.palette.mode },
                  dataLabels: { enabled: false },
                  colors: theme.palette.mode === 'dark' ? chartColorsDark : chartColors,
                }}
              />
            </Paper>
          )}
        </Grid>
        <Grid item xs={3}>
          <Grid container spacing={2} sx={{ height: VISUAL_PAPER_HEIGHT }}>
            {['mean', 'median', 'max'].map((stat) => (
              <Grid item xs={12} key={stat}>
                <Paper
                  variant='outlined'
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center',
                    padding: '20px',
                    borderRadius: '12px',
                    height: '100%',
                  }}
                >
                  <Box>{t(`features.insights.detail.histogramInsight.${stat}`)}</Box>
                  <Box fontSize='24px' fontWeight='bold' marginTop='8px' display='flex' justifyContent='center'>
                    {!loading && insightValues ? (
                      insightValues[stat as keyof HistogramInsightValue].toString()
                    ) : (
                      <Skeleton height='20px' width='50%' />
                    )}
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
