import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { DialogWrapper } from './dialogWrapper'

type Props = {
  open: boolean
}

// UpgradeRequiredDialog is used when the user's active customer count exceeds the maximum limit of their current plan.
export const UpgradeRequiredDialog: FC<Props> = ({ open }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <DialogWrapper
      open={open}
      handleClose={() => {}}
      title={t('common.upgradeRequiredDialog.title')}
      mainContent={<p className='text-sm'>{t('common.upgradeRequiredDialog.description')}</p>}
      onSubmit={() => navigate(AUTHORIZED_ROUTE.SETTINGS_PLAN)}
      loading={false}
      cancelButtonText={''}
      submitButtonText={t('common.upgradeRequiredDialog.submit')}
    />
  )
}
