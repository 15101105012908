import { CustomDateRange, DateRangePicker } from '@/components/dateRangePicker'
import { PageContentWrapper } from '@/components/pageContentWrapper'
import { PageHeader } from '@/components/pageHeader'
import { Button } from '@/components/ui/button'
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { UpgradeRecommendDialog } from 'components/upgradeRecommendDialog'
import { billingStatus } from 'config/plan'
import dayjs from 'dayjs'
import { convertQuerySetToForm, convertStringToApiQuerySetState } from 'features/customerSegments/querySetForm/schema/converter'
import { generateNumericQuerySet } from 'features/insights/types/querySet'
import { getIdToken } from 'firebase/auth'
import { orderBy, query } from 'firebase/firestore'
import { InsightDimension, InsightReportType, ShopBillingStatus } from 'gen/firestore'
import { InsightService } from 'gen/proto/insight/insight_pb'
import { useAccount } from 'hooks/useAccount'
import { useAuthUser } from 'hooks/useAuthUser'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCsvDownload } from 'hooks/useCsvDownload'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useCollection } from 'hooks/useFirestoreData'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useActionTracker } from 'hooks/useMixpanel'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { Calendar, ChartBar, ChartBarBig, ChartColumnBig, ChartPie, Download } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { formatYearMonth } from 'utils/timeUtil'
import { InsightDrawerState } from '../types/insight'
import { InsightDimensionProfile, insightDimensions } from '../types/insightDimensions'
import { generateQuerySet } from '../types/querySet'
import { InsightDrawer } from './components/components/insightDrawer'
import { HistogramInsight } from './components/histogramInsight'
import { MonthlyInsight } from './components/monthlyInsight'
import { NthOrderInsight } from './components/nthOrderInsight'
import { OrderCountInsight } from './components/orderCountInsight'
import { PopularityInsight } from './components/popularityInsight'

export enum ViewType {
  visual = 'visual',
  table = 'table',
}

export type PopularityInsightValue = {
  rank: number
  name: string
  customerCount: number
  ratio: number
  isVisible: boolean
}
export type MonthlyInsightValue = {
  rank: number
  name: string
  values: {
    yearMonth: string
    customerCount: number
  }[]
  isVisible: boolean
}

export type NthOrderInsightValue = {
  rank: number
  name: string
  first: number
  second: number
  secondPlus: number
  third: number
  fourth: number
  fifthPlus: number
  total: number
  isVisible: boolean
}

export type OrderCountInsightValue = {
  rank: number
  name: string
  once: number
  twice: number
  twicePlus: number
  threeTimes: number
  fourTimes: number
  fiveTimesPlus: number
  total: number
  isVisible: boolean
}

export type HistogramInsightValue = {
  values: {
    min: number
    max: number
    customerCount: number
  }[]
  mean: number
  median: number
  max: number
}

export const Detail = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { dispatch } = useActionTracker()
  const { account, shop } = useAccount()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const insightService = useGrpcClient(InsightService)
  const shopBillingStatus = billingStatus(shop, dayjs())
  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: customerSegments } = useCollection(query(customerSegmentsRef, orderBy('createdAt', 'desc')))

  const params = useParams()
  const searchParams = new URLSearchParams(location.search)

  const reportTypeParam = searchParams.get('reportType') as InsightReportType | undefined

  const [viewType, setViewType] = useState<ViewType>(ViewType.visual)
  const [loading, setLoading] = useState(false)
  const [insightDrawerState, setInsightDrawerState] = useState<InsightDrawerState>({ open: false })
  const [upgradeRecommendDialogOpen, setUpgradeRecommendDialogOpen] = useState(false)

  const defaultStartDate = dayjs().subtract(29, 'days').format('YYYY-MM-DD')
  const defaultEndDate = dayjs().format('YYYY-MM-DD')
  const defaultCustomDateRange = CustomDateRange.last30Days

  const [customerSegmentId, setCustomerSegmentId] = useState<string | undefined>(searchParams.get('customer_segment_id') || undefined)
  const [startDate, setStartDate] = useState<string | undefined>(searchParams.get('start_date') || defaultStartDate)
  const [endDate, setEndDate] = useState<string | undefined>(searchParams.get('end_date') || defaultEndDate)

  const insightDimension: InsightDimension | undefined = useMemo(() => {
    return params.id as InsightDimension
  }, [params.id])

  const dimensionProfile: InsightDimensionProfile | undefined = useMemo(() => {
    return insightDimensions.find((d) => d.name === insightDimension)
  }, [insightDimension])

  const reportType: InsightReportType | undefined = useMemo(() => {
    if (!dimensionProfile) return
    if (reportTypeParam && dimensionProfile.reportTypes.includes(reportTypeParam)) {
      return reportTypeParam
    }
    return dimensionProfile.reportTypes[0]
  }, [dimensionProfile, reportTypeParam])

  useEffect(() => {
    if (!insightDimension || !dimensionProfile) return

    const isValidReportType = reportTypeParam && dimensionProfile.reportTypes.includes(reportTypeParam)

    if (isValidReportType) return
    const newReportType = dimensionProfile.reportTypes[0]
    if (reportTypeParam === newReportType) return

    searchParams.set('reportType', newReportType)
    if (customerSegmentId) searchParams.set('customer_segment_id', customerSegmentId)

    const to =
      generatePath(AUTHORIZED_ROUTE.INSIGHT_DETAIL, {
        id: insightDimension,
      }) + (searchParams.toString() ? `?${searchParams.toString()}` : '')
    navigate(to)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  useEffect(() => {
    if (!insightDimension) return
    const updateQueryParams = () => {
      if (reportTypeParam) searchParams.set('reportType', reportTypeParam)
      if (customerSegmentId) {
        searchParams.set('customer_segment_id', customerSegmentId)
      } else {
        searchParams.delete('customer_segment_id')
      }
      const to =
        generatePath(AUTHORIZED_ROUTE.INSIGHT_DETAIL, {
          id: insightDimension,
        }) + `?${searchParams.toString()}` || ''
      navigate(to)
    }
    updateQueryParams()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegmentId])

  useEffect(() => {
    if (!insightDimension || !reportType || !customerSegmentId) return
    const customerSegmentName = (customerSegments && customerSegments.find((cs) => cs.ref.id === customerSegmentId)?.name) || 'all'
    dispatch('ViewInsight', { dimension: insightDimension, reportType: reportType, customerSegment: customerSegmentName })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightDimension, reportType, customerSegmentId])

  // popularity insight
  const [popularityInsightValues, setPopularityInsightValues] = useState<PopularityInsightValue[]>()
  const DEFAULT_VISIBLE_COUNT = 3

  const handleChartDataPointClickForPopularityInsight = (name: string) => {
    const queryFilters = [
      {
        filter_type: 'order_date' as const,
        operator: 'date_range' as const,
        value: { min_date: startDate, max_date: endDate },
      },
    ]
    const segmentQuerySet = customerSegments && customerSegments.find((segment) => segment.ref.id === customerSegmentId)?.querySet
    const additionalQuerySet = segmentQuerySet ? convertQuerySetToForm(convertStringToApiQuerySetState(segmentQuerySet)) : undefined
    const querySet = generateQuerySet(insightDimension, name, queryFilters, additionalQuerySet)
    if (querySet) {
      setInsightDrawerState({ open: true, querySet })
    }
  }

  const { handleDownload: handleDownloadPopularity, loading: downloadLoadingForPopularity } = useCsvDownload({
    data:
      popularityInsightValues &&
      popularityInsightValues.filter(({ isVisible }) => isVisible).map(({ name, customerCount, ratio }) => ({ name, customerCount, ratio })),
    headers: [t('features.insights.detail.name'), t('features.insights.detail.customerCount'), t('features.insights.detail.popularityInsight.ratio')],
    fileName: t('features.insights.detail.popularityInsight.downloadFileName', { dimension: insightDimension }),
    page: 'insight_' + insightDimension,
  })

  // monthly insight
  const [monthlyInsightValues, setMonthlyInsightValues] = useState<MonthlyInsightValue[]>()

  const handleChartDataPointClickForMonthlyInsight = (name: string, month: string) => {
    const queryFilters = [
      {
        filter_type: 'order_date' as const,
        operator: 'date_range' as const,
        value: {
          min_date: dayjs(month).startOf('month').format('YYYY-MM-DD'),
          max_date: dayjs(month).endOf('month').format('YYYY-MM-DD'),
        },
      },
    ]
    const segmentQuerySet = customerSegments && customerSegments.find((segment) => segment.ref.id === customerSegmentId)?.querySet
    const additionalQuerySet = segmentQuerySet ? convertQuerySetToForm(convertStringToApiQuerySetState(segmentQuerySet)) : undefined
    const querySet = generateQuerySet(insightDimension, name, queryFilters, additionalQuerySet)
    if (querySet) {
      setInsightDrawerState({ open: true, querySet })
    }
  }

  const { handleDownload: handleDownloadMonthly, loading: downloadLoadingForMonthly } = useCsvDownload({
    data:
      monthlyInsightValues &&
      monthlyInsightValues
        .filter(({ isVisible }) => isVisible)
        .flatMap(({ name, values }) =>
          values.map(({ yearMonth, customerCount }) => ({
            name,
            yearMonth,
            customerCount,
          }))
        ),
    headers: [
      t('features.insights.detail.name'),
      t('features.insights.detail.monthlyInsight.yearMonth'),
      t('features.insights.detail.customerCount'),
    ],
    fileName: t('features.insights.detail.monthlyInsight.downloadFileName', { dimension: insightDimension }),
    page: 'insight_' + insightDimension,
  })

  // nthOrder insight
  const [nthOrderInsightValues, setNthOrderInsightValues] = useState<NthOrderInsightValue[]>()

  const handleChartDataPointClickForNthOrder = (name: string, index: number) => {
    const sequenceMap: { [key: number]: { min_sequence: number; max_sequence?: number; ascending: boolean } } = {
      0: { min_sequence: 1, max_sequence: 1, ascending: true },
      1: { min_sequence: 2, ascending: true },
      2: { min_sequence: 2, max_sequence: 2, ascending: true },
      3: { min_sequence: 3, max_sequence: 3, ascending: true },
      4: { min_sequence: 4, max_sequence: 4, ascending: true },
      5: { min_sequence: 5, ascending: true },
    }
    const queryFilters = [
      { filter_type: 'order_sequence' as const, operator: 'specify_sequence' as const, value: sequenceMap[index] },
      {
        filter_type: 'order_date' as const,
        operator: 'date_range' as const,
        value: { min_date: startDate, max_date: endDate },
      },
    ]
    const segmentQuerySet = customerSegments && customerSegments.find((segment) => segment.ref.id === customerSegmentId)?.querySet
    const additionalQuerySet = segmentQuerySet ? convertQuerySetToForm(convertStringToApiQuerySetState(segmentQuerySet)) : undefined
    const querySet = generateQuerySet(insightDimension, name, queryFilters, additionalQuerySet)
    if (querySet) {
      setInsightDrawerState({ open: true, querySet })
    }
  }

  const { handleDownload: handleDownloadNthOrder, loading: downloadLoadingForNthOrder } = useCsvDownload({
    data:
      nthOrderInsightValues &&
      nthOrderInsightValues
        .filter(({ isVisible }) => isVisible)
        .map(({ name, total, first, second, secondPlus, third, fourth, fifthPlus }) => ({
          name,
          total,
          first,
          second,
          secondPlus,
          third,
          fourth,
          fifthPlus,
        })),
    headers: [
      t('features.insights.detail.name'),
      t('features.insights.detail.customerCount'),
      t('features.insights.detail.nthOrderInsight.first'),
      t('features.insights.detail.nthOrderInsight.second'),
      t('features.insights.detail.nthOrderInsight.secondPlus'),
      t('features.insights.detail.nthOrderInsight.third'),
      t('features.insights.detail.nthOrderInsight.fourth'),
      t('features.insights.detail.nthOrderInsight.fifthPlus'),
    ],
    fileName: t('features.insights.detail.nthOrderInsight.downloadFileName', { dimension: insightDimension }),
    page: 'insight_' + insightDimension,
  })

  // orderCount insight
  const [orderCountInsightValues, setOrderCountInsightValues] = useState<OrderCountInsightValue[]>()

  const handleChartDataPointClickForOrderCount = (name: string, index: number) => {
    const queryFilters = [
      {
        filter_type: 'order_count' as const,
        operator: 'int_range' as const,
        value: index === 5 ? { min: index } : { min: index, max: index },
      },
      {
        filter_type: 'order_date' as const,
        operator: 'date_range' as const,
        value: { min_date: startDate, max_date: endDate },
      },
    ]
    const segmentQuerySet = customerSegments && customerSegments.find((segment) => segment.ref.id === customerSegmentId)?.querySet
    const additionalQuerySet = segmentQuerySet ? convertQuerySetToForm(convertStringToApiQuerySetState(segmentQuerySet)) : undefined
    const querySet = generateQuerySet(insightDimension, name, queryFilters, additionalQuerySet)
    if (querySet) {
      setInsightDrawerState({ open: true, querySet })
    }
  }

  const { handleDownload: handleDownloadOrderCount, loading: downloadLoadingForOrderCount } = useCsvDownload({
    data:
      orderCountInsightValues &&
      orderCountInsightValues
        .filter(({ isVisible }) => isVisible)
        .map(({ name, total, once, twicePlus, twice, threeTimes, fourTimes, fiveTimesPlus }) => ({
          name,
          total,
          once,
          twicePlus,
          twice,
          threeTimes,
          fourTimes,
          fiveTimesPlus,
        })),
    headers: [
      t('features.insights.detail.name'),
      t('features.insights.detail.customerCount'),
      t('features.insights.detail.orderCountInsight.once'),
      t('features.insights.detail.orderCountInsight.twicePlus'),
      t('features.insights.detail.orderCountInsight.twice'),
      t('features.insights.detail.orderCountInsight.threeTimes'),
      t('features.insights.detail.orderCountInsight.fourTimes'),
      t('features.insights.detail.orderCountInsight.fiveTimesPlus'),
    ],
    fileName: t('features.insights.detail.orderCountInsight.downloadFileName', { dimension: insightDimension }),
    page: 'insight_' + insightDimension,
  })

  // histogram insight
  const [histogramInsightValues, setHistogramInsightValues] = useState<HistogramInsightValue>()

  const handleChartDataPointClickForHistogram = (min: number, max: number) => {
    const queryFilter = {
      filter_type: 'order_date' as const,
      operator: 'date_range' as const,
      value: { min_date: startDate, max_date: endDate },
    }
    const segmentQuerySet = customerSegments && customerSegments.find((segment) => segment.ref.id === customerSegmentId)?.querySet
    const additionalQuerySet = segmentQuerySet ? convertQuerySetToForm(convertStringToApiQuerySetState(segmentQuerySet)) : undefined
    const querySet = generateNumericQuerySet(insightDimension, min, max, queryFilter, additionalQuerySet)
    if (querySet) {
      setInsightDrawerState({ open: true, querySet })
    }
  }

  const { handleDownload: handleDownloadHistogram, loading: downloadLoadingForHistogram } = useCsvDownload({
    data:
      histogramInsightValues &&
      histogramInsightValues.values.map(({ min, max, customerCount }) => ({
        range: `${min} - ${max}`,
        customerCount,
      })),
    headers: [t('features.insights.detail.histogramInsight.range'), t('features.insights.detail.customerCount')],
    fileName: t('features.insights.detail.histogramInsight.downloadFileName', { dimension: insightDimension }),
    page: 'insight_' + insightDimension,
  })

  // fetch data
  useEffect(() => {
    const fetchData = async () => {
      if (!startDate || !endDate) return
      setLoading(true)

      try {
        const token = await getIdToken(authUser!)
        const requestParams = { customerSegmentId: customerSegmentId || '', dimension: insightDimension, startDate, endDate }
        const requestHeaders = { headers: { Authorization: `Bearer ${token}` } }
        if (reportTypeParam === InsightReportType.popularity) {
          const resp = await insightService.getPopularityReport(requestParams, requestHeaders)
          setPopularityInsightValues(
            resp.values.map((v, i) => ({
              rank: i + 1,
              name: v.name,
              customerCount: Number(v.customerCount),
              ratio: Number((v.ratio * 100).toFixed(2)),
              isVisible: shopBillingStatus !== ShopBillingStatus.free || i < DEFAULT_VISIBLE_COUNT,
            }))
          )
        } else if (reportTypeParam === InsightReportType.monthly) {
          const resp = await insightService.getMonthlyReport(requestParams, requestHeaders)
          setMonthlyInsightValues(
            resp.values.map((v, i) => ({
              rank: i + 1,
              name: v.name,
              values: v.monthlyValues.map((v) => ({
                yearMonth: formatYearMonth(dayjs(v.yearMonth), i18n.language),
                customerCount: Number(v.customerCount),
              })),
              isVisible: shopBillingStatus !== ShopBillingStatus.free || i < DEFAULT_VISIBLE_COUNT,
            }))
          )
        } else if (reportTypeParam === InsightReportType.nthOrder) {
          const resp = await insightService.getNthOrderReport(requestParams, requestHeaders)
          setNthOrderInsightValues(
            resp.values.map((v, i) => ({
              rank: i + 1,
              name: v.name,
              first: Number(v.first),
              second: Number(v.second),
              secondPlus: Number(v.secondPlus),
              third: Number(v.third),
              fourth: Number(v.fourth),
              fifthPlus: Number(v.fifthPlus),
              total: Number(v.total),
              isVisible: shopBillingStatus !== ShopBillingStatus.free || i < DEFAULT_VISIBLE_COUNT,
            }))
          )
        } else if (reportTypeParam === InsightReportType.orderCount) {
          const resp = await insightService.getOrderCountReport(requestParams, requestHeaders)
          setOrderCountInsightValues(
            resp.values.map((v, i) => ({
              rank: i + 1,
              name: v.name,
              once: Number(v.once),
              twice: Number(v.twice),
              twicePlus: Number(v.twicePlus),
              threeTimes: Number(v.threeTimes),
              fourTimes: Number(v.fourTimes),
              fiveTimesPlus: Number(v.fiveTimesPlus),
              total: Number(v.total),
              isVisible: shopBillingStatus !== ShopBillingStatus.free || i < DEFAULT_VISIBLE_COUNT,
            }))
          )
        } else if (reportTypeParam === InsightReportType.histogram) {
          const resp = await insightService.getHistogramReport(requestParams, requestHeaders)
          setHistogramInsightValues({
            values: resp.values.map((v) => ({
              min: Number(v.min),
              max: Number(v.max),
              customerCount: Number(v.customerCount),
            })),
            mean: Number(resp.mean),
            median: Number(resp.median),
            max: Number(resp.max),
          })
        }
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightDimension, customerSegmentId, startDate, endDate, reportTypeParam])

  const downloadDisabled = () => {
    if (reportTypeParam === InsightReportType.popularity) {
      return !popularityInsightValues || popularityInsightValues.length === 0 || downloadLoadingForPopularity
    } else if (reportTypeParam === InsightReportType.monthly) {
      return !monthlyInsightValues || monthlyInsightValues.length === 0 || downloadLoadingForMonthly
    } else if (reportTypeParam === InsightReportType.nthOrder) {
      return !nthOrderInsightValues || nthOrderInsightValues.length === 0 || downloadLoadingForNthOrder
    } else if (reportTypeParam === InsightReportType.orderCount) {
      return !orderCountInsightValues || orderCountInsightValues.length === 0 || downloadLoadingForOrderCount
    } else if (reportTypeParam === InsightReportType.histogram) {
      return !histogramInsightValues || downloadLoadingForHistogram
    }
    return true
  }

  const handleDownload = () => {
    if (shopBillingStatus === ShopBillingStatus.free) {
      setUpgradeRecommendDialogOpen(true)
      return
    }
    if (reportTypeParam === InsightReportType.popularity) {
      handleDownloadPopularity()
    } else if (reportTypeParam === InsightReportType.monthly) {
      handleDownloadMonthly()
    } else if (reportTypeParam === InsightReportType.nthOrder) {
      handleDownloadNthOrder()
    } else if (reportTypeParam === InsightReportType.orderCount) {
      handleDownloadOrderCount()
    } else if (reportTypeParam === InsightReportType.histogram) {
      handleDownloadHistogram()
    }
  }

  return (
    <>
      <PageHeader
        title={insightDimension ? t('features.insights.dimension', { context: insightDimension }) : ''}
        origin={{
          title: t('features.insights.detail.customerInsights'),
          path:
            customerSegmentId && customerSegmentId !== ''
              ? `${AUTHORIZED_ROUTE.INSIGHTS_OVERVIEW}?customer_segment_id=${customerSegmentId}`
              : AUTHORIZED_ROUTE.INSIGHTS_OVERVIEW,
        }}
      />

      <PageContentWrapper>
        <div className='flex items-center justify-between mb-6'>
          <div className='flex items-center gap-2'>
            <Select value={customerSegmentId} onValueChange={(value) => setCustomerSegmentId(value)} disabled={!customerSegments}>
              <SelectTrigger>
                <SelectValue placeholder={t('features.cohorts.segment_all')} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>{t('features.cohorts.segment')}</SelectLabel>
                  {customerSegments?.map((segment) => (
                    <SelectItem key={segment.ref.id} value={segment.ref.id}>
                      {segment.name}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
            <DateRangePicker
              defaultDateRange={{
                from: dayjs(defaultStartDate).toDate(),
                to: dayjs(defaultEndDate).toDate(),
              }}
              defaultCustomDateRange={defaultCustomDateRange}
              onDateChange={(date) => {
                if (shopBillingStatus === ShopBillingStatus.free) {
                  setUpgradeRecommendDialogOpen(true)
                  return
                }
                setStartDate(dayjs(date.from).format('YYYY-MM-DD'))
                setEndDate(dayjs(date.to).format('YYYY-MM-DD'))
              }}
              timezone={shop.timezone}
            />
            <Tabs
              value={reportType}
              onValueChange={(value) => {
                if (value === null) return
                searchParams.set('reportType', value)
                if (customerSegmentId) searchParams.set('customer_segment_id', customerSegmentId)
                const to =
                  insightDimension &&
                  generatePath(AUTHORIZED_ROUTE.INSIGHT_DETAIL, {
                    id: insightDimension,
                  }) + (searchParams.toString() ? `?${searchParams.toString()}` : '')
                navigate(to)
              }}
            >
              <TabsList>
                {dimensionProfile?.reportTypes.map((type) => (
                  <TabsTrigger key={type} value={type} className='gap-2'>
                    {type === InsightReportType.popularity && <ChartPie size={16} />}
                    {type === InsightReportType.monthly && <Calendar size={16} />}
                    {type === InsightReportType.nthOrder && <ChartBar size={16} />}
                    {type === InsightReportType.orderCount && <ChartBarBig size={16} />}
                    {type === InsightReportType.histogram && <ChartColumnBig size={16} />}
                    {t(`features.insights.detail.reportType_${type}`)}
                  </TabsTrigger>
                ))}
              </TabsList>
            </Tabs>
          </div>
          <div className='flex items-center gap-2'>
            <Tabs value={viewType} onValueChange={(value) => setViewType(value as ViewType)}>
              <TabsList>
                <TabsTrigger value={ViewType.visual}>{t('features.insights.detail.viewType_visual')}</TabsTrigger>
                <TabsTrigger value={ViewType.table}>{t('features.insights.detail.viewType_table')}</TabsTrigger>
              </TabsList>
            </Tabs>
            <div className='flex items-center'>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button variant='ghost' size='icon' disabled={downloadDisabled()} onClick={handleDownload}>
                    <Download />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>{t('features.customerSegments.overview.download')}</TooltipContent>
              </Tooltip>
            </div>
          </div>
        </div>

        {insightDimension && reportTypeParam === InsightReportType.popularity && (
          <PopularityInsight
            viewType={viewType}
            insightValues={popularityInsightValues}
            loading={loading}
            onChartPointClick={handleChartDataPointClickForPopularityInsight}
          />
        )}
        {insightDimension && reportTypeParam === InsightReportType.monthly && (
          <MonthlyInsight
            viewType={viewType}
            insightValues={monthlyInsightValues}
            loading={loading}
            onChartPointClick={handleChartDataPointClickForMonthlyInsight}
          />
        )}
        {insightDimension && reportTypeParam === InsightReportType.nthOrder && (
          <NthOrderInsight
            viewType={viewType}
            insightValues={nthOrderInsightValues}
            loading={loading}
            onChartPointClick={handleChartDataPointClickForNthOrder}
          />
        )}
        {insightDimension && reportTypeParam === InsightReportType.orderCount && (
          <OrderCountInsight
            viewType={viewType}
            insightValues={orderCountInsightValues}
            loading={loading}
            onChartPointClick={handleChartDataPointClickForOrderCount}
          />
        )}
        {insightDimension && reportTypeParam === InsightReportType.histogram && (
          <HistogramInsight
            dimension={insightDimension}
            insightValues={histogramInsightValues}
            loading={loading}
            onChartPointClick={handleChartDataPointClickForHistogram}
          />
        )}
      </PageContentWrapper>

      {upgradeRecommendDialogOpen && (
        <UpgradeRecommendDialog open={upgradeRecommendDialogOpen} handleClose={() => setUpgradeRecommendDialogOpen(false)} referrer='feature' />
      )}

      <InsightDrawer
        open={insightDrawerState.open}
        handleOpen={() => setInsightDrawerState({ open: true })}
        handleClose={() => setInsightDrawerState({ open: false })}
        querySet={insightDrawerState.querySet}
      />
    </>
  )
}
