import { DialogWrapper } from '@/components/dialogWrapper'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { Code, ConnectError } from '@connectrpc/connect'
import Picker from '@emoji-mart/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormControl, Popover } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { extractIconFromTextWithIcon, extractTextFromTextWithIcon } from 'utils/iconUtil'
import { z } from 'zod'

type Props = {
  open: boolean
  handleClose: () => void
  id: string
  name: string
  description: string
  favorited: boolean
}

export const UpdateDialog: FC<Props> = ({ open, handleClose, id, name, description, favorited }) => {
  const { t, i18n } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const customerSegmentService = useGrpcClient(CustomerSegmentService)

  const inputSchema = z.object({
    name: z
      .string()
      .min(1, { message: t('features.customerSegments.components.updateDialog.messageValidationNameMin') })
      .max(64, { message: t('features.customerSegments.components.updateDialog.messageValidationNameMax') }),
    description: z.string().max(128, { message: t('features.customerSegments.components.updateDialog.messageValidationDescriptionMax') }),
  })

  type InputSchemaType = z.infer<typeof inputSchema>

  // Divide customer list name into icon and name
  const defaultEmoji = '😀'
  const icon = extractIconFromTextWithIcon(name) || defaultEmoji
  const nameWithoutIcon = extractTextFromTextWithIcon(name)

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<InputSchemaType>({
    resolver: zodResolver(inputSchema),
    defaultValues: {
      name: nameWithoutIcon,
      description: description,
    },
  })

  // EmojiPicker
  const [emojiIcon, setEmojiIcon] = useState<string>(icon)
  const [openEmojiPicker, setOpenEmojiPicker] = useState(false)
  const emojiAnchorRef = useRef<HTMLButtonElement | null>(null)

  const updateSegment = async (input: InputSchemaType) => {
    try {
      const token = await getIdToken(authUser!)
      await customerSegmentService.update(
        {
          customerSegmentId: id,
          name: `${emojiIcon} ${input.name}`,
          description: input.description,
          favorited: favorited,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      enqueueSnackbar(t('features.customerSegments.components.updateDialog.messageSaved'), { severity: 'success' })
      handleClose()
    } catch (err) {
      if (err instanceof ConnectError && err.code === Code.AlreadyExists) {
        setError('name', { message: t('features.customerSegments.components.updateDialog.messageAlreadyExists') })
        return
      }
      enqueueSnackbar(t('features.customerSegments.components.updateDialog.messageError'), { severity: 'error' })
      notifySentry(err)
    }
  }

  return (
    <DialogWrapper
      open={open}
      handleClose={handleClose}
      title={t('features.customerSegments.components.updateDialog.title')}
      description={t('features.customerSegments.components.updateDialog.description')}
      mainContent={
        <div className='flex flex-col gap-3'>
          <div className='flex items-center gap-2'>
            <Button ref={emojiAnchorRef} variant='outline' onClick={() => setOpenEmojiPicker(true)}>
              {emojiIcon}
            </Button>
            <Popover
              open={openEmojiPicker}
              anchorEl={emojiAnchorRef.current}
              onClose={() => setOpenEmojiPicker(false)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Picker
                locale={i18n.language}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onEmojiSelect={(emoji: any) => {
                  setEmojiIcon(emoji.native)
                  setOpenEmojiPicker(false)
                }}
              />
            </Popover>
            <Controller
              control={control}
              name='name'
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl className='w-full'>
                    <Input {...field} placeholder={t('features.customerSegments.components.updateDialog.fieldName')} />
                    {error && <p className='text-red-500 text-xs mt-1 ml-2'>{error.message}</p>}
                  </FormControl>
                )
              }}
            />
          </div>
          <div>
            <Controller
              control={control}
              name='description'
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl className='w-full'>
                    <Textarea {...field} placeholder={t('features.customerSegments.components.updateDialog.fieldDescription')} />
                    {error && <p className='text-red-500 text-xs mt-1 ml-2'>{error.message}</p>}
                  </FormControl>
                )
              }}
            />
          </div>
        </div>
      }
      onSubmit={handleSubmit(updateSegment)}
      loading={isSubmitting}
      cancelButtonText={t('features.customerSegments.components.updateDialog.cancel')}
      submitButtonText={t('features.customerSegments.components.updateDialog.submit')}
    />
  )
}
