import { Box, Dialog, Divider, Typography } from '@mui/material'
import React, { FC } from 'react'
import { QuerySetFormState } from '../../../querySetForm/schema/querySetFormSchema'
import { QuerySet } from '../../../querySetView/querySet'

type Props = {
  open: boolean
  handleClose: () => void
  name: string
  description: string
  querySet: QuerySetFormState
}

export const DetailDialog: FC<Props> = ({ open, handleClose, name, description, querySet }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='md'
      fullWidth
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
    >
      <Box padding='24px 24px 20px'>
        <Typography fontSize='18px' marginBottom='12px'>
          {name}
        </Typography>
        {description && (
          <>
            <Typography fontSize='14px' color='text.secondary' marginBottom='16px'>
              {description}
            </Typography>
            <Divider sx={{ marginBottom: '16px' }} />
          </>
        )}
        <QuerySet querySet={querySet} />
      </Box>
    </Dialog>
  )
}
