import { DialogWrapper } from '@/components/dialogWrapper'
import { Input } from '@/components/ui/input'
import { Code, ConnectError } from '@connectrpc/connect'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormControl } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { Account } from 'gen/firestore'
import { AccountService } from 'gen/proto/account/account_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

type Props = {
  open: boolean
  account: Account
  handleClose: () => void
}

export const UpdateEmailDialog: FC<Props> = ({ open, account, handleClose }) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const accountService = useGrpcClient(AccountService)

  const segmentUpdateInput = z
    .object({
      email: z
        .string()
        .min(1, { message: t('features.settings.myAccount.updateEmailDialog.messageValidationEmailMin') })
        .email(t('features.settings.myAccount.updateEmailDialog.messageValidationEmailIsInvalid')),
      confirmEmail: z.string().min(1, { message: t('features.settings.myAccount.updateEmailDialog.messageValidationEmailMin') }),
    })
    .refine((data) => data.email === data.confirmEmail, {
      message: t('features.settings.myAccount.updateEmailDialog.messageValidationEmailMatch'),
      path: ['confirmEmail'],
    })

  type InputSchema = z.infer<typeof segmentUpdateInput>

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<InputSchema>({
    resolver: zodResolver(segmentUpdateInput),
    defaultValues: {
      email: '',
      confirmEmail: '',
    },
  })

  const updateEmail = async (input: InputSchema) => {
    try {
      const token = await getIdToken(authUser!)
      await accountService.updateEmail({ currentEmail: account.email, newEmail: input.email }, { headers: { Authorization: `Bearer ${token}` } })
      enqueueSnackbar(t('features.settings.myAccount.updateEmailDialog.messageSaved'), { severity: 'success' })
      handleClose()
      window.location.reload() // Reload the page to ensure the user is directed to the login page
    } catch (err) {
      if (err instanceof ConnectError && err.code === Code.AlreadyExists) {
        setError('email', { message: t('features.settings.myAccount.updateEmailDialog.messageAlreadyExists') })
        return
      }
      enqueueSnackbar(t('features.settings.myAccount.updateEmailDialog.messageError'), { severity: 'error' })
      notifySentry(err)
    }
  }

  return (
    <DialogWrapper
      open={open}
      handleClose={handleClose}
      title={t('features.settings.myAccount.updateEmailDialog.title')}
      mainContent={
        <div className='flex flex-col gap-2'>
          <Controller
            control={control}
            name='email'
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl className='w-full'>
                  <Input {...field} placeholder={t('features.settings.myAccount.updateEmailDialog.fieldEmail')} />
                  {error && <p className='text-red-500 text-xs mt-1 ml-2'>{error.message}</p>}
                </FormControl>
              )
            }}
          />
          <Controller
            control={control}
            name='confirmEmail'
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl className='w-full'>
                  <Input {...field} placeholder={t('features.settings.myAccount.updateEmailDialog.fieldConfirmEmail')} />
                  {error && <p className='text-red-500 text-xs mt-1 ml-2'>{error.message}</p>}
                </FormControl>
              )
            }}
          />
        </div>
      }
      onSubmit={handleSubmit(updateEmail)}
      loading={isSubmitting}
      cancelButtonText={t('features.settings.myAccount.updateEmailDialog.cancel')}
      submitButtonText={t('features.settings.myAccount.updateEmailDialog.submit')}
    />
  )
}
