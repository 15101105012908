import { Badge } from '@/components/ui/badge'
import { FC } from 'react'

type Props = {
  label: string
}

export const OperatorChip: FC<Props> = ({ label }) => {
  return (
    <Badge variant='secondary' className='mr-1'>
      {label}
    </Badge>
  )
}
