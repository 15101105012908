import { useMemo } from 'react'
import { createClient, Client } from '@connectrpc/connect'
import { createConnectTransport } from '@connectrpc/connect-web'
import { DescService } from '@bufbuild/protobuf'
import { env } from 'config/env'


const transport = createConnectTransport({
  fetch: (input, init) => fetch(input, { ...init, credentials: 'omit' }),
  baseUrl: env.ECPOWER_API_URL,
  interceptors: [],
})

// Get a promise client for the given service.
export const useGrpcClient = <T extends DescService>(service: T): Client<T> => {
  return useMemo(() => createClient(service, transport), [service])
}