import EditIcon from '@mui/icons-material/Edit'
import { Box, ClickAwayListener, Divider, Grid, IconButton, MenuItem, Paper, Select, Skeleton, Tooltip, Typography } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { Account } from 'gen/firestore'
import { AccountService } from 'gen/proto/account/account_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useThemeContext } from 'provider/themeContext'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ResetPasswordDialog } from './components/resetPasswordDialog'
import { UpdateEmailDialog } from './components/updateEmailDialog'

type Props = {
  account: Account
}

export const MyAccount: FC<Props> = ({ account }) => {
  const { t, i18n } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { setThemeMode } = useThemeContext()
  const accountService = useGrpcClient(AccountService)

  const [updateEmailDialogOpen, setUpdateEmailDialogOpen] = useState(false)
  const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false)
  const [changeLanguageFormOpen, setChangeLanguageFormOpen] = useState(false)
  const [changeDisplayModeFormOpen, setChangeDisplayModeFormOpen] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeLanguage = async (lng: any) => {
    try {
      const token = await getIdToken(authUser!)
      await accountService.updateLanguage({ language: lng }, { headers: { Authorization: `Bearer ${token}` } })
      i18n.changeLanguage(lng)
      enqueueSnackbar(t('features.settings.myAccount.messageLanguageSuccess'), { severity: 'success' })
    } catch (err) {
      enqueueSnackbar(t('features.settings.myAccount.messageLanguageError'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setChangeLanguageFormOpen(false)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeDisplayMode = async (mode: any) => {
    try {
      const token = await getIdToken(authUser!)
      await accountService.updateDisplayMode({ displayDarkMode: mode === 'dark' }, { headers: { Authorization: `Bearer ${token}` } })
      setThemeMode(mode === 'dark' ? 'dark' : 'light')
      enqueueSnackbar(t('features.settings.myAccount.messageDisplayModeSuccess'), { severity: 'success' })
    } catch (err) {
      enqueueSnackbar(t('features.settings.myAccount.messageDisplayModeError'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setChangeDisplayModeFormOpen(false)
    }
  }

  return (
    <>
      <Paper sx={{ padding: '32px', marginBottom: '24px', borderRadius: '12px' }} variant='outlined'>
        <Box>
          <Typography fontWeight='bold' marginBottom='16px'>
            {t('features.settings.myAccount.profile')}
          </Typography>

          <Grid container spacing={1} paddingLeft='4px'>
            <Grid fontSize='14px' item xs={1.5}>
              {t('features.settings.myAccount.email')}
            </Grid>
            <Grid fontSize='14px' item xs={10.5}>
              <Box display='flex' alignItems='center'>
                <Typography fontSize='14px'>{account ? account.email : <Skeleton width='120px' />}</Typography>
                <Tooltip title={t('features.settings.edit')} placement='right'>
                  <IconButton
                    size='small'
                    onClick={() => setUpdateEmailDialogOpen(true)}
                    sx={{ marginLeft: '4px', padding: '4px', borderRadius: '8px' }}
                  >
                    <EditIcon fontSize='inherit' sx={{ fontSize: '14px' }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>

            <Grid fontSize='14px' item xs={1.5}>
              {t('features.settings.myAccount.password')}
            </Grid>
            <Grid fontSize='14px' item xs={10.5}>
              <Box display='flex' alignItems='center'>
                <Typography fontSize='14px'>•••••••••••</Typography>
                <Tooltip title={t('features.settings.edit')} placement='right'>
                  <IconButton
                    size='small'
                    onClick={() => setResetPasswordDialogOpen(true)}
                    sx={{ marginLeft: '4px', padding: '4px', borderRadius: '8px' }}
                  >
                    <EditIcon fontSize='inherit' sx={{ fontSize: '14px' }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ marginY: '24px' }} />

        <Box>
          <Typography fontWeight='bold' marginBottom='16px'>
            {t('features.settings.myAccount.appearance')}
          </Typography>

          <Grid container spacing={1} paddingLeft='4px'>
            <Grid fontSize='14px' item xs={1.5}>
              {t('features.settings.myAccount.language')}
            </Grid>
            <Grid fontSize='14px' item xs={10.5}>
              {!changeLanguageFormOpen ? (
                <Box display='flex' alignItems='center'>
                  <Typography fontSize='14px'>
                    {account ? t('features.settings.myAccount.language', { context: account.language }) : <Skeleton />}
                  </Typography>
                  <Tooltip title={t('features.settings.edit')} placement='right'>
                    <IconButton
                      size='small'
                      onClick={() => setChangeLanguageFormOpen(true)}
                      sx={{ marginLeft: '4px', padding: '4px', borderRadius: '8px' }}
                    >
                      <EditIcon fontSize='inherit' sx={{ fontSize: '14px' }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              ) : (
                <ClickAwayListener onClickAway={() => setChangeLanguageFormOpen(false)} mouseEvent='onMouseDown'>
                  <Select size='small' value={account.language} onChange={(e) => handleChangeLanguage(e.target.value)}>
                    <MenuItem value='en'>{t('features.settings.myAccount.language', { context: 'en' })}</MenuItem>
                    <MenuItem value='ja'>{t('features.settings.myAccount.language', { context: 'ja' })}</MenuItem>
                  </Select>
                </ClickAwayListener>
              )}
            </Grid>

            <Grid fontSize='14px' item xs={1.5}>
              {t('features.settings.myAccount.displayMode')}
            </Grid>
            <Grid fontSize='14px' item xs={10.5}>
              {!changeDisplayModeFormOpen ? (
                <Box display='flex' alignItems='center'>
                  <Typography fontSize='14px'>
                    {account ? (
                      account.displayDarkMode ? (
                        t('features.settings.myAccount.dark')
                      ) : (
                        t('features.settings.myAccount.light')
                      )
                    ) : (
                      <Skeleton />
                    )}
                  </Typography>
                  {/* MEMO: Stop dark mode while replacing the theme to shadcn. */}
                  {/* <Tooltip title={t('features.settings.edit')} placement='right'>
                    <IconButton
                      size='small'
                      onClick={() => setChangeDisplayModeFormOpen(true)}
                      sx={{ marginLeft: '4px', padding: '4px', borderRadius: '8px' }}
                    >
                      <EditIcon sx={{ fontSize: '14px' }} />
                    </IconButton>
                  </Tooltip> */}
                </Box>
              ) : (
                <ClickAwayListener onClickAway={() => setChangeDisplayModeFormOpen(false)} mouseEvent='onMouseDown'>
                  <Select size='small' value={account.displayDarkMode ? 'dark' : 'light'} onChange={(e) => handleChangeDisplayMode(e.target.value)}>
                    <MenuItem value='light'>{t('features.settings.myAccount.light')}</MenuItem>
                    <MenuItem value='dark'>{t('features.settings.myAccount.dark')}</MenuItem>
                  </Select>
                </ClickAwayListener>
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>

      {updateEmailDialogOpen && (
        <UpdateEmailDialog open={updateEmailDialogOpen} account={account} handleClose={() => setUpdateEmailDialogOpen(false)} />
      )}

      {resetPasswordDialogOpen && (
        <ResetPasswordDialog open={resetPasswordDialogOpen} account={account} handleClose={() => setResetPasswordDialogOpen(false)} />
      )}
    </>
  )
}
