import { DialogWrapper } from '@/components/dialogWrapper'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { OrderPricingRule, Shop } from 'gen/firestore'
import { ShopService } from 'gen/proto/shop/shop_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

type Props = {
  open: boolean
  shop: Shop
  handleClose: () => void
}

export const UpdateOrderPricingRuleDialog: FC<Props> = ({ open, shop, handleClose }) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const shopService = useGrpcClient(ShopService)

  const orderPricingRuleSchema = z.object({
    orderPricingRule: z.string().min(1, { message: t('features.settings.workspace.updateOrderPricingRuleDialog.messageValidationPricingRule') }),
  })

  type InputSchema = z.infer<typeof orderPricingRuleSchema>

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<InputSchema>({
    resolver: zodResolver(orderPricingRuleSchema),
    defaultValues: {
      orderPricingRule: shop.orderPricingRule,
    },
  })

  const updatePricingMethod = async (input: InputSchema) => {
    try {
      const token = await getIdToken(authUser!)
      await shopService.updateOrderPricingRule({ orderPricingRule: input.orderPricingRule }, { headers: { Authorization: `Bearer ${token}` } })
      enqueueSnackbar(t('features.settings.workspace.updateOrderPricingRuleDialog.messageSaved'), { severity: 'success' })
      handleClose()
    } catch (err) {
      enqueueSnackbar(t('features.settings.workspace.updateOrderPricingRuleDialog.messageError'), { severity: 'error' })
      notifySentry(err)
    }
  }

  return (
    <DialogWrapper
      open={open}
      handleClose={handleClose}
      title={t('features.settings.workspace.updateOrderPricingRuleDialog.title')}
      description={t('features.settings.workspace.updateOrderPricingRuleDialog.description')}
      mainContent={
        <div className='flex flex-col gap-2'>
          <Controller
            control={control}
            name='orderPricingRule'
            render={({ field }) => {
              return (
                <FormControl component='fieldset'>
                  <RadioGroup {...field}>
                    {Object.values(OrderPricingRule).map((rule) => (
                      <FormControlLabel
                        key={rule}
                        value={rule}
                        control={<Radio size='small' />}
                        label={<Typography fontSize='15px'>{t(`features.settings.workspace.orderPricingRule_${rule}`)}</Typography>}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )
            }}
          />
        </div>
      }
      onSubmit={handleSubmit(updatePricingMethod)}
      loading={isSubmitting}
      cancelButtonText={t('features.settings.workspace.updateOrderPricingRuleDialog.cancel')}
      submitButtonText={t('features.settings.workspace.updateOrderPricingRuleDialog.submit')}
    />
  )
}
