import { Dialog } from '@mui/material'
import { Loader2 } from 'lucide-react'
import { FC } from 'react'
import { Button } from './ui/button'

type Props = {
  open: boolean
  handleClose: () => void
  title: string
  description?: string
  mainContent: React.ReactNode
  onSubmit: () => void
  loading: boolean
  cancelButtonText: string
  submitButtonText: string
  submitButtonDisabled?: boolean
}

export const DialogWrapper: FC<Props> = ({
  open,
  handleClose,
  title,
  description,
  mainContent,
  onSubmit,
  loading,
  cancelButtonText,
  submitButtonText,
  submitButtonDisabled,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='xs'
      fullWidth
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
    >
      <div className='flex flex-col gap-6 p-6'>
        <div className='flex flex-col gap-2'>
          <h2 className='text-lg font-bold'>{title}</h2>
          {description && <p className='text-sm text-gray-500'>{description}</p>}
        </div>
        {mainContent}
        <div className='flex justify-end gap-2'>
          {cancelButtonText.length > 0 && (
            <Button variant='outline' disabled={loading} onClick={handleClose}>
              {cancelButtonText}
            </Button>
          )}
          <Button type='submit' disabled={loading || submitButtonDisabled} onClick={onSubmit}>
            {loading ? <Loader2 className='animate-spin' /> : submitButtonText}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
