import { AppLoadingScreen } from 'components/appLoadingScreen'
import { Unsubscribe } from 'firebase/auth'
import { doc, onSnapshot } from 'firebase/firestore'
import { Account, Shop } from 'gen/firestore'
import { useAuthUser } from 'hooks/useAuthUser'
import { useRootCollectionRef } from 'hooks/useCollectionRef'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, ReactNode, createContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type AccountContextType = {
  loading: false
  account: Account
  shop: Shop
}

type AccountContextTypeLoading = {
  loading: true
  account: undefined
  shop: undefined
}

type AccountContextTypeError = {
  loading: false
  account: undefined
  shop: undefined
}

export const AccountContext = createContext<AccountContextType | AccountContextTypeLoading | AccountContextTypeError>({
  loading: true,
  account: undefined,
  shop: undefined,
})

type ProviderProps = {
  children: ReactNode
}

export const AccountProvider: FC<ProviderProps> = ({ children }) => {
  const { accountsRef } = useRootCollectionRef()
  const user = useAuthUser()
  const docRef = user && doc(accountsRef, user.uid)

  const { t } = useTranslation()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const [result, setResult] = useState<AccountContextType | AccountContextTypeLoading | AccountContextTypeError>({
    loading: true,
    account: undefined,
    shop: undefined,
  })

  useEffect(() => {
    if (!docRef) {
      setResult({
        loading: true,
        account: undefined,
        shop: undefined,
      })
      return
    }

    let unsubscribeShop: Unsubscribe | undefined
    const unsubscribeAccount = onSnapshot(docRef, {
      next: async (accountDocSnap) => {
        if (!accountDocSnap.exists()) {
          enqueueSnackbar(t('common.messageDataNotFound'), { severity: 'error' })
          const err = new Error('accountDocSnap does not exist.')
          notifySentry(err)
          return
        }
        const accountData: Account = accountDocSnap.data() as Account
        unsubscribeShop = onSnapshot(accountData.shopRef!, {
          next: async (shopDocSnap) => {
            const shopData = shopDocSnap.data() as Shop
            setResult({
              loading: false,
              account: accountData,
              shop: shopData,
            })
          },
          error: (err) => {
            setResult({ loading: false, account: undefined, shop: undefined })
            enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
            notifySentry(err)
          },
        })
      },
      error: (err) => {
        setResult({ loading: false, account: undefined, shop: undefined })
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      },
    })

    return () => {
      if (unsubscribeShop) {
        unsubscribeShop()
      }
      unsubscribeAccount()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (result.loading) return <AppLoadingScreen />
  if (!result.account || !result.shop) return <></>

  return <AccountContext.Provider value={result}>{children}</AccountContext.Provider>
}
