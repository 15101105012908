import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material'
import { auth } from 'config/firebaseClient'
import { signOut } from 'firebase/auth'
import { useAccount } from 'hooks/useAccount'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { getImagePath } from 'utils/imageUtil'

const appBarHeight = '64px'
const logoImgPath = '/logo.png'

export const DataSyncLayout = () => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { account, shop } = useAccount()
  const theme = useTheme()
  const [accountMenuOpen, setAccountMenuOpen] = useState(false)
  const accountMenuAnchorRef = useRef<HTMLButtonElement | null>(null)

  const handleLogout = async () => {
    try {
      await signOut(auth)
    } catch (err) {
      enqueueSnackbar(t('layouts.dataSyncLayout.messageLogoutError'), { severity: 'error' })
      notifySentry(err)
    }
  }

  return (
    <Box display='flex'>
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <AppBar
          position='absolute'
          sx={{
            height: appBarHeight,
            zIndex: (theme) => theme.zIndex.drawer + 1,
            boxShadow: 'none',
            backgroundColor: 'white',
          }}
        >
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <img
              src={logoImgPath}
              alt='ecpower-logo'
              style={{
                height: 'auto',
                maxWidth: '128px',
              }}
            />

            <IconButton ref={accountMenuAnchorRef} onClick={() => setAccountMenuOpen(true)} sx={{ borderRadius: '8px' }}>
              <AccountCircleIcon />
            </IconButton>

            <Menu anchorEl={accountMenuAnchorRef.current} open={accountMenuOpen} onClose={() => setAccountMenuOpen(false)}>
              <MenuList dense sx={{ padding: '0px' }}>
                <MenuItem sx={{ pointerEvents: 'none' }}>
                  <Box display='flex' alignItems='center' paddingY='4px'>
                    <Avatar
                      src={theme.palette.mode === 'dark' ? getImagePath('avatar_dark.svg') : getImagePath('avatar.svg')}
                      sx={{ width: '28px', height: '28px', marginRight: '8px' }}
                    />
                    <Box sx={{ width: '180px' }}>
                      <Typography fontSize='14px' fontWeight='bold' marginBottom='-2px' noWrap>
                        {shop.shopName}
                      </Typography>
                      <Typography fontSize='12px' color='text.secondary' noWrap overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
                        {account.email}
                      </Typography>
                    </Box>
                  </Box>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutOutlinedIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>{t('layouts.dashboardLayout.logout')}</ListItemText>
                </MenuItem>
              </MenuList>
            </Menu>
          </Toolbar>
        </AppBar>

        <Box>
          <Outlet />
        </Box>
      </Box>
    </Box>
  )
}
