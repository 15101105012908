import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCustomSnackbar } from './useCustomSnackbar'
import { useActionTracker } from './useMixpanel'
import { useSentryNotifier } from './useSentryNotifier'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any // any
  headers: string[]
  fileName: string
  page: string
}

export const useCsvDownload = ({ data, headers, fileName, page }: Props) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { dispatch } = useActionTracker()

  const [loading, setLoading] = useState(false)

  const handleDownload = async () => {
    try {
      setLoading(true)

      // Create CSV content
      const csvContent = [
        headers.join(','), // Add headers
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...data.map((row: any) => Object.values(row).join(',')),
      ].join('\n')

      // Create a blob and trigger download
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${fileName}.csv`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      dispatch('DownloadCSV', { page: page })
    } catch (err) {
      enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setLoading(false)
    }
  }

  return {
    handleDownload,
    loading,
  }
}
