import { PageContentWrapper } from '@/components/pageContentWrapper'
import { PageHeader } from '@/components/pageHeader'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader } from '@/components/ui/card'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Skeleton } from '@/components/ui/skeleton'
import { Table, TableBody, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { Pagination } from '@mui/material'
import { EmptyState } from 'components/emptyState'
import { SkeletonTable } from 'components/skeletonTable'
import { UpgradeRecommendDialog } from 'components/upgradeRecommendDialog'
import { billingStatus, customerSegmentCountLimit } from 'config/plan'
import dayjs from 'dayjs'
import { CustomerSegmentState } from 'features/customerSegments/types/types'
import { orderBy, query } from 'firebase/firestore'
import { ShopBillingStatus } from 'gen/firestore'
import { useAccount } from 'hooks/useAccount'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCsvDownload } from 'hooks/useCsvDownload'
import { useCollectionSubscription } from 'hooks/useFirestoreData'
import useLocalStorage from 'hooks/useLocalStrage'
import { useActionTracker } from 'hooks/useMixpanel'
import { NestedKeyOf, sortType, useSort } from 'hooks/useSort'
import { ArrowDownUp, Download, FolderOpen, LayoutGrid, Rows3, Search } from 'lucide-react'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { extractTextFromTextWithIcon } from 'utils/iconUtil'
import { formatDate } from 'utils/timeUtil'
import { SegmentGridItem } from '../../components/segmentGridItem'
import { SegmentTableItem } from '../../components/segmentTableItem'
import { CreateDialog } from '../group/components/createDialog'
import { SegmentEmptyState } from './components/segmentEmptyState'

type Props = {
  rows: CustomerSegmentState[] | undefined
}

enum ViewType {
  grid = 'grid',
  table = 'table',
}

export const Overview: FC<Props> = ({ rows }) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { account, shop } = useAccount()
  const shopBillingStatus = billingStatus(shop, dayjs())
  const { dispatch } = useActionTracker()

  const [page, setPage] = useState(0)
  const rowsPerPage = 20
  const [searchOpen, setSearchOpen] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [viewType, setViewType] = useLocalStorage<ViewType>('SegmentOverviewViewType', ViewType.table)

  const [createGroupDialogOpen, setCreateGroupDialogOpen] = useState(false)
  const [upgradeRecommendDialogOpen, setUpgradeRecommendDialogOpen] = useState(false)

  const { segmentGroupsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: segmentGroups } = useCollectionSubscription(query(segmentGroupsRef, orderBy('createdAt', 'desc')))

  const filteredCustomerSegments = useMemo(() => {
    if (!rows) return undefined
    return rows.filter((row) => !searchInput || row.name.toLowerCase().includes(searchInput.toLowerCase()))
  }, [rows, searchInput])

  const { sortedData: sortedFilteredCustomerSegments, handleSort } = useSort<CustomerSegmentState>(filteredCustomerSegments || [])

  const handleCreate = () => {
    if (!rows) return
    if (shopBillingStatus === ShopBillingStatus.free && rows.length >= customerSegmentCountLimit(shopBillingStatus)) {
      setUpgradeRecommendDialogOpen(true)
      return
    }
    navigate(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_CREATE)
  }

  const { handleDownload, loading: downloadLoading } = useCsvDownload({
    data: filteredCustomerSegments?.map((row) => {
      return {
        segmentName: extractTextFromTextWithIcon(row.name),
        count: row.metrics?.count,
        repeaterRate: row.metrics?.repeaterRate?.toFixed(3),
        ltv: row.metrics?.ltv,
        avgOrderValue: row.metrics?.avgOrderValue,
        avgOrderCount: row.metrics?.avgOrderCount?.toFixed(2),
        avgDaysBetweenOrders: row.metrics?.avgDaysBetweenOrders?.toFixed(1),
      }
    }),
    headers: [
      t('features.customerSegments.overview.name'),
      t('common.customerMetrics.title', { context: 'count' }),
      t('common.customerMetrics.title', { context: 'repeaterRate' }),
      t('common.customerMetrics.title', { context: 'ltv' }),
      t('common.customerMetrics.title', { context: 'avgOrderValue' }),
      t('common.customerMetrics.title', { context: 'avgOrderCount' }),
      t('common.customerMetrics.title', { context: 'avgDaysBetweenOrders' }),
    ],
    fileName: t('features.customerSegments.overview.downloadFileName', { date: formatDate(dayjs(), i18n.language) }),
    page: 'customerSegments',
  })

  const sortOptions: { name: string; target: NestedKeyOf<CustomerSegmentState>; orderBy: sortType }[] = [
    { name: t('features.customerSegments.overview.sortMenuItem.createdAtLatest'), target: 'createdAt', orderBy: 'desc' },
    { name: t('features.customerSegments.overview.sortMenuItem.createdAtOldest'), target: 'createdAt', orderBy: 'asc' },
    { name: t('features.customerSegments.overview.sortMenuItem.customersHighToLow'), target: 'metrics.count', orderBy: 'desc' },
    { name: t('features.customerSegments.overview.sortMenuItem.customersLowToHigh'), target: 'metrics.count', orderBy: 'asc' },
    { name: t('features.customerSegments.overview.sortMenuItem.lifetimeValueHighToLow'), target: 'metrics.ltv', orderBy: 'desc' },
    { name: t('features.customerSegments.overview.sortMenuItem.lifetimeValueLowToHigh'), target: 'metrics.ltv', orderBy: 'asc' },
  ]

  return (
    <>
      <PageHeader
        title={t('features.customerSegments.overview.title')}
        menuComponent={
          <>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant='ghost' size='icon' onClick={() => setViewType(viewType === ViewType.grid ? ViewType.table : ViewType.grid)}>
                  {viewType === ViewType.grid ? <Rows3 /> : <LayoutGrid />}
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                {viewType === ViewType.grid ? t('features.customerSegments.overview.tableView') : t('features.customerSegments.overview.gridView')}
              </TooltipContent>
            </Tooltip>
            <DropdownMenu>
              <Tooltip>
                <TooltipTrigger asChild>
                  <DropdownMenuTrigger asChild>
                    <Button variant='ghost' size='icon'>
                      <ArrowDownUp />
                    </Button>
                  </DropdownMenuTrigger>
                </TooltipTrigger>
                <TooltipContent>{t('features.customerSegments.overview.sort')}</TooltipContent>
              </Tooltip>
              <DropdownMenuContent>
                <DropdownMenuLabel>{t('features.customerSegments.overview.sort')}</DropdownMenuLabel>
                {sortOptions.map((option, index) => (
                  <DropdownMenuItem key={index} onSelect={() => handleSort(option.target, option.orderBy)}>
                    {option.name}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
            <DropdownMenu>
              <Tooltip>
                <TooltipTrigger asChild>
                  <DropdownMenuTrigger asChild>
                    <Button variant='ghost' size='icon'>
                      <FolderOpen />
                    </Button>
                  </DropdownMenuTrigger>
                </TooltipTrigger>
                <TooltipContent>{t('features.customerSegments.overview.groups')}</TooltipContent>
              </Tooltip>
              <DropdownMenuContent>
                <DropdownMenuLabel>{t('features.customerSegments.overview.groups')}</DropdownMenuLabel>
                {segmentGroups &&
                  segmentGroups.map((group) => (
                    <DropdownMenuItem
                      key={group.ref.id}
                      onSelect={() => {
                        const to = generatePath(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_GROUP, { id: group.ref.id })
                        navigate(to)
                      }}
                    >
                      {group.name}
                    </DropdownMenuItem>
                  ))}
                {segmentGroups && segmentGroups.length > 0 && <DropdownMenuSeparator />}
                <DropdownMenuItem onSelect={() => setCreateGroupDialogOpen(true)}>
                  {t('features.customerSegments.overview.createGroup')}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <div className='flex items-center'>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant='ghost'
                    size='icon'
                    onClick={() => {
                      setSearchOpen(!searchOpen)
                      dispatch('OpenSegmentSearchField')
                    }}
                    className='rounded-md'
                  >
                    <Search />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>{t('features.customerSegments.overview.search')}</TooltipContent>
              </Tooltip>
              {searchOpen && (
                <input
                  type='text'
                  autoFocus
                  className='border-b border-gray-300 focus:border-gray-500 focus:outline-none text-sm'
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              )}
            </div>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant='ghost'
                  size='icon'
                  disabled={!filteredCustomerSegments || filteredCustomerSegments.length === 0 || downloadLoading}
                  onClick={() => {
                    if (shopBillingStatus === ShopBillingStatus.free) {
                      setUpgradeRecommendDialogOpen(true)
                      return
                    }
                    handleDownload()
                  }}
                >
                  <Download />
                </Button>
              </TooltipTrigger>
              <TooltipContent>{t('features.customerSegments.overview.download')}</TooltipContent>
            </Tooltip>
            <Button size='sm' disabled={!rows} onClick={handleCreate} className='ml-3'>
              {t('features.customerSegments.overview.create')}
            </Button>
          </>
        }
      />

      <PageContentWrapper>
        {rows &&
          (rows.length === 0 ? (
            <SegmentEmptyState />
          ) : (
            filteredCustomerSegments?.length === 0 && (
              <EmptyState title={t('features.customerSegments.overview.searchResultZero', { input: searchInput })} />
            )
          ))}

        {viewType === ViewType.table &&
          (!filteredCustomerSegments ? (
            <SkeletonTable rowCount={3} columnCount={9} />
          ) : (
            filteredCustomerSegments.length > 0 && (
              <div className='rounded-md border'>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead />
                      <TableHead>{t('features.customerSegments.overview.name')}</TableHead>
                      <TableHead className='text-center'>{t('common.customerMetrics.title_count')}</TableHead>
                      <TableHead className='text-center'>{t('common.customerMetrics.title_repeaterRate')}</TableHead>
                      <TableHead className='text-center'>{t('common.customerMetrics.title_ltv')}</TableHead>
                      <TableHead className='text-center'>{t('common.customerMetrics.title_avgOrderValue')}</TableHead>
                      <TableHead className='text-center'>{t('common.customerMetrics.title_avgOrderCount')}</TableHead>
                      <TableHead className='text-center'>{t('common.customerMetrics.title_avgDaysBetweenOrders')}</TableHead>
                      <TableHead />
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {sortedFilteredCustomerSegments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                      <SegmentTableItem key={row.id} row={row} />
                    ))}
                  </TableBody>
                </Table>
              </div>
            )
          ))}

        {viewType === ViewType.grid && (
          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6'>
            {!filteredCustomerSegments
              ? Array.from(new Array(8)).map((_, i) => (
                  <Card key={i}>
                    <CardHeader>
                      <Skeleton className='w-8 h-8' />
                    </CardHeader>
                    <CardContent className='flex flex-col gap-2'>
                      <Skeleton className='w-1/2 h-3' />
                      <Skeleton className='w-full h-3' />
                      <Skeleton className='w-full h-3' />
                    </CardContent>
                  </Card>
                ))
              : sortedFilteredCustomerSegments
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => <SegmentGridItem key={row.id} row={row} />)}
          </div>
        )}

        {filteredCustomerSegments && filteredCustomerSegments.length > 0 && (
          <div className='flex justify-center'>
            <Pagination
              shape='rounded'
              count={filteredCustomerSegments ? Math.ceil(filteredCustomerSegments.length / rowsPerPage) : 0}
              page={page + 1}
              onChange={(_, v) => setPage(v - 1)}
              sx={{ marginY: '20px' }}
            />
          </div>
        )}
      </PageContentWrapper>

      {upgradeRecommendDialogOpen && (
        <UpgradeRecommendDialog
          open={upgradeRecommendDialogOpen}
          handleClose={() => setUpgradeRecommendDialogOpen(false)}
          referrer='customerSegmentOverview'
        />
      )}

      {createGroupDialogOpen && <CreateDialog open={createGroupDialogOpen} handleClose={() => setCreateGroupDialogOpen(false)} />}
    </>
  )
}
