import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Code, ConnectError } from '@connectrpc/connect'
import { zodResolver } from '@hookform/resolvers/zod'
import { auth } from 'config/firebaseClient'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { AuthService } from 'gen/proto/auth/auth_pb'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { Loader2 } from 'lucide-react'
import { useEffect, useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PUBLIC_ROUTE } from 'routing'
import { z } from 'zod'
import { AuthHeader } from './components/authHeader'

export const MemberSignup = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const authService = useGrpcClient(AuthService)

  const queryParams = useMemo(() => new URLSearchParams(window.location.search), [])
  const store = useMemo(() => queryParams.get('store'), [queryParams])
  const email = useMemo(() => decodeURIComponent(queryParams.get('email') || ''), [queryParams])
  const token = useMemo(() => queryParams.get('token'), [queryParams])

  useEffect(() => {
    if (!store || !email || !token) {
      alert(t('features.auth.memberSignup.messageUrlIsInvalid'))
      window.location.replace(PUBLIC_ROUTE.LOGIN)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, email, token])

  const signupInputSchema = z.object({
    password: z
      .string()
      .min(6, { message: t('features.auth.memberSignup.messageValidationPasswordMin') })
      .max(30, { message: t('features.auth.memberSignup.messageValidationPasswordMax') }),
  })
  type InputSchema = z.infer<typeof signupInputSchema>

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isValid, isSubmitting },
  } = useForm<InputSchema>({
    resolver: zodResolver(signupInputSchema),
    defaultValues: {
      password: '',
    },
    mode: 'onChange',
  })

  const enableSubmit = useMemo(() => {
    return store && email && token && isValid
  }, [store, email, token, isValid])

  const handleSignup: SubmitHandler<InputSchema> = async (input) => {
    try {
      await authService.memberSignup({
        email: email!,
        password: input.password,
        shopName: store!,
        token: token!,
        language: i18n.language,
      })
      await signInWithEmailAndPassword(auth, email!, input.password)
    } catch (err) {
      if (err instanceof ConnectError) {
        switch (err.code) {
          case Code.AlreadyExists:
            enqueueSnackbar(t('features.auth.memberSignup.messageErrorAlreadyExists'), { severity: 'error' })
            return
          case Code.InvalidArgument:
            enqueueSnackbar(t('features.auth.memberSignup.messageErrorInvalidArgument'), { severity: 'error' })
            return
          case Code.NotFound:
            enqueueSnackbar(t('features.auth.memberSignup.messageErrorNotFound'), { severity: 'error' })
            return
          default:
            enqueueSnackbar(t('features.auth.memberSignup.messageErrorUnknown'), { severity: 'error' })
            notifySentry(err, { inputEmail: email, inputPassword: input.password })
            return
        }
      }
      enqueueSnackbar(t('features.auth.memberSignup.messageErrorUnknown'), { severity: 'error' })
      notifySentry(err, { inputEmail: email, inputPassword: input.password })
    }
  }

  return (
    <div className='grid min-h-svh lg:grid-cols-2'>
      <div className='flex flex-col gap-4 p-6 md:p-10'>
        <AuthHeader />
        <div className='flex flex-1 items-center justify-center'>
          <div className='w-full max-w-xs'>
            <form className='flex flex-col gap-6'>
              <div className='flex flex-col gap-2'>
                <h1 className='text-2xl font-bold'>{t('features.auth.memberSignup.title')}</h1>
                <p className='text-balance text-sm text-muted-foreground'>{t('features.auth.memberSignup.description')}</p>
              </div>
              <div className='grid gap-6'>
                <div className='grid gap-2'>
                  <Label htmlFor='store'>{t('features.auth.memberSignup.store')}</Label>
                  <Input id='store' type='text' value={store || ''} disabled />
                </div>
                <div className='grid gap-2'>
                  <Label htmlFor='email'>{t('features.auth.memberSignup.email')}</Label>
                  <Input id='email' type='email' value={email || ''} disabled />
                </div>
                <div className='grid gap-2'>
                  <div className='flex items-center'>
                    <Label htmlFor='password'>{t('features.auth.memberSignup.password')}</Label>
                    <a
                      className='ml-auto text-sm underline-offset-4 hover:underline cursor-pointer'
                      onClick={() => navigate(PUBLIC_ROUTE.RESET_PASSWORD)}
                    >
                      {t('features.auth.login.forget')}
                    </a>
                  </div>
                  <Input id='password' type='password' required {...register('password')} />
                  {formErrors.password && <p className='text-red-500 text-xs'>{formErrors.password.message}</p>}
                </div>
                <Button type='submit' className='w-full' onClick={handleSubmit(handleSignup)} disabled={!enableSubmit || isSubmitting}>
                  {isSubmitting ? <Loader2 className='animate-spin' /> : t('features.auth.memberSignup.submit')}
                </Button>
              </div>
              <div className='text-center text-sm'>
                <span>{t('features.auth.memberSignup.login')}</span>
                <a className='underline underline-offset-4 cursor-pointer' onClick={() => navigate(PUBLIC_ROUTE.LOGIN)}>
                  {t('features.auth.memberSignup.loginLink')}
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className='relative hidden bg-muted lg:block'>
        <div className='absolute inset-0 h-full w-full object-cover dark:brightness-[0.2] dark:grayscale'>
          <div className='flex justify-center items-center h-full'>
            <img src='images/auth/member_signup.svg' alt='Image' className='w-2/3' />
          </div>
        </div>
      </div>
    </div>
  )
}
