import * as React from 'react'
import { FC } from 'react'

type Props = {
  children: React.ReactNode
}

export const PageContentWrapper: FC<Props> = ({ children }) => {
  return <div className='container mx-auto p-6'>{children}</div>
}
