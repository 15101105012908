import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { SidebarMenu, SidebarMenuButton, SidebarMenuItem, useSidebar } from '@/components/ui/sidebar'
import { auth } from '@/config/firebaseClient'
import { billingStatus } from '@/config/plan'
import { useAccount } from '@/hooks/useAccount'
import { useCustomSnackbar } from '@/hooks/useCustomSnackbar'
import { useSentryNotifier } from '@/hooks/useSentryNotifier'
import { AUTHORIZED_ROUTE } from '@/routing'
import dayjs from 'dayjs'
import { signOut } from 'firebase/auth'
import { Building2, ChevronsUpDown, CircleUser, LogOut, Sparkles } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const USER_AVATAR_PATH = '/images/avatar.svg'

export const NavUser = () => {
  const { t } = useTranslation()
  const { account, shop } = useAccount()
  const shopBillingStatus = billingStatus(shop, dayjs())

  const { isMobile } = useSidebar()
  const navigate = useNavigate()

  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const handleLogout = async () => {
    try {
      await signOut(auth)
    } catch (err) {
      enqueueSnackbar(t('layouts.dashboardLayout.messageLogoutError'), { severity: 'error' })
      notifySentry(err)
    }
  }

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton size='lg' className='data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground'>
              <Avatar className='h-6 w-6 rounded-lg'>
                <AvatarImage src={USER_AVATAR_PATH} alt={shop.shopName} />
                <AvatarFallback className='rounded-lg'>CN</AvatarFallback>
              </Avatar>
              <div className='grid flex-1 text-left text-sm leading-tight'>
                <span className='truncate font-semibold'>{shop.shopName}</span>
                <span className='truncate text-xs'>{account.email}</span>
              </div>
              <ChevronsUpDown className='ml-auto size-4' />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className='w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg'
            side={isMobile ? 'bottom' : 'right'}
            align='end'
            sideOffset={4}
          >
            <DropdownMenuLabel className='p-0 font-normal'>
              <div className='flex items-center gap-2 px-1 py-1.5 text-left text-sm'>
                <Avatar className='h-6 w-6 rounded-lg'>
                  <AvatarImage src={USER_AVATAR_PATH} alt={shop.shopName} />
                  <AvatarFallback className='rounded-lg'>CN</AvatarFallback>
                </Avatar>
                <div className='grid flex-1 text-left text-sm leading-tight'>
                  <span className='truncate font-semibold'>{shop.shopName}</span>
                  <span className='truncate text-xs'>{account.email}</span>
                </div>
              </div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            {shopBillingStatus !== 'paid' && (
              <>
                <DropdownMenuGroup>
                  <DropdownMenuItem onClick={() => navigate(AUTHORIZED_ROUTE.SETTINGS_PLAN)}>
                    <Sparkles />
                    {t('layouts.dashboardLayout.upgradeToPro')}
                  </DropdownMenuItem>
                </DropdownMenuGroup>
                <DropdownMenuSeparator />
              </>
            )}
            <DropdownMenuGroup>
              <DropdownMenuItem onClick={() => navigate(AUTHORIZED_ROUTE.SETTINGS_WORKSPACE)}>
                <Building2 />
                {t('layouts.dashboardLayout.workspace')}
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => navigate(AUTHORIZED_ROUTE.SETTINGS_ACCOUNT)}>
                <CircleUser />
                {t('layouts.dashboardLayout.account')}
              </DropdownMenuItem>
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={handleLogout}>
              <LogOut />
              {t('layouts.dashboardLayout.logout')}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  )
}
