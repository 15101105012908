import { Card, CardContent, CardHeader } from '@/components/ui/card'
import { Checkbox } from '@/components/ui/checkbox'
import { Input } from '@/components/ui/input'
import { LoaderCircle } from 'lucide-react'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Item = {
  name: string
  isVisible: boolean
}

type Props = {
  loading: boolean
  items: Item[]
  selectedNames: string[]
  setSelectedNames: (names: string[]) => void
  height: string // px
}

export const NameCheckBoxList: FC<Props> = ({ loading, items, selectedNames, setSelectedNames, height }) => {
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState('')

  const filteredItems = useMemo(() => {
    const lowerCaseSearchText = searchText.toLowerCase()
    return items.filter((item) => item.name.toLowerCase().includes(lowerCaseSearchText))
  }, [items, searchText])

  const handleCheckboxChange = (checked: boolean, name: string) => {
    if (checked) {
      if (selectedNames.length <= 10) {
        setSelectedNames([...selectedNames, name])
      }
    } else {
      if (selectedNames.length > 1) {
        setSelectedNames(selectedNames.filter((_name) => _name !== name))
      }
    }
  }

  return !loading ? (
    <Card style={{ height: height, overflowY: 'auto' }}>
      <CardHeader>
        <Input placeholder={t('features.insights.detail.search')} onChange={(e) => setSearchText(e.target.value)} />
      </CardHeader>
      <CardContent>
        {filteredItems.map((item, index) => (
          <div className='flex items-center space-x-2 py-2' key={index}>
            <Checkbox
              checked={selectedNames.includes(item.name)}
              disabled={!item.isVisible}
              onClick={() => item.isVisible && handleCheckboxChange(!selectedNames.includes(item.name), item.name)}
            />
            <label className='text-sm whitespace-nowrap overflow-hidden text-ellipsis'>{item.name}</label>
          </div>
        ))}
      </CardContent>
    </Card>
  ) : (
    <Card className='flex justify-center items-center' style={{ height: height, overflowY: 'auto' }}>
      <LoaderCircle className='animate-spin' />
    </Card>
  )
}
