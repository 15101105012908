import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Skeleton } from '@/components/ui/skeleton'
import { CustomerMetrics } from 'gen/firestore'
import { useCurrency } from 'hooks/useCurrency'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  metrics: CustomerMetrics | undefined
  type: 'grid' | 'list'
  onClick?: (key: keyof CustomerMetrics) => void
}

type MetricsItem = {
  key: keyof CustomerMetrics
  value: string | undefined
}

export const MetricsItems: FC<Props> = ({ metrics, type, onClick }) => {
  const { t } = useTranslation()
  const { formatCurrency } = useCurrency()

  const metricsItems: MetricsItem[] = [
    { key: 'count', value: metrics?.count.toLocaleString() },
    { key: 'repeaterRate', value: metrics && `${(metrics.repeaterRate * 100).toFixed(1)}%` },
    { key: 'ltv', value: metrics && formatCurrency(metrics.ltv) },
    { key: 'avgOrderValue', value: metrics && formatCurrency(metrics.avgOrderValue) },
    { key: 'avgOrderCount', value: metrics?.avgOrderCount.toFixed(2) },
    { key: 'avgDaysBetweenOrders', value: metrics?.avgDaysBetweenOrders.toFixed(1) },
  ]

  return (
    <>
      {type === 'grid' && (
        <div className='grid grid-cols-6 gap-3'>
          {metricsItems.map((item, index) => (
            <div className='col-span-1' key={`${item.key}-${index}`}>
              <Card className='cursor-pointer' onClick={() => onClick && onClick(item.key)}>
                <CardHeader>
                  <CardTitle className='text-sm'>{t('common.customerMetrics.title', { context: item.key })}</CardTitle>
                </CardHeader>
                <CardContent>{item.value ? <p className='text-xl'>{item.value}</p> : <Skeleton className='h-4 w-full' />}</CardContent>
              </Card>
            </div>
          ))}
        </div>
      )}

      {type === 'list' && (
        <div className='grid grid-cols-1 gap-4'>
          {metricsItems.map((item, index) => (
            <div className='col-span-1' key={`${item.key}-${index}`}>
              <div className='flex justify-between'>
                <div className='flex items-center'>
                  <p className='text-sm text-muted-foreground'>{t('common.customerMetrics.title', { context: item.key })}</p>
                </div>
                <p className='text-sm'>{(metrics && item.value) || <Skeleton className='w-12' />}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}
