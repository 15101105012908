import { Box } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { OP_NONE } from '../../querySetForm/schema/const'
import { FilterItemFormState } from '../../querySetForm/schema/querySetFormSchema'
import { OrderCountFilter } from './orderCountFilter'
import { OrderDateFilter } from './orderDateFilter'
import { OrderSequenceFilter } from './orderSequenceFilter'

type Props = {
  filterItems: FilterItemFormState[]
}

export const FilterItems: FC<Props> = ({ filterItems }) => {
  const { t } = useTranslation()

  return (
    <>
      {filterItems.filter((f) => f.operator !== OP_NONE).length > 0 && (
        <Box display='flex' marginTop='12px' marginLeft='40px'>
          <Box fontSize='14px' marginRight='12px' paddingTop='1px'>
            {t('features.customerSegments.querySet.querySetView.where')}
          </Box>
          <Box>
            {filterItems
              .filter((f) => f.operator !== OP_NONE)
              .map((filterItem, filterIndex) => (
                <Box key={filterIndex} marginBottom='6px'>
                  {filterItem.filter_type === 'order_date' && <OrderDateFilter filterItem={filterItem} />}
                  {filterItem.filter_type === 'order_sequence' && <OrderSequenceFilter filterItem={filterItem} />}
                  {filterItem.filter_type === 'order_count' && <OrderCountFilter filterItem={filterItem} />}
                </Box>
              ))}
          </Box>
        </Box>
      )}
    </>
  )
}
