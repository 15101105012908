import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { OP_CONTAIN_ALL, OP_CONTAIN_ANY, OP_NOT_CONTAIN } from '../../querySetForm/schema/const'
import { QueryItemFormState } from '../../querySetForm/schema/querySetFormSchema'
import { DimensionChip } from '../dimensionChip'
import { OperatorChip } from '../operatorChip'
import { ValueChip } from '../valueChip'
import { Box } from '@mui/material'

type Props = {
  queryItem: QueryItemFormState
  icon: JSX.Element
}

export const ContainTypedOrderQuery: FC<Props> = ({ queryItem, icon }) => {
  const { t } = useTranslation()

  return (
    <>
      <Box component='span' marginRight='6px' fontSize='14px'>
        {t('features.customerSegments.querySet.querySetView.prefixForOrder')}
      </Box>
      <DimensionChip label={t('features.customerSegments.querySet.dimension', { context: queryItem.dimension })} icon={icon} />
      <OperatorChip label={t('features.customerSegments.querySet.querySetView.op_order', { context: queryItem.operator })} />
      {(queryItem.operator === OP_CONTAIN_ALL || queryItem.operator === OP_CONTAIN_ANY || queryItem.operator === OP_NOT_CONTAIN) &&
        queryItem.value.map((v) => {
          return <ValueChip key={v} label={v} />
        })}
    </>
  )
}
