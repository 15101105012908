import { DialogWrapper } from '@/components/dialogWrapper'
import { getIdToken } from 'firebase/auth'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_pb'
import { useAccount } from 'hooks/useAccount'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  handleClose: () => void
  customerSegmentId: string
  tagName: string
  estimatedProcessingDurationInMinutes: number
}

export const DeactivateAutoSyncDialog: FC<Props> = ({ open, handleClose, customerSegmentId, tagName, estimatedProcessingDurationInMinutes }) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { shop } = useAccount()
  const customerSegmentService = useGrpcClient(CustomerSegmentService)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const updateTagOperationSetting = async () => {
    setIsSubmitting(true)
    try {
      const token = await getIdToken(authUser!)
      await customerSegmentService.updateTagOperationSetting(
        {
          customerSegmentId: customerSegmentId,
          isActive: false,
          tagName: '',
          enableShopifySegmentCreation: false,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      enqueueSnackbar(t('features.customerSegments.detail.deactivateAutoSyncDialog.messageSaved'), { severity: 'success' })
      handleClose()
    } catch (err) {
      enqueueSnackbar(t('features.customerSegments.detail.deactivateAutoSyncDialog.messageFailed'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <DialogWrapper
      open={open}
      handleClose={handleClose}
      title={t('features.customerSegments.detail.deactivateAutoSyncDialog.title')}
      mainContent={
        <p className='text-sm'>
          {t('features.customerSegments.detail.deactivateAutoSyncDialog.description', {
            tagName: tagName,
            estimatedProcessingDurationInMinutes: estimatedProcessingDurationInMinutes,
          })}
        </p>
      }
      onSubmit={updateTagOperationSetting}
      loading={isSubmitting}
      cancelButtonText={t('features.customerSegments.detail.deactivateAutoSyncDialog.cancel')}
      submitButtonText={t('features.customerSegments.detail.deactivateAutoSyncDialog.submit')}
      submitButtonDisabled={shop.isDemo}
    />
  )
}
