import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { useTranslation } from 'react-i18next'

export const AuthHeader = () => {
  const { i18n } = useTranslation()

  return (
    <div className='flex items-center justify-between'>
      <img src='/logo.png' alt='ecpower' className='w-[110px]' />
      <Select value={i18n.language} onValueChange={(value) => i18n.changeLanguage(value)}>
        <SelectTrigger className='w-[120px]'>
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value='en'>English</SelectItem>
            <SelectItem value='ja'>日本語</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  )
}
