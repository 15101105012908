import { InsightDimension, InsightReportType } from 'gen/firestore'

export type InsightDimensionProfile = {
  name: InsightDimension
  category: InsightCategory
  reportTypes: InsightReportType[]
}

export enum InsightCategory {
  all = 'all',
  product = 'product',
  source = 'source',
  order = 'order',
  datetime = 'datetime',
  customer = 'customer',
}

export const insightDimensions: InsightDimensionProfile[] = [
  {
    name: InsightDimension.product,
    category: InsightCategory.product,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
  },
  {
    name: InsightDimension.productType,
    category: InsightCategory.product,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
  },
  {
    name: InsightDimension.productVendor,
    category: InsightCategory.product,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
  },
  {
    name: InsightDimension.productTag,
    category: InsightCategory.product,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
  },
  {
    name: InsightDimension.productVariant,
    category: InsightCategory.product,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
  },
  {
    name: InsightDimension.referrer,
    category: InsightCategory.source,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
  },
  {
    name: InsightDimension.landingPage,
    category: InsightCategory.source,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
  },
  {
    name: InsightDimension.utmSource,
    category: InsightCategory.source,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
  },
  {
    name: InsightDimension.utmMedium,
    category: InsightCategory.source,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
  },
  {
    name: InsightDimension.utmCampaign,
    category: InsightCategory.source,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
  },
  {
    name: InsightDimension.channel,
    category: InsightCategory.order,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
  },
  {
    name: InsightDimension.orderTag,
    category: InsightCategory.order,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
  },
  {
    name: InsightDimension.coupon,
    category: InsightCategory.order,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
  },
  {
    name: InsightDimension.yearMonth,
    category: InsightCategory.datetime,
    reportTypes: [InsightReportType.popularity, InsightReportType.nthOrder, InsightReportType.orderCount],
  },
  {
    name: InsightDimension.weekday,
    category: InsightCategory.datetime,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
  },
  {
    name: InsightDimension.hour,
    category: InsightCategory.datetime,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
  },
  {
    name: InsightDimension.intervalDays,
    category: InsightCategory.customer,
    reportTypes: [InsightReportType.histogram],
  },
  {
    name: InsightDimension.lifetimeDays,
    category: InsightCategory.customer,
    reportTypes: [InsightReportType.histogram],
  },
  {
    name: InsightDimension.aov,
    category: InsightCategory.customer,
    reportTypes: [InsightReportType.histogram],
  },
  {
    name: InsightDimension.frequency,
    category: InsightCategory.customer,
    reportTypes: [InsightReportType.histogram],
  },
  {
    name: InsightDimension.recency,
    category: InsightCategory.customer,
    reportTypes: [InsightReportType.histogram],
  },
  {
    name: InsightDimension.monetary,
    category: InsightCategory.customer,
    reportTypes: [InsightReportType.histogram],
  },
  {
    name: InsightDimension.country,
    category: InsightCategory.customer,
    reportTypes: [InsightReportType.popularity],
  },
  {
    name: InsightDimension.province,
    category: InsightCategory.customer,
    reportTypes: [InsightReportType.popularity],
  },
  {
    name: InsightDimension.customerTag,
    category: InsightCategory.customer,
    reportTypes: [InsightReportType.popularity],
  },
]
