import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { Timestamp } from 'firebase/firestore'

dayjs.extend(utc)
dayjs.extend(timezone)

// Convert dayjs to ISO 8601
export const toISOStringWithTimezone = (d: dayjs.Dayjs): string => {
  return `${d.format('YYYY')}-${d.format('MM')}-${d.format('DD')}T${d.format('HH')}:${d.format('mm')}:${d.format('ss')}${d.format('Z')}`
}

// Convert ISO 8601 to dayjs
export const convertISOStringWithTimezone = (isoString: string): dayjs.Dayjs => {
  return dayjs.utc(isoString).tz()
}

// Convert dayjs to a formatted date string
export const formatDate = (d: dayjs.Dayjs, lang?: string): string => {
  return lang === 'en' ? d.format('MMM D, YYYY') : d.format('YYYY-MM-DD')
}

// Convert dayjs to a formatted date and time string
export const formatDateTime = (d: dayjs.Dayjs, lang?: string): string => {
  return lang === 'en' ? d.format('MMM D, YYYY HH:mm:ss') : d.format('YYYY-MM-DD HH:mm:ss')
}

// Convert dayjs to a formatted YearMonth string
export const formatYearMonth = (d: dayjs.Dayjs, lang?: string): string => {
  return lang === 'en' ? d.format('MMM YYYY') : d.format('YYYY-MM')
}

// Convert Timestamp to dayjs
export const timestampToDayjs = (t: Timestamp): dayjs.Dayjs => {
  return dayjs(t.toDate())
}
