import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import VolunteerActivismRoundedIcon from '@mui/icons-material/VolunteerActivismRounded'
import { Box, Card, CardActionArea, CardContent, IconButton, Skeleton, Tooltip, Typography, useTheme } from '@mui/material'
import { CustomerSegmentState } from 'features/customerSegments/types/types'
import { SegmentGroup } from 'gen/firestore'
import { useCurrency } from 'hooks/useCurrency'
import { useActionTracker } from 'hooks/useMixpanel'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, generatePath, useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { extractIconFromTextWithIcon, extractTextFromTextWithIcon } from 'utils/iconUtil'
import { UpdateDialog } from './components/updateDialog'
import { OverviewSegmentMenu } from './overviewSegmentMenu'

type Props = {
  row: CustomerSegmentState
  group?: SegmentGroup
}

export const SegmentGridItem: FC<Props> = ({ row, group }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { formatCurrency } = useCurrency()
  const theme = useTheme()
  const { dispatch } = useActionTracker()

  const [menuOpen, setMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false)

  return (
    <>
      <Card
        variant='outlined'
        sx={{
          borderRadius: '12px',
          height: '100%',
          position: 'relative',
          '&:hover': {
            boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)',
          },
          '&:hover .showOnHover': {
            visibility: 'visible',
          },
        }}
      >
        <CardActionArea
          onClick={() => {
            const to = generatePath(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_DETAIL, {
              id: row.id,
            })
            navigate(to)
          }}
          sx={{ height: '100%' }}
        >
          <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <Box marginBottom='8px'>
              <Typography fontSize='24px' marginBottom='8px'>
                {extractIconFromTextWithIcon(row.name)}
              </Typography>
              <Typography fontSize='14px' sx={{ marginBottom: '8px', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
                {extractTextFromTextWithIcon(row.name)}
              </Typography>
              <Box display='flex' alignItems='center' gap='12px'>
                <Tooltip title={t('common.customerMetrics.title', { context: 'count' })} placement='bottom'>
                  <Box display='flex' alignItems='center' gap='4px'>
                    <PersonRoundedIcon sx={{ fontSize: '16px', color: 'text.secondary' }} />
                    <Typography fontSize='13px'>{row.metrics ? row.metrics.count.toLocaleString() : <Skeleton width='40px' />}</Typography>
                  </Box>
                </Tooltip>
                <Tooltip title={t('common.customerMetrics.title', { context: 'ltv' })} placement='bottom'>
                  <Box display='flex' alignItems='center' gap='4px'>
                    <VolunteerActivismRoundedIcon sx={{ fontSize: '14px', color: 'text.secondary' }} />
                    <Typography fontSize='13px'>{row.metrics ? formatCurrency(row.metrics.ltv) : <Skeleton width='40px' />}</Typography>
                  </Box>
                </Tooltip>
              </Box>
            </Box>

            {row.tagOperationSetting && row.tagOperationSetting.isActive && (
              <Box display='flex' alignItems='center'>
                <LocalOfferOutlinedIcon sx={{ color: theme.palette.text.secondary, marginRight: '4px', fontSize: '14px' }} />
                <Typography fontSize='12px' color='text.secondary'>
                  {row.tagOperationSetting.tagName.length > 32
                    ? `${row.tagOperationSetting.tagName.slice(0, 32)}...`
                    : row.tagOperationSetting.tagName}
                </Typography>
              </Box>
            )}
          </CardContent>
        </CardActionArea>

        <Box
          className='showOnHover'
          display='flex'
          sx={{
            position: 'absolute',
            top: '20px',
            right: '16px',
            visibility: menuOpen ? 'visible' : 'hidden',
            '.MuiCardActionArea-root:hover &': {
              visibility: 'visible',
            },
          }}
        >
          {row.tagOperationSetting && !row.tagOperationSetting.isActive && (
            <Tooltip title={t('features.customerSegments.components.segmentItem.autoSync')} placement='top'>
              <IconButton
                size='small'
                onClick={() => {
                  const to = generatePath(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_DETAIL, { id: row.id })
                  navigate(`${to}#sync`)
                }}
                sx={{ borderRadius: '8px' }}
              >
                <LocalOfferOutlinedIcon sx={{ fontSize: '16px' }} />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title={t('features.customerSegments.components.segmentItem.duplicate')} placement='top'>
            <IconButton
              size='small'
              onClick={() => {
                dispatch('ClickDuplicateSegment')
                navigate({
                  pathname: AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_CREATE,
                  search: createSearchParams({
                    querySet: encodeURIComponent(row.querySet),
                  }).toString(),
                })
              }}
              sx={{ borderRadius: '8px' }}
            >
              <ContentCopyOutlinedIcon sx={{ fontSize: '16px' }} />
            </IconButton>
          </Tooltip>

          <Tooltip title={t('features.customerSegments.components.segmentItem.edit')} placement='top'>
            <IconButton
              size='small'
              onClick={() => {
                setUpdateDialogOpen(true)
              }}
              sx={{ borderRadius: '8px' }}
            >
              <EditOutlinedIcon sx={{ fontSize: '16px' }} />
            </IconButton>
          </Tooltip>

          <Tooltip title={t('features.customerSegments.components.segmentItem.menu')} placement='top'>
            <IconButton
              size='small'
              onClick={(e) => {
                setMenuOpen(true)
                setAnchorEl(e.currentTarget)
              }}
              sx={{ borderRadius: '8px' }}
            >
              <MoreHorizIcon sx={{ fontSize: '16px' }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Card>

      <OverviewSegmentMenu
        open={menuOpen}
        handleClose={() => {
          setMenuOpen(false)
          setAnchorEl(null)
        }}
        anchorEl={anchorEl}
        customerSegment={row}
        group={group}
      />

      {updateDialogOpen && (
        <UpdateDialog
          open={updateDialogOpen}
          handleClose={() => {
            setUpdateDialogOpen(false)
          }}
          id={row.id}
          name={row.name}
          description={row.description}
          favorited={row.favorited}
        />
      )}
    </>
  )
}
