import { PageContentWrapper } from '@/components/pageContentWrapper'
import { PageHeader } from '@/components/pageHeader'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { useAccount } from 'hooks/useAccount'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { Members } from './members/members'
import { MyAccount } from './myAccount/myAccount'
import { Plan } from './plan/plan'
import { Workspace } from './workspace/workspace'

enum TabIndex {
  workspace = 'workspace',
  account = 'account',
  members = 'members',
  plan = 'plan',
}

const tabRoutes = {
  [TabIndex.workspace]: AUTHORIZED_ROUTE.SETTINGS_WORKSPACE,
  [TabIndex.account]: AUTHORIZED_ROUTE.SETTINGS_ACCOUNT,
  [TabIndex.members]: AUTHORIZED_ROUTE.SETTINGS_MEMBERS,
  [TabIndex.plan]: AUTHORIZED_ROUTE.SETTINGS_PLAN,
}

const getTabIndex = (pathname: string): TabIndex => {
  return Object.entries(tabRoutes).find(([, route]) => route === pathname)?.[0] as TabIndex
}

export const Settings = () => {
  const { t } = useTranslation()
  const { account, shop } = useAccount()
  const location = useLocation()
  const navigate = useNavigate()

  const [tabIndex, setTabIndex] = useState<TabIndex>(getTabIndex(location.pathname))

  useEffect(() => {
    const newTabIndex = getTabIndex(location.pathname)
    if (newTabIndex !== tabIndex) setTabIndex(newTabIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const handleTabChange = (newTabIndex: string) => {
    const route = tabRoutes[newTabIndex as TabIndex]
    if (route) {
      navigate(route)
      setTabIndex(newTabIndex as TabIndex)
    }
  }

  return (
    <>
      <PageHeader title={t('features.settings.title')} />

      <PageContentWrapper>
        <Tabs value={tabIndex} onValueChange={handleTabChange} className='w-full'>
          <TabsList className='mb-4'>
            <TabsTrigger value={TabIndex.workspace}>{t('features.settings.tabIndexWorkspace')}</TabsTrigger>
            <TabsTrigger value={TabIndex.account}>{t('features.settings.tabIndexAccount')}</TabsTrigger>
            <TabsTrigger value={TabIndex.members}>{t('features.settings.tabIndexMembers')}</TabsTrigger>
            <TabsTrigger value={TabIndex.plan}>{t('features.settings.tabIndexPlan')}</TabsTrigger>
          </TabsList>
          <TabsContent value={TabIndex.workspace}>
            <Workspace shop={shop} />
          </TabsContent>
          <TabsContent value={TabIndex.account}>
            <MyAccount account={account} />
          </TabsContent>
          <TabsContent value={TabIndex.members}>
            <Members account={account} />
          </TabsContent>
          <TabsContent value={TabIndex.plan}>
            <Plan shop={shop} />
          </TabsContent>
        </Tabs>
      </PageContentWrapper>
    </>
  )
}
