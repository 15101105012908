import { Button } from '@/components/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Progress } from '@/components/ui/progress'
import { SidebarGroup } from '@/components/ui/sidebar'
import dayjs from 'dayjs'
import { ShopBillingStatus } from 'gen/firestore'
import { useAccount } from 'hooks/useAccount'
import { useActionTracker } from 'hooks/useMixpanel'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { formatDate, timestampToDayjs } from 'utils/timeUtil'

type Props = {
  shopBillingStatus: ShopBillingStatus
}

export const UpgradeCard: FC<Props> = ({ shopBillingStatus }) => {
  const { t, i18n } = useTranslation()
  const { shop } = useAccount()
  const navigate = useNavigate()
  const { dispatch } = useActionTracker()

  const totalTrialDays = timestampToDayjs(shop.trialEndsAt).diff(timestampToDayjs(shop.createdAt), 'day')
  const elapsedTrialDays = dayjs().diff(timestampToDayjs(shop.createdAt), 'day')
  const remainingTrialDays = totalTrialDays - elapsedTrialDays

  return (
    <SidebarGroup className='group-data-[collapsible=icon]:hidden'>
      <Card className='shadow-nonerounded-lg'>
        <CardHeader className='p-4 pb-0'>
          <CardTitle className='text-sm'>{t('layouts.dashboardLayout.upgradeTitle')}</CardTitle>
          {shopBillingStatus === 'trial' && (
            <CardDescription>
              {t('layouts.dashboardLayout.upgradeDescTrial', { date: formatDate(timestampToDayjs(shop.trialEndsAt!), i18n.language) })}
            </CardDescription>
          )}
          {shopBillingStatus === 'free' && <CardDescription>{t('layouts.dashboardLayout.upgradeDescFree')}</CardDescription>}
        </CardHeader>
        <CardContent className='grid gap-2.5 p-4'>
          {shopBillingStatus === 'trial' && (
            <div>
              <Progress value={(elapsedTrialDays / totalTrialDays) * 100} />
              <span className='text-xs text-muted-foreground'>
                {t('layouts.dashboardLayout.upgradeProgress', { remaining: remainingTrialDays, total: totalTrialDays })}
              </span>
            </div>
          )}
          <Button
            className='w-full bg-sidebar-primary text-sidebar-primary-foreground shadow-none'
            size='sm'
            onClick={() => {
              dispatch('ClickCheckPlan', { referrer: 'sidebar' })
              navigate(AUTHORIZED_ROUTE.SETTINGS_PLAN)
            }}
          >
            {t('layouts.dashboardLayout.upgradeCTA')}
          </Button>
        </CardContent>
      </Card>
    </SidebarGroup>
  )
}
