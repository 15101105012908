import { DialogWrapper } from '@/components/dialogWrapper'
import { Input } from '@/components/ui/input'
import { Code, ConnectError } from '@connectrpc/connect'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormControl } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { ShopService } from 'gen/proto/shop/shop_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

type Props = {
  open: boolean
  handleClose: () => void
  onSubmit: () => void
}

export const InviteMemberDialog: FC<Props> = ({ open, handleClose, onSubmit }) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const shopService = useGrpcClient(ShopService)
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const InputSchema = z.object({
    email: z
      .string()
      .min(1, { message: t('features.settings.members.inviteMemberDialog.messageValidationEmailMin') })
      .email(t('features.settings.members.inviteMemberDialog.messageValidationEmailIsInvalid')),
  })

  type InputSchemaType = z.infer<typeof InputSchema>

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<InputSchemaType>({
    resolver: zodResolver(InputSchema),
    defaultValues: {
      email: '',
    },
  })

  const inviteMember = async (input: InputSchemaType) => {
    try {
      const token = await getIdToken(authUser!)
      await shopService.inviteAccount({ email: input.email }, { headers: { Authorization: `Bearer ${token}` } })
      enqueueSnackbar(t('features.settings.members.inviteMemberDialog.messageSaved'), { severity: 'success' })
      onSubmit()
      handleClose()
    } catch (err) {
      if (err instanceof ConnectError && err.code === Code.AlreadyExists) {
        setError('email', { message: t('features.settings.members.inviteMemberDialog.messageAlreadyExists') })
        return
      }
      enqueueSnackbar(t('features.settings.members.inviteMemberDialog.messageError'), { severity: 'error' })
      notifySentry(err)
    }
  }

  return (
    <DialogWrapper
      open={open}
      handleClose={handleClose}
      title={t('features.settings.members.inviteMemberDialog.title')}
      description={t('features.settings.members.inviteMemberDialog.description')}
      mainContent={
        <Controller
          control={control}
          name='email'
          render={({ field, fieldState: { error } }) => {
            return (
              <FormControl className='w-full'>
                <Input {...field} placeholder={t('features.settings.members.inviteMemberDialog.fieldEmail')} />
                {error && <p className='text-red-500 text-xs mt-1 ml-2'>{error.message}</p>}
              </FormControl>
            )
          }}
        />
      }
      onSubmit={handleSubmit(inviteMember)}
      loading={isSubmitting}
      cancelButtonText={t('features.settings.members.inviteMemberDialog.cancel')}
      submitButtonText={t('features.settings.members.inviteMemberDialog.submit')}
    />
  )
}
