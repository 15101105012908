import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import {
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  SwipeableDrawer,
  Tab,
  Typography,
  useTheme,
} from '@mui/material'
import dayjs from 'dayjs'
import { OrderDrawer } from 'features/orders/orderDrawer'
import { getIdToken } from 'firebase/auth'
import { CustomerService } from 'gen/proto/customer/customer_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCurrency } from 'hooks/useCurrency'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useActionTracker } from 'hooks/useMixpanel'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getDimensionIconImagePath } from 'utils/imageUtil'
import { formatDateTime } from 'utils/timeUtil'

type Props = {
  open: boolean
  handleOpen: () => void
  handleClose: () => void
  customer:
    | {
        id: string
        name: string
      }
    | undefined
}

type OrderDrawerState = {
  open: boolean
  order:
    | {
        id: string
        name: string
      }
    | undefined
}

enum TabIndex {
  profile = 'profile',
  orders = 'orders',
}

export type CustomerProfile = {
  email: string
  country: string
  province: string
  tags: string[]
  recency: number
  frequency: number
  monetary: number
}

export type CustomerOrder = {
  id: string
  name: string
  orderValue: number
  orderedAt: string
}

const drawerWidth = 360

export const CustomerDrawer: FC<Props> = ({ open, handleOpen, handleClose, customer }) => {
  const { t, i18n } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { formatCurrency } = useCurrency()
  const { dispatch } = useActionTracker()
  const theme = useTheme()

  const customerService = useGrpcClient(CustomerService)

  const [tabIndex, setTabIndex] = useState<TabIndex>(TabIndex.profile)
  const [orderDrawerState, setOrderDrawerState] = useState<OrderDrawerState>({ open: false, order: undefined })

  const [customerProfile, setCustomerProfile] = useState<CustomerProfile>()
  const [customerOrders, setCustomerOrders] = useState<CustomerOrder[]>()

  useEffect(() => {
    if (!customer) {
      setCustomerProfile(undefined)
      return
    }
    const fetch = async () => {
      try {
        const token = await getIdToken(authUser!)
        const resp = await customerService.get({ id: customer.id }, { headers: { Authorization: `Bearer ${token}` } })
        setCustomerProfile({
          email: resp.email,
          country: resp.country,
          province: resp.province,
          tags: resp.tags.filter((str) => str !== ''),
          recency: resp.recency > 0 ? Number(resp.recency) : 0,
          frequency: Number(resp.frequency),
          monetary: Number(resp.monetary),
        })
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      }
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  useEffect(() => {
    if (!customer) {
      setCustomerOrders(undefined)
      return
    }
    const fetch = async () => {
      try {
        const token = await getIdToken(authUser!)
        const resp = await customerService.getOrders({ id: customer.id }, { headers: { Authorization: `Bearer ${token}` } })
        const orders = resp.orders.map((o) => {
          return {
            id: o.id,
            name: o.name,
            orderValue: Number(o.orderValue),
            orderedAt: formatDateTime(dayjs(o.orderedAt), i18n.language),
          }
        })
        setCustomerOrders(orders)
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      }
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  return (
    <>
      <SwipeableDrawer open={open} onOpen={handleOpen} onClose={handleClose} anchor='right'>
        <Box width={drawerWidth} padding='12px' role='presentation'>
          <Box display='flex' justifyContent='space-between'>
            <IconButton size='small' onClick={handleClose} sx={{ borderRadius: '8px' }}>
              <ArrowBackIcon fontSize='small' />
            </IconButton>
          </Box>

          <Box padding='16px 12px 12px'>
            <Typography>{customer?.name}</Typography>
            <Typography fontSize='14px' color='text.secondary'>
              {customerProfile ? customerProfile.email : <Skeleton height='16px' width='160px' />}
            </Typography>
          </Box>

          <TabContext value={tabIndex}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={(_, v) => setTabIndex(v)}>
                <Tab label={t('features.customers.customerDrawer.tabIndexProfile')} value={TabIndex.profile} />
                <Tab label={t('features.customers.customerDrawer.tabIndexOrders')} value={TabIndex.orders} />
              </TabList>
            </Box>
            <TabPanel value={TabIndex.profile} sx={{ padding: '24px 16px' }}>
              <Grid container spacing={2.5}>
                <Grid item xs={12}>
                  <Typography variant='body2' color='text.secondary' marginBottom='4px'>
                    <img src={getDimensionIconImagePath('monetary', theme)} alt='monetary' width='13px' style={{ marginRight: '4px' }} />
                    {t('features.customers.customerDrawer.monetary')}
                  </Typography>
                  {customerProfile ? (
                    <Typography fontSize='14px'>{formatCurrency(customerProfile.monetary)}</Typography>
                  ) : (
                    <Skeleton height='16px' width='120px' />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2' color='text.secondary' marginBottom='4px'>
                    <img src={getDimensionIconImagePath('frequency', theme)} alt='frequency' width='13px' style={{ marginRight: '4px' }} />
                    {t('features.customers.customerDrawer.frequency')}
                  </Typography>
                  {customerProfile ? (
                    <Typography fontSize='14px'>
                      {t('features.customers.customerDrawer.frequencyTimes', { count: customerProfile.frequency })}
                    </Typography>
                  ) : (
                    <Skeleton height='16px' width='120px' />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2' color='text.secondary' marginBottom='4px'>
                    <img src={getDimensionIconImagePath('recency', theme)} alt='recency' width='13px' style={{ marginRight: '4px' }} />
                    {t('features.customers.customerDrawer.recency')}
                  </Typography>
                  {customerProfile ? (
                    <Typography fontSize='14px'>{t('features.customers.customerDrawer.recencyDays', { count: customerProfile.recency })}</Typography>
                  ) : (
                    <Skeleton height='16px' width='120px' />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2' color='text.secondary' marginBottom='4px'>
                    <img src={getDimensionIconImagePath('country', theme)} alt='country' width='13px' style={{ marginRight: '4px' }} />
                    {t('features.customers.customerDrawer.country')}
                  </Typography>
                  {customerProfile ? <Typography fontSize='14px'>{customerProfile.country}</Typography> : <Skeleton height='16px' width='120px' />}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2' color='text.secondary' marginBottom='4px'>
                    <img src={getDimensionIconImagePath('prefecture', theme)} alt='prefecture' width='13px' style={{ marginRight: '4px' }} />
                    {t('features.customers.customerDrawer.province')}
                  </Typography>
                  {customerProfile ? <Typography fontSize='14px'>{customerProfile.province}</Typography> : <Skeleton height='16px' width='120px' />}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2' color='text.secondary' marginBottom='4px'>
                    <img src={getDimensionIconImagePath('customer_tag', theme)} alt='customer_tag' width='13px' style={{ marginRight: '4px' }} />
                    {t('features.customers.customerDrawer.tags')}
                  </Typography>
                  {customerProfile ? (
                    customerProfile.tags.length === 0 ? (
                      <Typography fontSize='14px'>{t('features.customers.customerDrawer.none')}</Typography>
                    ) : (
                      customerProfile.tags.map((tag) => (
                        <Chip key={tag} label={tag} size='small' sx={{ borderRadius: '4px', marginRight: '6px', marginBottom: '6px' }} />
                      ))
                    )
                  ) : (
                    <Box display='flex'>
                      {[1, 2, 3].map((i) => (
                        <Skeleton key={i} width='48px' sx={{ marginRight: '6px' }} />
                      ))}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={TabIndex.orders} sx={{ padding: '0px', overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
              {customerOrders ? (
                <List dense>
                  {customerOrders.map((order, i) => (
                    <ListItem
                      key={order.id}
                      disablePadding
                      secondaryAction={
                        <IconButton size='small' edge='end' aria-label='delete' sx={{ borderRadius: '8px' }}>
                          <KeyboardArrowRightIcon fontSize='small' />
                        </IconButton>
                      }
                      sx={{ border: (theme) => `1px solid ${theme.palette.divider}`, borderRadius: '12px', marginBottom: '8px' }}
                    >
                      <ListItemButton
                        onClick={() => {
                          setOrderDrawerState({ open: true, order: { id: order.id, name: order.name } })
                          dispatch('OpenOrderDrawer', { orderId: order.id, orderName: order.name, referrer: 'customerDrawer' })
                        }}
                      >
                        <Box paddingY='8px'>
                          <Box display='flex' alignItems='center' marginBottom='4px'>
                            <Chip label={t('features.customers.customerDrawer.orderNth', { count: customerOrders.length - i })} size='small' />
                            <Typography fontSize='14px' color='text.secondary' marginLeft='8px'>
                              {order.name}
                            </Typography>
                          </Box>
                          <Box display='flex' alignItems='center'>
                            <Typography fontSize='14px'>{formatCurrency(order.orderValue)}</Typography>
                            {' ・ '}
                            <Typography fontSize='14px'>{formatDateTime(dayjs(order.orderedAt), i18n.language)}</Typography>
                          </Box>
                        </Box>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <List dense>
                  {[1, 2, 3, 4].map((i) => (
                    <ListItem
                      key={i}
                      disablePadding
                      sx={{ border: (theme) => `1px solid ${theme.palette.divider}`, borderRadius: '12px', marginBottom: '8px' }}
                    >
                      <ListItemButton>
                        <ListItemText primary={<Skeleton width='30%' />} secondary={<Skeleton width='80%' />} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </TabPanel>
          </TabContext>
        </Box>
      </SwipeableDrawer>

      {orderDrawerState.open && orderDrawerState.order && (
        <OrderDrawer
          open={orderDrawerState.open}
          handleOpen={() => setOrderDrawerState({ open: true, order: orderDrawerState.order })}
          handleClose={() => setOrderDrawerState({ open: false, order: undefined })}
          orderProps={orderDrawerState.order}
        />
      )}
    </>
  )
}
