import { SidebarInset, SidebarProvider } from '@/components/ui/sidebar'
import { FatalError } from 'features/error/fatalError'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { ErrorBoundary } from 'react-error-boundary'
import { Outlet } from 'react-router-dom'
import { AppSidebar } from './components/appSidebar'

export const DashboardLayout = () => {
  const { notifySentry } = useSentryNotifier()

  return (
    <SidebarProvider>
      <AppSidebar />
      <SidebarInset>
        <ErrorBoundary
          FallbackComponent={() => <FatalError />}
          onError={(err, info) => notifySentry(err, info.componentStack)}
          resetKeys={[location.pathname]}
        >
          <Outlet />
        </ErrorBoundary>
      </SidebarInset>
    </SidebarProvider>
  )
}
