import dayjs from 'dayjs'

export enum TimeRange {
  last6Months = 'last6Months',
  last12Months = 'last12Months',
  last18Months = 'last18Months',
  last24Months = 'last24Months',
}

export const calculateStartEndYearMonth = (timeRange: TimeRange): { startYearMonth: string; endYearMonth: string } => {
  const endYearMonth = dayjs().format('YYYY-MM')
  const monthsMap = {
    [TimeRange.last6Months]: 6,
    [TimeRange.last12Months]: 12,
    [TimeRange.last18Months]: 18,
    [TimeRange.last24Months]: 24,
  }
  const startYearMonth = dayjs()
    .subtract(monthsMap[timeRange] || 12, 'month')
    .format('YYYY-MM')
  return { startYearMonth, endYearMonth }
}
