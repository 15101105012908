import { getGuideUrl, GuideType } from 'config/guide'
import { useActionTracker } from 'hooks/useMixpanel'
import { Info } from 'lucide-react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from './ui/button'
import { Tooltip, TooltipContent, TooltipTrigger } from './ui/tooltip'

type Props = {
  guideType: GuideType
}

export const GuideIcon: FC<Props> = ({ guideType }) => {
  const { t, i18n } = useTranslation()
  const { dispatch } = useActionTracker()
  const currentLang = i18n.language

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant='ghost'
          size='icon'
          onClick={() => {
            const guideURL = getGuideUrl(currentLang, guideType)
            dispatch('ViewGuide', { guideType: guideType, url: guideURL })
            window.open(guideURL, '_blank')
          }}
        >
          <Info />
        </Button>
      </TooltipTrigger>
      <TooltipContent>{t('common.guideIcon.view')}</TooltipContent>
    </Tooltip>
  )
}
