import { Card, CardContent, CardHeader } from '@/components/ui/card'
import { ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart'
import { Skeleton } from '@/components/ui/skeleton'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { useCurrency } from '@/hooks/useCurrency'
import { EmptyState } from 'components/emptyState'
import dayjs from 'dayjs'
import { Info, LoaderCircle } from 'lucide-react'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Area, AreaChart, CartesianGrid, XAxis } from 'recharts'
import { formatDate } from 'utils/timeUtil'
import { MetricsHistory } from '../../types/types'
import { MetricsIndex } from '../metrics'

type Props = {
  metricsIndex: MetricsIndex
  metricsHistory: MetricsHistory[] | undefined
  loading: boolean
}

export const MetricsChartCard: FC<Props> = ({ metricsIndex, metricsHistory, loading }) => {
  const { t, i18n } = useTranslation()
  const { formatCurrency } = useCurrency()

  const chartData = useMemo(() => {
    if (!metricsHistory) return undefined
    return metricsHistory.map((item) => ({
      date: formatDate(dayjs(item.date), i18n.language),
      metric: item.metrics[metricsIndex],
    }))
  }, [metricsHistory, metricsIndex, i18n.language])

  const formatMetricVal = (metricsIndex: MetricsIndex, value: number): string => {
    if (metricsIndex === MetricsIndex.count) return value.toLocaleString()
    if (metricsIndex === MetricsIndex.repeaterRate) return `${(value * 100).toFixed(1)}%`
    if (metricsIndex === MetricsIndex.ltv) return formatCurrency(value) || value.toLocaleString()
    if (metricsIndex === MetricsIndex.avgOrderValue) return formatCurrency(value) || value.toLocaleString()
    if (metricsIndex === MetricsIndex.avgOrderCount) return value.toFixed(2)
    if (metricsIndex === MetricsIndex.avgDaysBetweenOrders) return value.toFixed(1)
    return value.toLocaleString()
  }

  const latestValue = useMemo(() => {
    return chartData && chartData.length > 0 ? formatMetricVal(metricsIndex, chartData[chartData.length - 1].metric) : '-'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData])

  const growthRateString = useMemo(() => {
    if (!chartData || chartData.length === 0) return '0%'
    const rate = (chartData[chartData.length - 1].metric / chartData[0].metric - 1) * 100
    return rate >= 0.1 ? `+${rate.toFixed(1)}%` : rate <= -0.1 ? `${rate.toFixed(1)}%` : '0%'
  }, [chartData])

  const oldestDate = useMemo(() => {
    return chartData && chartData.length > 0 ? chartData[0].date : ''
  }, [chartData])

  const chartConfig = {
    metric: {
      label: t(`common.customerMetrics.title_${metricsIndex}`),
      color: 'hsl(var(--chart-1))',
    },
  }

  return (
    <Card>
      <CardHeader>
        <div className='flex flex-col gap-1'>
          <div className='flex items-center gap-2'>
            <div className='text-sm font-bold'>{t(`common.customerMetrics.title_${metricsIndex}`)}</div>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Info className='w-3 h-3' />
                </TooltipTrigger>
                <TooltipContent>
                  <p>{t(`common.customerMetrics.desc`, { context: metricsIndex })}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          <div className='text-xl font-bold'>{loading ? <Skeleton className='h-3 w-24' /> : latestValue}</div>
          <div className='text-sm text-muted-foreground'>{loading ? <Skeleton className='h-3 w-36' /> : `${growthRateString} vs ${oldestDate}`}</div>
        </div>
      </CardHeader>
      <CardContent>
        {loading || !chartData ? (
          <div className='flex justify-center items-center h-[160px]'>
            <LoaderCircle className='animate-spin' />
          </div>
        ) : chartData.length === 0 ? (
          <EmptyState title={t('features.customerSegments.detail.metrics.empty')} />
        ) : (
          <ChartContainer config={chartConfig}>
            <AreaChart accessibilityLayer data={chartData}>
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey='date'
                tickLine={false}
                axisLine={false}
                interval='preserveStartEnd'
                ticks={[chartData[0].date, chartData[chartData.length - 1].date]}
              />
              <ChartTooltip cursor={false} content={<ChartTooltipContent indicator='line' />} />
              <Area dataKey='metric' type='natural' fill='url(#colorMetric)' stroke='var(--color-metric)' />
              <defs>
                <linearGradient id='colorMetric' x1='0' y1='0' x2='0' y2='1'>
                  <stop offset='0%' stopColor='var(--color-metric)' stopOpacity={0.8} />
                  <stop offset='100%' stopColor='var(--color-metric)' stopOpacity={0.2} />
                </linearGradient>
              </defs>
            </AreaChart>
          </ChartContainer>
        )}
      </CardContent>
    </Card>
  )
}
