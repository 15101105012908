type Env = {
  DEBUG_LOG: boolean
  ECPOWER_API_URL: string
  EMBEDDED_SHOPIFY_APP_NAME: string
  FIREBASE_API_KEY: string
  FIREBASE_AUTH_DOMAIN: string
  FIREBASE_PROJECT_ID: string
  FIREBASE_STORAGE_BUCKET: string
  FIREBASE_MESSAGING_SENDER_ID: string
  FIREBASE_APP_ID: string
  SENTRY_DNS: string | undefined // Optional: Sentry is not used in the local environment
  SENTRY_ENV: string | undefined // Optional: Sentry is not used in the local environment
  SHOPIFY_APP_STORE_URL: string
  VERSION: string | undefined // Used when building in CI
  MIXPANEL_TOKEN: string
  CLARITY_PROJECT_ID: string
}

// TODO: Make this a Singleton function
export const env: Env = (() => {
  // Check whether env variables are set properly
  if (
    !import.meta.env.VITE_DEBUG_LOG ||
    !import.meta.env.VITE_ECPOWER_API_URL ||
    !import.meta.env.VITE_EMBEDDED_SHOPIFY_APP_NAME ||
    !import.meta.env.VITE_FIREBASE_API_KEY ||
    !import.meta.env.VITE_FIREBASE_AUTH_DOMAIN ||
    !import.meta.env.VITE_FIREBASE_PROJECT_ID ||
    !import.meta.env.VITE_FIREBASE_STORAGE_BUCKET ||
    !import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID ||
    !import.meta.env.VITE_FIREBASE_APP_ID ||
    !import.meta.env.VITE_MIXPANEL_TOKEN ||
    !import.meta.env.VITE_SHOPIFY_APP_STORE_URL ||
    !import.meta.env.VITE_CLARITY_PROJECT_ID
  ) {
    throw new Error('failed to load env. please check .env setting')
  }

  return {
    DEBUG_LOG: import.meta.env.VITE_DEBUG_LOG === 'true',
    ECPOWER_API_URL: import.meta.env.VITE_ECPOWER_API_URL,
    EMBEDDED_SHOPIFY_APP_NAME: import.meta.env.VITE_EMBEDDED_SHOPIFY_APP_NAME,
    FIREBASE_API_KEY: import.meta.env.VITE_FIREBASE_API_KEY,
    FIREBASE_AUTH_DOMAIN: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    FIREBASE_PROJECT_ID: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    FIREBASE_STORAGE_BUCKET: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    FIREBASE_MESSAGING_SENDER_ID: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    FIREBASE_APP_ID: import.meta.env.VITE_FIREBASE_APP_ID,
    SENTRY_DNS: import.meta.env.VITE_SENTRY_DNS,
    SENTRY_ENV: import.meta.env.VITE_SENTRY_ENV,
    SHOPIFY_APP_STORE_URL: import.meta.env.VITE_SHOPIFY_APP_STORE_URL,
    VERSION: import.meta.env.VITE_VERSION,
    MIXPANEL_TOKEN: import.meta.env.VITE_MIXPANEL_TOKEN,
    CLARITY_PROJECT_ID: import.meta.env.VITE_CLARITY_PROJECT_ID,
  }
})()