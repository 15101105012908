import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { Pagination, Skeleton } from '@mui/material'
import { EmptyState } from 'components/emptyState'
import { SkeletonTable } from 'components/skeletonTable'
import { UpgradeRecommendDialog } from 'components/upgradeRecommendDialog'
import { billingStatus } from 'config/plan'
import dayjs from 'dayjs'
import { CustomerSegmentState } from 'features/customerSegments/types/types'
import { CustomerDrawer } from 'features/customers/customerDrawer'
import { ShopBillingStatus } from 'gen/firestore'
import { useAccount } from 'hooks/useAccount'
import { useCurrency } from 'hooks/useCurrency'
import { Download, LoaderCircle, UserRoundSearch } from 'lucide-react'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDate } from 'utils/timeUtil'
import { useCustomersDownload } from '../hooks/useCustomersDownload'
import { CustomerState } from '../types/types'

type Props = {
  customerSegment: CustomerSegmentState | undefined
  customers: CustomerState[] | undefined
  loading: boolean
}

type CustomerDrawerState = {
  open: boolean
  customer:
    | {
        id: string
        name: string
        email: string
      }
    | undefined
}

export const Customers: FC<Props> = ({ customerSegment, customers: rows, loading }) => {
  const { t, i18n } = useTranslation()
  const { formatCurrency } = useCurrency()
  const { shop } = useAccount()
  const shopBillingStatus = billingStatus(shop, dayjs())

  const [page, setPage] = useState(0)
  const rowsPerPage = 10
  const [customerDrawerState, setCustomerDrawerState] = useState<CustomerDrawerState>({ open: false, customer: undefined })
  const [upgradeRecommendDialogOpen, setUpgradeRecommendDialogOpen] = useState(false)

  const { handleDownload, loading: downloadLoading } = useCustomersDownload({
    customerSegmentId: customerSegment?.id,
    fileName: `${customerSegment?.name}_${formatDate(dayjs(), i18n.language)}`,
    page: 'customerSegment_customers',
  })

  return (
    <>
      <Card>
        <CardHeader>
          <div className='flex items-center justify-between'>
            <CardTitle>{t('features.customerSegments.detail.customers.title')}</CardTitle>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant='ghost'
                  size='icon'
                  disabled={loading || !rows || rows.length === 0 || downloadLoading}
                  onClick={() => {
                    if (shopBillingStatus === ShopBillingStatus.free) {
                      setUpgradeRecommendDialogOpen(true)
                      return
                    }
                    handleDownload()
                  }}
                >
                  {downloadLoading ? <LoaderCircle className='animate-spin' /> : <Download />}
                </Button>
              </TooltipTrigger>
              <TooltipContent>{t('features.customerSegments.detail.metrics.download')}</TooltipContent>
            </Tooltip>
          </div>
        </CardHeader>
        <CardContent>
          {loading || !rows ? (
            <SkeletonTable rowCount={5} columnCount={6} />
          ) : rows.length === 0 ? (
            <EmptyState title={t('features.customerSegments.detail.customers.empty')} />
          ) : (
            <>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>{t('features.customerSegments.detail.customers.name')}</TableHead>
                    <TableHead>{t('features.customerSegments.detail.customers.email')}</TableHead>
                    <TableHead className='text-center'>{t('features.customerSegments.detail.customers.monetary')}</TableHead>
                    <TableHead className='text-center'>{t('features.customerSegments.detail.customers.frequency')}</TableHead>
                    <TableHead className='text-center'>{t('features.customerSegments.detail.customers.recency')}</TableHead>
                    <TableHead />
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {rows &&
                    rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell className='text-center'>{formatCurrency(row.monetary) || <Skeleton />}</TableCell>
                        <TableCell className='text-center'>{row.frequency}</TableCell>
                        <TableCell className='text-center'>{row.recency}</TableCell>
                        <TableCell className='text-right'>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button variant='ghost' size='icon' onClick={() => setCustomerDrawerState({ open: true, customer: row })}>
                                <UserRoundSearch />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>{t('features.customerSegments.detail.customers.viewCustomer')}</TooltipContent>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>

              <div className='flex justify-center'>
                <Pagination
                  shape='rounded'
                  count={rows ? Math.ceil(rows.length / rowsPerPage) : 0}
                  page={page + 1}
                  onChange={(_, v) => setPage(v - 1)}
                  sx={{ marginTop: '20px' }}
                />
              </div>
            </>
          )}
        </CardContent>
      </Card>

      <CustomerDrawer
        open={customerDrawerState.open}
        handleOpen={() => setCustomerDrawerState({ open: true, customer: customerDrawerState.customer })}
        handleClose={() => setCustomerDrawerState({ open: false, customer: undefined })}
        customer={customerDrawerState.customer}
      />

      {upgradeRecommendDialogOpen && (
        <UpgradeRecommendDialog
          open={upgradeRecommendDialogOpen}
          handleClose={() => setUpgradeRecommendDialogOpen(false)}
          referrer='customerSegmentCustomers'
        />
      )}
    </>
  )
}
