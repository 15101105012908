import { PageContentWrapper } from '@/components/pageContentWrapper'
import { PageHeader } from '@/components/pageHeader'
import { Button } from '@/components/ui/button'
import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table'
import { getGuideUrl } from 'config/guide'
import { convertFormToQuerySet } from 'features/customerSegments/querySetForm/schema/converter'
import { QuerySetFormState } from 'features/customerSegments/querySetForm/schema/querySetFormSchema'
import { useActionTracker } from 'hooks/useMixpanel'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { useSegmentTemplates } from './hooks/useSegmentTemplates'

export const Templates: FC = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { dispatch } = useActionTracker()

  const { data: templates } = useSegmentTemplates()

  return (
    <>
      <PageHeader
        title={t('features.templates.title')}
        menuComponent={
          <Button
            variant='outline'
            size='sm'
            onClick={() => {
              const guideURL = getGuideUrl(i18n.language, 'UseCases')
              dispatch('ViewGuide', { guideType: 'UseCases', url: guideURL })
              window.open(guideURL, '_blank')
            }}
          >
            {t('features.templates.viewUseCases')}
          </Button>
        }
      />

      <PageContentWrapper>
        <Table>
          <TableBody>
            {templates.map((template) => (
              <TableRow
                key={template.name}
                className='cursor-pointer border-none group [&>*]:py-3'
                onClick={() => {
                  dispatch('ClickTemplate', { name: template.name })
                  const querySetJsonString = JSON.stringify(convertFormToQuerySet(template.querySet as QuerySetFormState))
                  const to = `${AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_CREATE}?${createSearchParams({
                    querySet: encodeURIComponent(querySetJsonString),
                    name: encodeURIComponent(template.name),
                    description: encodeURIComponent(template.description),
                  }).toString()}`
                  navigate(to)
                }}
              >
                <TableCell>{template.name}</TableCell>
                <TableCell className='text-muted-foreground'>{template.description}</TableCell>
                <TableCell className='text-right text-xs text-muted-foreground group-hover:visible invisible'>
                  {t('features.templates.useTemplate')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PageContentWrapper>
    </>
  )
}
