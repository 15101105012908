// useCustomerSegment.ts
import { doc } from 'firebase/firestore'
import { useAccount } from 'hooks/useAccount'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCollectionSubscription, useDocumentSubscription } from 'hooks/useFirestoreData'
import { useMemo } from 'react'
import { timestampToDayjs } from 'utils/timeUtil'

import { getIdToken } from 'firebase/auth'
import { query } from 'firebase/firestore'
import { CustomerMetrics, SegmentGroup } from 'gen/firestore'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { CustomerSegmentState } from 'features/customerSegments/types/types'

export const useGroupSegments = (
  groupId: string | undefined
): { segmentGroup: SegmentGroup | undefined; customerSegments: CustomerSegmentState[] | undefined } => {
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { account } = useAccount()
  const { customerSegmentsRef, segmentGroupsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: segmentGroup } = useDocumentSubscription(doc(segmentGroupsRef, groupId))
  const { data: customerSegmentData } = useCollectionSubscription(query(customerSegmentsRef))

  const [metricsMap, setMetricsMap] = useState<Map<string, CustomerMetrics>>()
  const customerSegmentService = useGrpcClient(CustomerSegmentService)

  useEffect(() => {
    if (!customerSegments || customerSegments.length === 0) return // No segments to fetch
    if (customerSegments.every((s) => s.metrics)) return // Metrics are already fetched

    const handleFetch = async () => {
      try {
        const token = await getIdToken(authUser!)
        const resp = await customerSegmentService.getLatestCustomerMetricsList(
          { customerSegmentIds: customerSegments.map((s) => s.id) },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        const metricsMap = new Map(
          resp.result.map((metric) => [
            metric.customerSegmentId,
            {
              count: Number(metric.count),
              repeaterRate: metric.repeaterRate,
              ltv: metric.ltv,
              avgOrderValue: metric.avgOrderValue,
              avgOrderCount: metric.avgOrderCount,
              avgDaysBetweenOrders: metric.avgDaysBetweenOrders,
            },
          ])
        )
        setMetricsMap(metricsMap)
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      }
    }

    handleFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegmentData?.map((s) => s.ref.id)])

  const customerSegments = useMemo(() => {
    if (!customerSegmentData || !segmentGroup) return undefined
    if (!segmentGroup.customerSegmentRefs) return [] as CustomerSegmentState[]

    const targetCustomerSegments = customerSegmentData
      .filter((d) => segmentGroup.customerSegmentRefs.some((ref) => ref.id === d.ref.id))
      .map((d) => ({
        id: d.ref.id,
        name: d.name,
        description: d.description,
        querySet: d.querySet,
        favorited: d.favorited,
        metrics: metricsMap?.get(d.ref.id),
        tagOperationSetting: d.tagOperationSetting,
        createdAt: timestampToDayjs(d.createdAt),
      }))

    const newSegmentRefSequenceMap = new Map(segmentGroup.customerSegmentRefs.map((ref, index) => [ref.id, index]))

    // Sort in the order of customerSegmentRefs on database
    return targetCustomerSegments.sort((a, b) => {
      const indexA = newSegmentRefSequenceMap.get(a.id)
      const indexB = newSegmentRefSequenceMap.get(b.id)
      if (indexA === undefined || indexB === undefined) {
        throw new Error(
          `One of the ids does not exist in the map. targetCustomerSegments:${targetCustomerSegments} segmentGroup.customerSegmentRefs: ${segmentGroup.customerSegmentRefs} `
        )
      }
      return indexA - indexB
    })
  }, [customerSegmentData, segmentGroup, metricsMap])

  return {
    segmentGroup: segmentGroup,
    customerSegments,
  }
}
