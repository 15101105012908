import SearchIcon from '@mui/icons-material/Search'
import { Box, Chip, Paper, Typography } from '@mui/material'
import { QuickStartGuideDialog } from 'components/quickStartGuideDialog'
import { SearchDialog } from 'components/searchDialog'
import { getGuideUrl } from 'config/guide'
import dayjs from 'dayjs'
import { useAccount } from 'hooks/useAccount'
import { useActionTracker } from 'hooks/useMixpanel'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const Home: FC = () => {
  const { t, i18n } = useTranslation()
  const { dispatch } = useActionTracker()
  const { shop } = useAccount()

  const [openSearchDialog, setOpenSearchDialog] = useState(false)
  const [openQuickStartGuideDialog, setOpenQuickStartGuideDialog] = useState(false)

  const timezone = shop.timezone || 'UTC'
  const currentTime = dayjs().tz(timezone)
  const hour = currentTime.hour()

  return (
    <>
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' height='100vh' paddingBottom='96px'>
        <Typography fontSize='24px' marginBottom='36px'>
          {hour >= 5 && hour < 12
            ? t('features.home.title_morning')
            : hour >= 12 && hour < 18
              ? t('features.home.title_afternoon')
              : t('features.home.title_evening')}
        </Typography>

        <Paper
          variant='outlined'
          sx={{
            width: '50%',
            borderRadius: '32px',
            padding: '14px 32px',
            cursor: 'pointer',
            marginBottom: '36px',
            '&:hover': {
              boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)',
            },
          }}
          onClick={() => setOpenSearchDialog(true)}
        >
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Box display='flex' alignItems='center'>
              <SearchIcon />
              <Typography marginLeft='16px'>{t('features.home.search')}</Typography>
            </Box>
            <Typography color='text.secondary'>{t('features.home.shortcut')}</Typography>
          </Box>
        </Paper>

        <Box>
          {[
            {
              title: t('features.home.interactiveGuide'),
              onclick: () => {
                setOpenQuickStartGuideDialog(true)
              },
            },
            {
              title: t('features.home.documentation'),
              onclick: () => {
                const guideURL = getGuideUrl(i18n.language, 'Home')
                dispatch('ViewGuide', { guideType: 'Home', url: guideURL })
                window.open(guideURL, '_blank')
              },
            },
          ].map((item, index) => (
            <Chip
              key={index}
              label={item.title}
              size='small'
              onClick={item.onclick}
              sx={{
                padding: '16px 12px',
                borderRadius: '24px',
                cursor: 'pointer',
                marginLeft: index === 0 ? '0px' : '12px',
                '&:hover': {
                  boxShadow: '0 0 2px rgba(0, 0, 0, 0.2)',
                },
              }}
            />
          ))}
        </Box>
      </Box>

      {openSearchDialog && <SearchDialog open={openSearchDialog} handleClose={() => setOpenSearchDialog(false)} />}
      {openQuickStartGuideDialog && (
        <QuickStartGuideDialog open={openQuickStartGuideDialog} handleClose={() => setOpenQuickStartGuideDialog(false)} referrer='home' />
      )}
    </>
  )
}
