import dayjs from 'dayjs'
import { useState, useMemo } from 'react'

export type sortType = 'asc' | 'desc'

/*
About NestedKeyOf
Type for retrieving keys of nested objects in the form of strings.
All keys of a nested object can be represented as a string in the form of a concatenation of dots (.)
The keys of all nested objects can be represented as strings concatenated with dots (.).
*Except for function types.

example.
 1. When T is a simple object type
    Example: { id: number; name: string; }
    - Result: 'id' | 'name'

 2. When T is a nested object type
    Example: { user: { id: number; name: string; }; isActive: boolean; }
    - Result: 'user' | 'user.id' | 'user.name' | 'isActive'

 3. When T includes function properties
    Example: { id: number; getName: () => string; }
    - Result: 'id' (Since `getName` is a function, it is excluded)

 4. When T is a deeply nested object type
    Example: { user: { address: { street: string; city: string; }; }; }
    - Result: 'user' | 'user.address' | 'user.address.street' | 'user.address.city'
*/
export type NestedKeyOf<T extends object> = {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  [K in keyof T & (string | number)]: T[K] extends Function
    ? never
    : T[K] extends object | undefined
      ? `${K}` | `${K}.${NestedKeyOf<NonNullable<T[K]>>}`
      : `${K}`
}[keyof T & (string | number)]

/*
Retrieves a value from an object based on a specified (nested) key.
example
const customerSegments = {
  id: 'aaa',
  metrics: {
    count: 10,
    ltv: 2000
  }
}
getNestedValue(customerSegments, 'id')
-> 'aaa'
getNestedValue(customerSegments, 'metrics.count')
-> 10
*/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getNestedValue = <T extends object>(obj: T, key: NestedKeyOf<T>): any => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return key.split('.').reduce((o, k) => (o as any)[k], obj as any)
}

export const sortData = <T extends object>(data: T[], target: NestedKeyOf<T>, orderBy: sortType): T[] => {
  if (!target) return data
  const dataCopy = [...data]
  dataCopy.sort((a, b) => {
    let columnA = getNestedValue(a, target)
    let columnB = getNestedValue(b, target)

    if (dayjs.isDayjs(columnA) && dayjs.isDayjs(columnB)) {
      columnA = columnA.unix()
      columnB = columnB.unix()
    }

    if (typeof columnA === 'string' || typeof columnA === 'number') {
      if (orderBy === 'asc') {
        return columnA < columnB ? -1 : columnA > columnB ? 1 : 0
      } else {
        return columnA < columnB ? 1 : columnA > columnB ? -1 : 0
      }
    } else {
      throw new Error(`Unsupported column type: ${typeof columnA}, column:${columnA}`)
    }
  })
  return dataCopy
}

type SortState<T extends object> = {
  target: NestedKeyOf<T> | undefined
  orderBy: sortType
}

type UseSortResult<T extends object> = {
  sortedData: T[]
  sort: SortState<T>
  toggleSort: (column: NestedKeyOf<T>) => void
  handleSort: (column: NestedKeyOf<T>, orderBy: sortType) => void
}

const initialDirection: sortType = 'desc'

export const useSort = <T extends object>(data: T[]): UseSortResult<T> => {
  const [sort, setSort] = useState<SortState<T>>({
    target: undefined,
    orderBy: initialDirection,
  })

  const sortedData = useMemo(() => {
    if (!sort.target) return data

    return sortData(data, sort.target, sort.orderBy)
  }, [data, sort])

  const toggleSort = (column: NestedKeyOf<T>) => {
    if (!sort.target) {
      const newState: SortState<T> = {
        target: column || undefined,
        orderBy: 'desc',
      }
      setSort(newState)
    } else if (sort.target === column) {
      const newState: SortState<T> = {
        target: column || undefined,
        orderBy: sort.orderBy === 'asc' ? 'desc' : 'asc',
      }
      setSort(newState)
    } else {
      const newState: SortState<T> = {
        target: column || undefined,
        orderBy: 'desc',
      }
      setSort(newState)
    }
  }
  const handleSort = (column: NestedKeyOf<T>, orderBy: sortType) => {
    setSort({
      target: column || undefined,
      orderBy: orderBy,
    })
  }

  return { sortedData, sort, toggleSort, handleSort }
}
