// @generated by protoc-gen-es v2.2.3 with parameter "target=ts"
// @generated from file proto/ai_assist/ai_assist.proto (package ai_assist, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage, GenService } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file proto/ai_assist/ai_assist.proto.
 */
export const file_proto_ai_assist_ai_assist: GenFile = /*@__PURE__*/
  fileDesc("Ch9wcm90by9haV9hc3Npc3QvYWlfYXNzaXN0LnByb3RvEglhaV9hc3Npc3QiVgonUHJvdmlkZUluc2lnaHRGb3JDdXN0b21lclNlZ21lbnRSZXF1ZXN0EhkKEWN1c3RvbWVyU2VnbWVudElkGAEgASgJEhAKCHF1ZXN0aW9uGAIgASgJImEKKFByb3ZpZGVJbnNpZ2h0Rm9yQ3VzdG9tZXJTZWdtZW50UmVzcG9uc2USDgoGYW5zd2VyGAEgASgJEhEKCWRpbWVuc2lvbhgCIAEoCRISCgpyZXBvcnRUeXBlGAMgASgJMqEBCg9BSUFzc2lzdFNlcnZpY2USjQEKIFByb3ZpZGVJbnNpZ2h0Rm9yQ3VzdG9tZXJTZWdtZW50EjIuYWlfYXNzaXN0LlByb3ZpZGVJbnNpZ2h0Rm9yQ3VzdG9tZXJTZWdtZW50UmVxdWVzdBozLmFpX2Fzc2lzdC5Qcm92aWRlSW5zaWdodEZvckN1c3RvbWVyU2VnbWVudFJlc3BvbnNlIgBCNFoyZWNwb3dlci5pby9hcGktc2VydmVyL3Byb3RvL2FpX2Fzc2lzdDthaV9hc3Npc3RfcGJiBnByb3RvMw");

/**
 * ProvideInsightForCustomerSegment
 *
 * @generated from message ai_assist.ProvideInsightForCustomerSegmentRequest
 */
export type ProvideInsightForCustomerSegmentRequest = Message<"ai_assist.ProvideInsightForCustomerSegmentRequest"> & {
  /**
   * @generated from field: string customerSegmentId = 1;
   */
  customerSegmentId: string;

  /**
   * @generated from field: string question = 2;
   */
  question: string;
};

/**
 * Describes the message ai_assist.ProvideInsightForCustomerSegmentRequest.
 * Use `create(ProvideInsightForCustomerSegmentRequestSchema)` to create a new message.
 */
export const ProvideInsightForCustomerSegmentRequestSchema: GenMessage<ProvideInsightForCustomerSegmentRequest> = /*@__PURE__*/
  messageDesc(file_proto_ai_assist_ai_assist, 0);

/**
 * @generated from message ai_assist.ProvideInsightForCustomerSegmentResponse
 */
export type ProvideInsightForCustomerSegmentResponse = Message<"ai_assist.ProvideInsightForCustomerSegmentResponse"> & {
  /**
   * answer to the question referring to a relevant insight report
   *
   * @generated from field: string answer = 1;
   */
  answer: string;

  /**
   * type: InsightDimension
   *
   * @generated from field: string dimension = 2;
   */
  dimension: string;

  /**
   * type: InsightReportType
   *
   * @generated from field: string reportType = 3;
   */
  reportType: string;
};

/**
 * Describes the message ai_assist.ProvideInsightForCustomerSegmentResponse.
 * Use `create(ProvideInsightForCustomerSegmentResponseSchema)` to create a new message.
 */
export const ProvideInsightForCustomerSegmentResponseSchema: GenMessage<ProvideInsightForCustomerSegmentResponse> = /*@__PURE__*/
  messageDesc(file_proto_ai_assist_ai_assist, 1);

/**
 * @generated from service ai_assist.AIAssistService
 */
export const AIAssistService: GenService<{
  /**
   * @generated from rpc ai_assist.AIAssistService.ProvideInsightForCustomerSegment
   */
  provideInsightForCustomerSegment: {
    methodKind: "unary";
    input: typeof ProvideInsightForCustomerSegmentRequestSchema;
    output: typeof ProvideInsightForCustomerSegmentResponseSchema;
  },
}> = /*@__PURE__*/
  serviceDesc(file_proto_ai_assist_ai_assist, 0);

