import { enUS, ja } from 'date-fns/locale'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { cn } from '@/lib/utils'
import { formatDate } from '@/utils/timeUtil'
import dayjs from 'dayjs'
import { CalendarIcon } from 'lucide-react'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { DateRange } from 'react-day-picker'
import { useTranslation } from 'react-i18next'
import { Separator } from './ui/separator'

type Props = {
  defaultDateRange: DateRange
  defaultCustomDateRange: CustomDateRange
  onDateChange: (date: DateRange) => void
  timezone: string
}

export enum CustomDateRange {
  last7Days = 'last7Days',
  last14Days = 'last14Days',
  last30Days = 'last30Days',
  last90Days = 'last90Days',
  last180Days = 'last180Days',
  last365Days = 'last365Days',
  thisWeek = 'thisWeek',
  lastWeek = 'lastWeek',
  thisMonth = 'thisMonth',
  lastMonth = 'lastMonth',
  thisYear = 'thisYear',
  lastYear = 'lastYear',
}

export const convertCustomDateRangeToStartEndDate = (
  customDateRange: CustomDateRange,
  timezone: string
): { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } => {
  const now = dayjs().tz(timezone)
  switch (customDateRange) {
    case CustomDateRange.last7Days:
      return { startDate: now.subtract(6, 'day'), endDate: now }
    case CustomDateRange.last14Days:
      return { startDate: now.subtract(13, 'day'), endDate: now }
    case CustomDateRange.last30Days:
      return { startDate: now.subtract(29, 'day'), endDate: now }
    case CustomDateRange.last90Days:
      return { startDate: now.subtract(89, 'day'), endDate: now }
    case CustomDateRange.last180Days:
      return { startDate: now.subtract(179, 'day'), endDate: now }
    case CustomDateRange.last365Days:
      return { startDate: now.subtract(364, 'day'), endDate: now }
    case CustomDateRange.thisWeek:
      return { startDate: now.startOf('week').add(1, 'day'), endDate: now.endOf('week').add(1, 'day') }
    case CustomDateRange.lastWeek:
      return { startDate: now.subtract(1, 'week').startOf('week').add(1, 'day'), endDate: now.subtract(1, 'week').endOf('week').add(1, 'day') }
    case CustomDateRange.thisMonth:
      return { startDate: now.startOf('month'), endDate: now.endOf('month') }
    case CustomDateRange.lastMonth:
      return { startDate: now.subtract(1, 'month').startOf('month'), endDate: now.subtract(1, 'month').endOf('month') }
    case CustomDateRange.thisYear:
      return { startDate: now.startOf('year'), endDate: now.endOf('year') }
    case CustomDateRange.lastYear:
      return { startDate: now.subtract(1, 'year').startOf('year'), endDate: now.subtract(1, 'year').endOf('year') }
    default:
      return { startDate: now.subtract(6, 'day'), endDate: now }
  }
}

export const DateRangePicker: React.FC<Props> = ({ defaultDateRange, defaultCustomDateRange, onDateChange, timezone }) => {
  const { t, i18n } = useTranslation()
  const [date, setDate] = useState<DateRange | undefined>(defaultDateRange)
  const [tempDate, setTempDate] = useState<DateRange | undefined>(date)
  const [customDateRange, setCustomDateRange] = useState<CustomDateRange>(defaultCustomDateRange)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  useEffect(() => {
    const { startDate, endDate } = convertCustomDateRangeToStartEndDate(customDateRange, timezone)
    setTempDate({ from: startDate.toDate(), to: endDate.toDate() })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customDateRange])

  const handleDateChange = (newDate: DateRange | undefined) => {
    if (newDate?.to && dayjs(newDate.to).isAfter(dayjs())) {
      newDate.to = dayjs().toDate()
    }
    setTempDate(newDate)
  }

  const applyDateChange = () => {
    if (!tempDate?.from || !tempDate?.to) return
    setDate(tempDate)
    if (onDateChange) onDateChange(tempDate)
    setIsPopoverOpen(false)
  }

  return (
    <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <PopoverTrigger asChild>
        <Button id='date' variant='outline' className={cn('justify-start text-left font-normal', !date && 'text-muted-foreground')}>
          <CalendarIcon />
          {date?.from ? (
            date.to ? (
              <>
                {formatDate(dayjs(date.from), i18n.language)} - {formatDate(dayjs(date.to), i18n.language)}
              </>
            ) : (
              formatDate(dayjs(date.from), i18n.language)
            )
          ) : (
            <span>{t('common.dateRangePicker.pickDate')}</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-auto p-0' align='start'>
        <div className='flex items-center justify-between'>
          <div className='flex flex-col gap-1 max-h-[300px] overflow-y-auto m-2'>
            {Object.values(CustomDateRange).map((value) => (
              <Button
                key={value}
                variant='ghost'
                onClick={() => setCustomDateRange(value)}
                className={cn('cursor-pointer', value === customDateRange && 'bg-muted')}
              >
                {t(`common.dateRangePicker.customDateRange_${value}`)}
              </Button>
            ))}
          </div>
          <Calendar
            locale={i18n.language === 'ja' ? ja : enUS}
            initialFocus
            mode='range'
            defaultMonth={tempDate?.from}
            selected={tempDate}
            onSelect={handleDateChange}
            numberOfMonths={2}
          />
        </div>
        <Separator />
        <div className='flex justify-end gap-2 p-2'>
          <Button variant='outline' onClick={() => setIsPopoverOpen(false)}>
            {t('common.dateRangePicker.close')}
          </Button>
          <Button onClick={applyDateChange} disabled={!tempDate?.from || !tempDate?.to}>
            {t('common.dateRangePicker.apply')}
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  )
}
