import { defaultOrderCountFilter, defaultOrderDateFilter, defaultOrderSequenceFilter } from '../defalutValues'
import {
  OP_CUSTOM,
  OP_DATE_RANGE,
  OP_NONE,
  OP_ORDER_SEQUENCE_FIRST,
  OP_ORDER_SEQUENCE_LAST,
  OP_ORDER_SEQUENCE_SECOND,
  OP_ORDER_SEQUENCE_SPECIFY,
  OP_RELATIVE_DATE_RANGE,
} from './const'
import { FilterItemApiState, QuerySetApiState, querySet as apiQuerySetSchema } from './querySetApiSchema'
import { FilterItemFormState, QuerySetFormState, querySet as formQuerySetSchema } from './querySetFormSchema'

// string -> QuerySetApiState
export const convertStringToApiQuerySetState = (jsonString: string): QuerySetApiState => {
  try {
    const querySetState: QuerySetApiState = apiQuerySetSchema.parse(JSON.parse(jsonString)) // Validate
    return querySetState
  } catch (error) {
    console.error('json:', jsonString)
    throw error
  }
}

// QuerySetFormState -> QuerySetApiState
export const convertFormToQuerySet = (querySetForm: QuerySetFormState): QuerySetApiState => {
  const querySubsets = querySetForm.query_subsets.map((querySubset) => {
    const queryItems = querySubset.query_items.map((queryItem) => {
      const formFilters = queryItem.filters
      const filters: FilterItemApiState[] = []
      formFilters.forEach((filterForm) => {
        const filterItem = convertFormToFilterItem(filterForm)
        if (filterItem) filters.push(filterItem) // Push to filters only when it is not undefined
      })
      return { ...queryItem, filters: filters }
    })
    return { ...querySubset, query_items: queryItems }
  })

  const result = { ...querySetForm, query_subsets: querySubsets }
  return apiQuerySetSchema.parse(result)
}

// QuerySetApiState -> QuerySetFormState
export const convertQuerySetToForm = (querySet: QuerySetApiState): QuerySetFormState => {
  const querySubsets = querySet.query_subsets.map((querySubset) => {
    const queryItems = querySubset.query_items.map((queryItem) => {
      const _filters = queryItem.filters
      const _defaultFilters = [defaultOrderDateFilter, defaultOrderCountFilter, defaultOrderSequenceFilter]
      const defaultFilters: FilterItemFormState[] = _defaultFilters.map((filter) => ({ ...filter })) // Create a deep copy to handle defaultFormFilters in an immutable way
      _filters.forEach((filter) => {
        const index = defaultFilters.findIndex((f) => f.filter_type === filter.filter_type)
        if (index !== -1) {
          // If the filter_type exists in defaultFilters, replace it with the filter from filters
          defaultFilters[index] = convertFilterItemToForm(filter)
        }
      })
      return { ...queryItem, filters: defaultFilters }
    })
    return { ...querySubset, query_items: queryItems }
  })
  const result = { ...querySet, query_subsets: querySubsets }
  return formQuerySetSchema.parse(result)
}

// FilterItemApiState -> FilterItemFormState
const convertFilterItemToForm = (item: FilterItemApiState): FilterItemFormState => {
  switch (item.filter_type) {
    case 'order_date':
      if (item.operator === OP_DATE_RANGE) {
        return {
          filter_type: 'order_date',
          operator: item.operator,
          value: item.value,
        }
      } else if (item.operator === OP_RELATIVE_DATE_RANGE) {
        return {
          filter_type: 'order_date',
          operator: item.operator,
          value: item.value,
        }
      }
      throw new Error(`unsupported operator for order_date: ${item}`)
    case 'order_count':
      return {
        filter_type: 'order_count',
        operator: item.operator,
        value: {
          min: item.value.min,
          max: item.value.max,
        },
      }
    case 'order_sequence':
      if (item.value.min_sequence === 1 && item.value.max_sequence === 1 && item.value.ascending === true) {
        return {
          filter_type: 'order_sequence',
          operator: OP_ORDER_SEQUENCE_FIRST,
          value: undefined,
        }
      }
      if (item.value.min_sequence === 2 && item.value.max_sequence === 2 && item.value.ascending === true) {
        return {
          filter_type: 'order_sequence',
          operator: OP_ORDER_SEQUENCE_SECOND,
          value: undefined,
        }
      }
      if (item.value.min_sequence === 1 && item.value.max_sequence === 1 && item.value.ascending === false) {
        return {
          filter_type: 'order_sequence',
          operator: OP_ORDER_SEQUENCE_LAST,
          value: undefined,
        }
      }
      return {
        filter_type: 'order_sequence',
        operator: OP_ORDER_SEQUENCE_SPECIFY,
        value: { min_sequence: item.value.min_sequence, max_sequence: item.value.max_sequence, ascending: true }, // ascending is always true
      }

    // throw new Error(`unsupported operator for order_sequence: ${item}`)
    default:
      throw new Error('unsupported filter_type')
  }
}

// FilterItemFormState -> FilterItemApiState
const convertFormToFilterItem = (item: FilterItemFormState): FilterItemApiState | undefined => {
  if (item.operator === OP_NONE) return undefined

  switch (item.filter_type) {
    case 'order_date':
      if (item.operator === OP_DATE_RANGE) {
        return {
          filter_type: 'order_date',
          operator: item.operator,
          value: item.value,
        }
      }
      if (item.operator === OP_RELATIVE_DATE_RANGE) {
        return {
          filter_type: 'order_date',
          operator: item.operator,
          value: item.value,
        }
      }
      throw new Error(`unsupported operator for order_date: ${item}`)
    case 'order_count':
      return {
        filter_type: 'order_count',
        operator: item.operator,
        value: item.value,
      }
    case 'order_sequence':
      if (item.operator === OP_ORDER_SEQUENCE_FIRST) {
        return {
          filter_type: 'order_sequence',
          operator: OP_CUSTOM,
          value: { min_sequence: 1, max_sequence: 1, ascending: true },
        }
      }
      if (item.operator === OP_ORDER_SEQUENCE_SECOND) {
        return {
          filter_type: 'order_sequence',
          operator: OP_CUSTOM,
          value: { min_sequence: 2, max_sequence: 2, ascending: true },
        }
      }
      if (item.operator === OP_ORDER_SEQUENCE_LAST) {
        return {
          filter_type: 'order_sequence',
          operator: OP_CUSTOM,
          value: { min_sequence: 1, max_sequence: 1, ascending: false },
        }
      }
      if (item.operator === OP_ORDER_SEQUENCE_SPECIFY) {
        return {
          filter_type: 'order_sequence',
          operator: OP_CUSTOM,
          value: { min_sequence: item.value.min_sequence, max_sequence: item.value.max_sequence, ascending: true }, // ascending is always true
        }
      }
      throw new Error(`unsupported operator for order_sequence: ${item}`)
    default:
      throw new Error(`unsupported filter type: ${item}`)
  }
}
