import { SkeletonTable } from '@/components/skeletonTable'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import dayjs from 'dayjs'
import { getIdToken } from 'firebase/auth'
import { Account, AccountRole } from 'gen/firestore'
import { ShopService } from 'gen/proto/shop/shop_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { UserRoundX } from 'lucide-react'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDateTime } from 'utils/timeUtil'
import { InviteMemberDialog } from './components/inviteMemberDialog'
import { RemoveMemberDialog } from './components/removeMemberDialog'

type Props = {
  account: Account
}

type Member = {
  email: string
  role: string // AccountRole
  status: string // AccountStatus
  createdAt: string
}

export const Members: FC<Props> = ({ account }) => {
  const { t, i18n } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const shopService = useGrpcClient(ShopService)

  const [members, setMembers] = useState<Member[]>()
  const [dialogSubmitted, setDialogSubmitted] = useState<number>(0)

  const [inviteMemberDialogOpen, setInviteMemberDialogOpen] = useState<boolean>(false)

  useEffect(() => {
    const handleFetch = async () => {
      try {
        const token = await getIdToken(authUser!)
        const resp = await shopService.getAccounts({}, { headers: { Authorization: `Bearer ${token}` } })
        const members = resp.accounts.map((a) => {
          return {
            email: a.email,
            role: a.role,
            status: a.status,
            createdAt: formatDateTime(dayjs(a.createdAt), i18n.language),
          }
        })
        setMembers(members)
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      }
    }

    handleFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogSubmitted])

  return (
    <>
      <Card>
        <CardHeader>
          <div className='flex justify-between items-center'>
            <CardTitle>{t('features.settings.members.title')}</CardTitle>
            <Button size='sm' variant='outline' disabled={account.role !== AccountRole.owner} onClick={() => setInviteMemberDialogOpen(true)}>
              {t('features.settings.members.invite')}
            </Button>
          </div>
        </CardHeader>
        <CardContent>
          {members ? (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>{t('features.settings.members.email')}</TableHead>
                  <TableHead>{t('features.settings.members.role')}</TableHead>
                  <TableHead>{t('features.settings.members.status')}</TableHead>
                  <TableHead className='text-right'></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {members &&
                  members.map((row) => (
                    <TableRow key={row.email}>
                      <TableCell className='font-medium'>{row.email}</TableCell>
                      <TableCell>
                        <Badge variant='outline'>{t('features.settings.members.role', { context: row.role })}</Badge>
                      </TableCell>
                      <TableCell>
                        <Badge variant='outline'>{t('features.settings.members.status', { context: row.status })}</Badge>
                      </TableCell>
                      <TableCell className='text-right'>
                        {account.role === AccountRole.owner && row.email !== account.email ? (
                          <RemoveMemberDialog
                            triggerNode={
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <Button variant='ghost' size='icon'>
                                    <UserRoundX />
                                  </Button>
                                </TooltipTrigger>
                                <TooltipContent>{t('features.settings.members.remove')}</TooltipContent>
                              </Tooltip>
                            }
                            email={row.email}
                            onSubmit={() => setDialogSubmitted((count) => count + 1)}
                          />
                        ) : (
                          <Button variant='ghost' size='icon' disabled={true}>
                            <UserRoundX />
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          ) : (
            <SkeletonTable columnCount={4} rowCount={3} />
          )}
        </CardContent>
      </Card>

      {inviteMemberDialogOpen && (
        <InviteMemberDialog
          open={inviteMemberDialogOpen}
          handleClose={() => setInviteMemberDialogOpen(false)}
          onSubmit={() => setDialogSubmitted((count) => count + 1)}
        />
      )}
    </>
  )
}
