// @generated by protoc-gen-es v2.2.3 with parameter "target=ts"
// @generated from file proto/product/product.proto (package product, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage, GenService } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file proto/product/product.proto.
 */
export const file_proto_product_product: GenFile = /*@__PURE__*/
  fileDesc("Chtwcm90by9wcm9kdWN0L3Byb2R1Y3QucHJvdG8SB3Byb2R1Y3QiKwobR2V0UHJlZmVycmVkU2VnbWVudHNSZXF1ZXN0EgwKBG5hbWUYASABKAkiqgEKHEdldFByZWZlcnJlZFNlZ21lbnRzUmVzcG9uc2USPwoIc2VnbWVudHMYASADKAsyLS5wcm9kdWN0LkdldFByZWZlcnJlZFNlZ21lbnRzUmVzcG9uc2UuU2VnbWVudBpJCgdTZWdtZW50EgoKAmlkGAEgASgJEgwKBG5hbWUYAiABKAkSFQoNY3VzdG9tZXJDb3VudBgDIAEoAxINCgVyYXRpbxgEIAEoASIqChpHZXRSZWxldmFudFByb2R1Y3RzUmVxdWVzdBIMCgRuYW1lGAEgASgJIo0BChtHZXRSZWxldmFudFByb2R1Y3RzUmVzcG9uc2USPgoIcHJvZHVjdHMYASADKAsyLC5wcm9kdWN0LkdldFJlbGV2YW50UHJvZHVjdHNSZXNwb25zZS5Qcm9kdWN0Gi4KB1Byb2R1Y3QSDAoEbmFtZRgBIAEoCRIVCg1jdXN0b21lckNvdW50GAMgASgDMtsBCg5Qcm9kdWN0U2VydmljZRJlChRHZXRQcmVmZXJyZWRTZWdtZW50cxIkLnByb2R1Y3QuR2V0UHJlZmVycmVkU2VnbWVudHNSZXF1ZXN0GiUucHJvZHVjdC5HZXRQcmVmZXJyZWRTZWdtZW50c1Jlc3BvbnNlIgASYgoTR2V0UmVsZXZhbnRQcm9kdWN0cxIjLnByb2R1Y3QuR2V0UmVsZXZhbnRQcm9kdWN0c1JlcXVlc3QaJC5wcm9kdWN0LkdldFJlbGV2YW50UHJvZHVjdHNSZXNwb25zZSIAQjBaLmVjcG93ZXIuaW8vYXBpLXNlcnZlci9wcm90by9wcm9kdWN0O3Byb2R1Y3RfcGJiBnByb3RvMw");

/**
 * GetPreferredSegments
 *
 * @generated from message product.GetPreferredSegmentsRequest
 */
export type GetPreferredSegmentsRequest = Message<"product.GetPreferredSegmentsRequest"> & {
  /**
   * @generated from field: string name = 1;
   */
  name: string;
};

/**
 * Describes the message product.GetPreferredSegmentsRequest.
 * Use `create(GetPreferredSegmentsRequestSchema)` to create a new message.
 */
export const GetPreferredSegmentsRequestSchema: GenMessage<GetPreferredSegmentsRequest> = /*@__PURE__*/
  messageDesc(file_proto_product_product, 0);

/**
 * @generated from message product.GetPreferredSegmentsResponse
 */
export type GetPreferredSegmentsResponse = Message<"product.GetPreferredSegmentsResponse"> & {
  /**
   * @generated from field: repeated product.GetPreferredSegmentsResponse.Segment segments = 1;
   */
  segments: GetPreferredSegmentsResponse_Segment[];
};

/**
 * Describes the message product.GetPreferredSegmentsResponse.
 * Use `create(GetPreferredSegmentsResponseSchema)` to create a new message.
 */
export const GetPreferredSegmentsResponseSchema: GenMessage<GetPreferredSegmentsResponse> = /*@__PURE__*/
  messageDesc(file_proto_product_product, 1);

/**
 * @generated from message product.GetPreferredSegmentsResponse.Segment
 */
export type GetPreferredSegmentsResponse_Segment = Message<"product.GetPreferredSegmentsResponse.Segment"> & {
  /**
   * @generated from field: string id = 1;
   */
  id: string;

  /**
   * @generated from field: string name = 2;
   */
  name: string;

  /**
   * @generated from field: int64 customerCount = 3;
   */
  customerCount: bigint;

  /**
   * @generated from field: double ratio = 4;
   */
  ratio: number;
};

/**
 * Describes the message product.GetPreferredSegmentsResponse.Segment.
 * Use `create(GetPreferredSegmentsResponse_SegmentSchema)` to create a new message.
 */
export const GetPreferredSegmentsResponse_SegmentSchema: GenMessage<GetPreferredSegmentsResponse_Segment> = /*@__PURE__*/
  messageDesc(file_proto_product_product, 1, 0);

/**
 * GetRelevantProducts
 *
 * @generated from message product.GetRelevantProductsRequest
 */
export type GetRelevantProductsRequest = Message<"product.GetRelevantProductsRequest"> & {
  /**
   * @generated from field: string name = 1;
   */
  name: string;
};

/**
 * Describes the message product.GetRelevantProductsRequest.
 * Use `create(GetRelevantProductsRequestSchema)` to create a new message.
 */
export const GetRelevantProductsRequestSchema: GenMessage<GetRelevantProductsRequest> = /*@__PURE__*/
  messageDesc(file_proto_product_product, 2);

/**
 * @generated from message product.GetRelevantProductsResponse
 */
export type GetRelevantProductsResponse = Message<"product.GetRelevantProductsResponse"> & {
  /**
   * @generated from field: repeated product.GetRelevantProductsResponse.Product products = 1;
   */
  products: GetRelevantProductsResponse_Product[];
};

/**
 * Describes the message product.GetRelevantProductsResponse.
 * Use `create(GetRelevantProductsResponseSchema)` to create a new message.
 */
export const GetRelevantProductsResponseSchema: GenMessage<GetRelevantProductsResponse> = /*@__PURE__*/
  messageDesc(file_proto_product_product, 3);

/**
 * @generated from message product.GetRelevantProductsResponse.Product
 */
export type GetRelevantProductsResponse_Product = Message<"product.GetRelevantProductsResponse.Product"> & {
  /**
   * @generated from field: string name = 1;
   */
  name: string;

  /**
   * @generated from field: int64 customerCount = 3;
   */
  customerCount: bigint;
};

/**
 * Describes the message product.GetRelevantProductsResponse.Product.
 * Use `create(GetRelevantProductsResponse_ProductSchema)` to create a new message.
 */
export const GetRelevantProductsResponse_ProductSchema: GenMessage<GetRelevantProductsResponse_Product> = /*@__PURE__*/
  messageDesc(file_proto_product_product, 3, 0);

/**
 * @generated from service product.ProductService
 */
export const ProductService: GenService<{
  /**
   * @generated from rpc product.ProductService.GetPreferredSegments
   */
  getPreferredSegments: {
    methodKind: "unary";
    input: typeof GetPreferredSegmentsRequestSchema;
    output: typeof GetPreferredSegmentsResponseSchema;
  },
  /**
   * @generated from rpc product.ProductService.GetRelevantProducts
   */
  getRelevantProducts: {
    methodKind: "unary";
    input: typeof GetRelevantProductsRequestSchema;
    output: typeof GetRelevantProductsResponseSchema;
  },
}> = /*@__PURE__*/
  serviceDesc(file_proto_product_product, 0);

