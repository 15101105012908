import { PageContentWrapper } from '@/components/pageContentWrapper'
import { PageHeader } from '@/components/pageHeader'
import { Button } from '@/components/ui/button'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { UpgradeRecommendDialog } from 'components/upgradeRecommendDialog'
import { DetailSegmentMenu } from 'features/customerSegments/components/detailSegmentMenu'
import { useAccount } from 'hooks/useAccount'
import { useActionTracker } from 'hooks/useMixpanel'
import { Activity, Bot, ChartSpline, Ellipsis, Lightbulb, RefreshCcw, ScanText, User } from 'lucide-react'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { convertQuerySetToForm, convertStringToApiQuerySetState } from '../../querySetForm/schema/converter'
import { Activities } from './components/activities'
import { Customers } from './components/customers'
import { DetailDialog } from './components/detailDialog'
import { Metrics } from './components/metrics'
import { SuggestInsightDialog } from './components/suggestInsightDialog'
import { Sync } from './components/sync'
import { useActivities } from './hooks/useActivities'
import { useCustomerSegment } from './hooks/useCustomerSegment'
import { useCustomers } from './hooks/useCustomers'
import { useMetricsHistory } from './hooks/useMetricsHistory'
import { useTagsRunningStatus } from './hooks/useTagsRunningStatus'
import { convertTimeRangeToStartEndDate, TimeRange } from './types/timeRange'

enum TabIndex {
  metrics = 'metrics',
  activities = 'activities',
  customers = 'customers',
  sync = 'sync',
}

export const Detail = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { shop } = useAccount()
  const { dispatch } = useActionTracker()
  const navigate = useNavigate()

  const params = useParams()
  const customerSegmentId = params.id
  const customerSegment = useCustomerSegment(customerSegmentId)

  const { startDate: initMetricsStartDate, endDate: initMetricsEndDate } = convertTimeRangeToStartEndDate(TimeRange.last30Days, shop.timezone)
  const [metricsStartDate, setMetricsStartDate] = useState(initMetricsStartDate)
  const [metricsEndDate, setMetricsEndDate] = useState(initMetricsEndDate)
  const { metricsHistory, loading: loadingMetricsHistory } = useMetricsHistory(customerSegmentId, metricsStartDate, metricsEndDate)

  const { startDate: initActivitiesStartDate, endDate: initActivitiesEndDate } = convertTimeRangeToStartEndDate(TimeRange.last7Days, shop.timezone)
  const [activitiesStartDate, setActivitiesStartDate] = useState(initActivitiesStartDate)
  const [activitiesEndDate, setActivitiesEndDate] = useState(initActivitiesEndDate)
  const { activities, loading: loadingActivities } = useActivities(customerSegmentId, activitiesStartDate, activitiesEndDate)

  const { customers, loading: loadingCustomers } = useCustomers(customerSegment, 100)

  const { currentTagRunning, otherTagsRunning } = useTagsRunningStatus(customerSegmentId)

  const [menuOpen, setMenuOpen] = useState(false)
  const menuAnchorRef = useRef<HTMLButtonElement | null>(null)
  const [suggestInsightDialogOpen, setSuggestInsightDialogOpen] = useState(false)
  const [upgradeRecommendDialogOpen, setUpgradeRecommendDialogOpen] = useState(false)
  const [detailDialogOpen, setDetailDialogOpen] = useState(false)

  const initialTabIndex = (): TabIndex => {
    if (location.hash === `#${TabIndex.activities}`) return TabIndex.activities
    if (location.hash === `#${TabIndex.customers}`) return TabIndex.customers
    if (location.hash === `#${TabIndex.sync}`) return TabIndex.sync
    return TabIndex.metrics
  }
  const [tabIndex, setTabIndex] = useState<TabIndex>(initialTabIndex)

  const handleTabChange = (newTabIndex: string) => {
    const tab = newTabIndex as TabIndex
    setTabIndex(tab)
    navigate(tab === TabIndex.metrics ? '' : `#${tab}`)
  }

  return (
    <>
      <PageHeader
        title={customerSegment?.name || ''}
        origin={{ title: t('features.customerSegments.detail.segments'), path: AUTHORIZED_ROUTE.CUSTOMER_SEGMENTS }}
        menuComponent={
          <>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant='ghost'
                  size='icon'
                  onClick={() => {
                    dispatch('OpenSegmentQueryset', { name: customerSegment?.name })
                    setDetailDialogOpen(true)
                  }}
                >
                  <ScanText />
                </Button>
              </TooltipTrigger>
              <TooltipContent>{t('features.customerSegments.detail.showDetails')}</TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant='ghost' size='icon' onClick={() => setSuggestInsightDialogOpen(true)}>
                  <Bot />
                </Button>
              </TooltipTrigger>
              <TooltipContent>{t('features.customerSegments.detail.askAi')}</TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button ref={menuAnchorRef} variant='ghost' size='icon' onClick={() => setMenuOpen(true)}>
                  <Ellipsis />
                </Button>
              </TooltipTrigger>
              <TooltipContent>{t('features.customerSegments.detail.menu')}</TooltipContent>
            </Tooltip>
          </>
        }
      />

      <PageContentWrapper>
        <Tabs value={tabIndex} onValueChange={handleTabChange} className='w-full'>
          <div className='flex items-center justify-between mb-6'>
            <TabsList>
              <TabsTrigger value={TabIndex.metrics} className='gap-2'>
                <ChartSpline size={16} />
                {t('features.customerSegments.detail.tabIndexMetrics')}
              </TabsTrigger>
              <TabsTrigger value={TabIndex.activities} className='gap-2'>
                <Activity size={16} />
                {t('features.customerSegments.detail.tabIndexActivities')}
              </TabsTrigger>
              <TabsTrigger value={TabIndex.customers} className='gap-2'>
                <User size={16} />
                {t('features.customerSegments.detail.tabIndexCustomers')}
              </TabsTrigger>
              <TabsTrigger value={TabIndex.sync} className='gap-2'>
                <RefreshCcw size={16} />
                {t('features.customerSegments.detail.tabIndexSync')}
              </TabsTrigger>
            </TabsList>
            <Button variant='outline' size='sm' onClick={() => navigate(`${AUTHORIZED_ROUTE.INSIGHTS}?customer_segment_id=${customerSegment?.id}`)}>
              <Lightbulb />
              {t('features.customerSegments.detail.viewInsights')}
            </Button>
          </div>
          <TabsContent value={TabIndex.metrics}>
            <Metrics
              customerSegment={customerSegment}
              metricsHistory={metricsHistory}
              loading={loadingMetricsHistory}
              handleTimeRangeChange={(startDate, endDate) => {
                setMetricsStartDate(startDate)
                setMetricsEndDate(endDate)
              }}
            />
          </TabsContent>
          <TabsContent value={TabIndex.activities}>
            <Activities
              customerSegment={customerSegment}
              activities={activities}
              loading={loadingActivities}
              handleTimeRangeChange={(startDate, endDate) => {
                setActivitiesStartDate(startDate)
                setActivitiesEndDate(endDate)
              }}
            />
          </TabsContent>
          <TabsContent value={TabIndex.customers}>
            <Customers customerSegment={customerSegment} customers={customers} loading={loadingCustomers} />
          </TabsContent>
          <TabsContent value={TabIndex.sync}>
            <Sync customerSegment={customerSegment} currentTagRunning={currentTagRunning} otherTagsRunning={otherTagsRunning} />
          </TabsContent>
        </Tabs>
      </PageContentWrapper>

      {customerSegment && (
        <SuggestInsightDialog
          open={suggestInsightDialogOpen}
          handleClose={() => setSuggestInsightDialogOpen(false)}
          segmentId={customerSegment.id}
          segmentName={customerSegment.name}
        />
      )}

      {customerSegment && (
        <DetailSegmentMenu
          open={menuOpen}
          handleClose={() => setMenuOpen(false)}
          anchorEl={menuAnchorRef.current}
          customerSegment={customerSegment}
        />
      )}

      {upgradeRecommendDialogOpen && (
        <UpgradeRecommendDialog
          open={upgradeRecommendDialogOpen}
          handleClose={() => setUpgradeRecommendDialogOpen(false)}
          referrer='customerSegmentOverview'
        />
      )}

      {detailDialogOpen && (
        <DetailDialog
          open={detailDialogOpen}
          handleClose={() => setDetailDialogOpen(false)}
          name={customerSegment?.name || ''}
          description={customerSegment?.description || ''}
          querySet={convertQuerySetToForm(convertStringToApiQuerySetState(customerSegment?.querySet || ''))}
        />
      )}
    </>
  )
}
