import * as Sentry from '@sentry/react'
import { useAuthUser } from 'hooks/useAuthUser'
import { env } from 'config/env'

type UseSentryNotifierResult = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  notifySentry: (err: any, extraInfo?: any) => void
}

export const useSentryNotifier = (): UseSentryNotifierResult => {
  const user = useAuthUser()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const notifySentry = (err: Error, extraInfo?: any) => {
    if (env.SENTRY_DNS) {
      Sentry.withScope(() => {
        if (user) {
          Sentry.setUser({
            id: user.uid,
            email: user.email || undefined,
          })
          if (extraInfo) {
            Sentry.setExtra('extraInfo', extraInfo)
          }
        }

        Sentry.captureException(err)
      })
    } else {
      // Output to the brawser console if Sentry DNS is not set
      console.log(`invoke notifySentry function! err: ${err}`)
    }
  }

  return { notifySentry }
}
