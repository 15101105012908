import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Code, ConnectError } from '@connectrpc/connect'
import { zodResolver } from '@hookform/resolvers/zod'
import { Link } from '@mui/material'
import { env } from 'config/env'
import { auth } from 'config/firebaseClient'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { AuthService } from 'gen/proto/auth/auth_pb'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { Loader2 } from 'lucide-react'
import { useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { PUBLIC_ROUTE } from 'routing'
import { z } from 'zod'
import { AuthHeader } from './components/authHeader'

const getEmbeddedAppURL = (shop: string) => {
  return `https://${shop}.myshopify.com/admin/apps/${env.EMBEDDED_SHOPIFY_APP_NAME}`
}

const verifySessionTokenIntervalMs = 10 * 1000

export const Signup = () => {
  const { t, i18n } = useTranslation()
  const [shopName, setShopName] = useState('')
  const navigate = useNavigate()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const authService = useGrpcClient(AuthService)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const embeddedAppSessionToken = queryParams.get('sessionToken') || undefined

  const verifySessionToken = useCallback(async () => {
    try {
      const res = await authService.getShopByToken({ sessionToken: embeddedAppSessionToken })
      if (!res.shopName) {
        throw new Error('shopName is not found')
      }
      setShopName(res.shopName)
      if (res.shopName && !res.tokenVerified) {
        alert(t('features.auth.signup.messageUrlIsExpired'))
        window.location.replace(getEmbeddedAppURL(res.shopName))
      }
    } catch (err) {
      notifySentry(err, { sessionToken: embeddedAppSessionToken })
      alert(t('features.auth.signup.messageUrlIsInvalid'))
      let url = env.SHOPIFY_APP_STORE_URL
      if (i18n.language === 'ja') url += '?locale=ja'
      window.location.replace(url)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authService, embeddedAppSessionToken, notifySentry])

  useEffect(() => {
    // first verify session token
    verifySessionToken()

    // interval verify session token
    const intervalId = setInterval(verifySessionToken, verifySessionTokenIntervalMs)
    return () => {
      clearInterval(intervalId)
    }
  }, [verifySessionToken])

  const signupInputSchema = z.object({
    email: z
      .string()
      .min(1, { message: t('features.auth.signup.messageValidationEmailIsRequired') })
      .email(t('features.auth.signup.messageValidationEmailIsInvalid')),
    password: z
      .string()
      .min(6, { message: t('features.auth.signup.messageValidationPasswordMin') })
      .max(30, { message: t('features.auth.signup.messageValidationPasswordMax') }),
  })
  type InputSchema = z.infer<typeof signupInputSchema>

  const {
    setError,
    register,
    handleSubmit,
    formState: { errors: formErrors, isSubmitting },
  } = useForm<InputSchema>({
    resolver: zodResolver(signupInputSchema),
  })

  const handleSignup: SubmitHandler<InputSchema> = async (input) => {
    try {
      await authService.signup({
        email: input.email,
        password: input.password,
        sessionToken: embeddedAppSessionToken,
        language: i18n.language,
      })
      await signInWithEmailAndPassword(auth, input.email, input.password)
    } catch (err) {
      if (err instanceof ConnectError && err.code === Code.AlreadyExists) {
        setError('email', { message: t('features.auth.signup.messageEmailExists') })
        return
      }
      enqueueSnackbar(t('features.auth.signup.messageUnknownError'), { severity: 'error' })
      notifySentry(err, { inputEmail: input.email, inputPassword: input.password })
    }
  }

  return (
    <div className='grid min-h-svh lg:grid-cols-2'>
      <div className='flex flex-col gap-4 p-6 md:p-10'>
        <AuthHeader />
        <div className='flex flex-1 items-center justify-center'>
          <div className='w-full max-w-xs'>
            <form className='flex flex-col gap-6'>
              <div className='flex flex-col gap-2'>
                <h1 className='text-2xl font-bold'>{t('features.auth.signup.title')}</h1>
                <p className='text-balance text-sm text-muted-foreground'>{t('features.auth.signup.description')}</p>
              </div>
              <div className='grid gap-6'>
                <div className='grid gap-2'>
                  <Label htmlFor='store'>{t('features.auth.signup.store')}</Label>
                  <Input id='store' type='text' value={shopName ? `${shopName}.myshopify.com` : ''} disabled />
                </div>
                <div className='grid gap-2'>
                  <Label htmlFor='email'>{t('features.auth.signup.email')}</Label>
                  <Input id='email' type='email' required {...register('email')} />
                  {formErrors.email && <p className='text-red-500 text-xs'>{formErrors.email.message}</p>}
                </div>
                <div className='grid gap-2'>
                  <Label htmlFor='password'>{t('features.auth.signup.password')}</Label>
                  <Input id='password' type='password' required {...register('password')} />
                  {formErrors.password && <p className='text-red-500 text-xs'>{formErrors.password.message}</p>}
                </div>
                <Button type='submit' className='w-full' onClick={handleSubmit(handleSignup)} disabled={!shopName || isSubmitting}>
                  {isSubmitting ? <Loader2 className='animate-spin' /> : t('features.auth.signup.submit')}
                </Button>
              </div>
              <div className='text-center text-xs text-muted-foreground'>
                <Trans
                  i18nKey='features.auth.signup.agreeToTermsAndPrivacyPolicy'
                  components={{
                    lt: <Link href={t('features.auth.signup.link_terms')} target='_blank' rel='noopener noreferrer' color='inherit' />,
                    ld: <Link href={t('features.auth.signup.link_dpa')} target='_blank' rel='noopener noreferrer' color='inherit' />,
                    lp: <Link href={t('features.auth.signup.link_privacyPolicy')} target='_blank' rel='noopener noreferrer' color='inherit' />,
                  }}
                />
              </div>
              <div className='text-center text-sm'>
                {t('features.auth.signup.login')}
                <a className='underline underline-offset-4 cursor-pointer' onClick={() => navigate(PUBLIC_ROUTE.LOGIN)}>
                  {t('features.auth.signup.loginLink')}
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className='relative hidden bg-muted lg:block'>
        <div className='absolute inset-0 h-full w-full object-cover dark:brightness-[0.2] dark:grayscale'>
          <div className='flex justify-center items-center h-full'>
            <img src='images/auth/signup.svg' alt='Image' className='w-2/3' />
          </div>
        </div>
      </div>
    </div>
  )
}
