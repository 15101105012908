import { Box, Dialog } from '@mui/material'
import { MetricsItems } from 'components/metricsItems'
import { convertFormToQuerySet } from 'features/customerSegments/querySetForm/schema/converter'
import { QuerySetFormState } from 'features/customerSegments/querySetForm/schema/querySetFormSchema'
import { getIdToken } from 'firebase/auth'
import { CustomerMetrics } from 'gen/firestore'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  querySetFormMethods: UseFormReturn<QuerySetFormState>
  handleClose: () => void
}

export const PreviewMetricsDialog: FC<Props> = ({ open, querySetFormMethods, handleClose }) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const customerSegmentService = useGrpcClient(CustomerSegmentService)

  const [metrics, setMetrics] = useState<CustomerMetrics>()

  useEffect(() => {
    const fetch = async () => {
      try {
        const token = await getIdToken(authUser!)

        const ok = await querySetFormMethods.trigger()
        if (!ok) return
        const querySetFormValues = querySetFormMethods.getValues()
        const querySet = convertFormToQuerySet(querySetFormValues)
        const querySetString = JSON.stringify(querySet)

        const resp = await customerSegmentService.estimateCustomerMetrics(
          { querySet: querySetString },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        if (!resp.result) return
        setMetrics({
          count: Number(resp.result.count),
          repeaterRate: resp.result.repeaterRate,
          ltv: Number(resp.result.ltv),
          avgOrderValue: Number(resp.result.avgOrderValue),
          avgOrderCount: resp.result.avgOrderCount,
          avgDaysBetweenOrders: resp.result.avgDaysBetweenOrders,
        })
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      }
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='lg'
      fullWidth
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
    >
      <Box padding='24px 32px'>
        <MetricsItems metrics={metrics} type='grid' />
      </Box>
    </Dialog>
  )
}
