import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { getIdToken } from 'firebase/auth'
import { ShopService } from 'gen/proto/shop/shop_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { Loader2 } from 'lucide-react'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  triggerNode: React.ReactNode
  email: string
  onSubmit: () => void
}

export const RemoveMemberDialog: FC<Props> = ({ triggerNode, email, onSubmit }) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const shopService = useGrpcClient(ShopService)
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const token = await getIdToken(authUser!)
      await shopService.removeAccount({ email: email }, { headers: { Authorization: `Bearer ${token}` } })
      enqueueSnackbar(t('features.settings.members.removeMemberDialog.messageSuccess'), { severity: 'success' })
      onSubmit()
    } catch (err) {
      enqueueSnackbar(t('features.settings.members.removeMemberDialog.messageError'), { severity: 'error' })
      notifySentry(err, { inputEmail: email })
    } finally {
      setLoading(false)
    }
  }

  return (
    <AlertDialog>
      <AlertDialogTrigger>{triggerNode}</AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t('features.settings.members.removeMemberDialog.title')}</AlertDialogTitle>
          <AlertDialogDescription>{t('features.settings.members.removeMemberDialog.description', { email: email })}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>{t('features.settings.members.removeMemberDialog.cancel')}</AlertDialogCancel>
          <Button onClick={handleSubmit} disabled={loading}>
            {loading ? <Loader2 className='animate-spin' /> : t('features.settings.members.removeMemberDialog.submit')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
