import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { EmptyState } from 'components/emptyState'
import { InsightDimension } from 'gen/firestore'
import { useActionTracker } from 'hooks/useMixpanel'
import { FileSearch, LoaderCircle } from 'lucide-react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { extractIconFromTextWithIcon, extractTextFromTextWithIcon } from 'utils/iconUtil'
import { InsightValue } from '../insights'

type Props = {
  title: string
  customerSegmentId: string
  insightDimension: InsightDimension
  insightValues: InsightValue[] | undefined
  loading: boolean
  insightDimensions: InsightDimension[]
  setInsightDimension: (dimension: InsightDimension) => void
}

export const InsightCategoryValues: FC<Props> = ({
  title,
  customerSegmentId,
  insightDimension,
  insightValues,
  loading,
  insightDimensions,
  setInsightDimension,
}) => {
  const { t } = useTranslation()
  const { dispatch } = useActionTracker()
  const navigate = useNavigate()

  return (
    <Card>
      <CardHeader>
        <div className='flex items-center justify-between'>
          <div className='flex items-center gap-1'>
            <CardTitle>{title}</CardTitle>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant='ghost'
                  size='icon'
                  onClick={() => {
                    navigate(
                      generatePath(AUTHORIZED_ROUTE.INSIGHT_DETAIL, { id: insightDimension }) +
                        (customerSegmentId ? `?customer_segment_id=${customerSegmentId}` : '')
                    )
                  }}
                >
                  <FileSearch />
                </Button>
              </TooltipTrigger>
              <TooltipContent>{t('features.insights.viewMore')}</TooltipContent>
            </Tooltip>
          </div>
          <Select
            defaultValue={insightDimension}
            onValueChange={(value) => {
              setInsightDimension(value as InsightDimension)
              dispatch('SwitchInsightDimension', { name: value as InsightDimension })
            }}
          >
            <SelectTrigger className='w-auto h-[32px]'>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {insightDimensions.map((d) => (
                  <SelectItem key={d} value={d}>
                    {extractTextFromTextWithIcon(t(`features.insights.dimension_${d}`))}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </CardHeader>
      <CardContent>
        {loading || !insightValues ? (
          <div className='flex justify-center items-center h-[140px]'>
            <LoaderCircle className='animate-spin' />
          </div>
        ) : insightValues.length === 0 ? (
          <EmptyState title={t('features.insights.empty')} />
        ) : (
          <>
            <div className='grid grid-cols-1 gap-3'>
              {insightValues.map((v) => (
                <div className='flex justify-between items-center' key={v.name}>
                  <div className='flex items-center gap-3'>
                    <span className='text-lg'>{extractIconFromTextWithIcon(v.name)}</span>
                    <span className='text-sm'>{extractTextFromTextWithIcon(v.name)}</span>
                  </div>
                  <span className='text-xs flex items-center'>
                    {t('features.insights.customerCountAndRatio', {
                      customerCount: v.customerCount.toLocaleString(),
                      ratio: v.ratio,
                    })}
                  </span>
                </div>
              ))}
            </div>
          </>
        )}
      </CardContent>
    </Card>
  )
}
