import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { SearchDialog } from '@/components/searchDialog'
import { Button } from '@/components/ui/button'
import { SidebarGroup, SidebarMenu, SidebarMenuItem } from '@/components/ui/sidebar'
import { useActionTracker } from '@/hooks/useMixpanel'
import { AUTHORIZED_ROUTE } from '@/routing'
import { Search, UserPlus } from 'lucide-react'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const NavActions: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { dispatch } = useActionTracker()

  const [openSearchDialog, setOpenSearchDialog] = useState(false)

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        setOpenSearchDialog(true)
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [setOpenSearchDialog])

  return (
    <>
      <SidebarGroup>
        <SidebarMenu>
          <SidebarMenuItem>
            <div className='flex items-center gap-1'>
              <Button variant='outline' size='sm' className='flex-1 h-8' onClick={() => navigate(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_CREATE)}>
                <UserPlus />
                {t('layouts.dashboardLayout.createSegment')}
              </Button>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant='outline'
                    size='icon'
                    className='h-8'
                    onClick={() => {
                      dispatch('OpenSearchDialog')
                      setOpenSearchDialog(true)
                    }}
                  >
                    <Search />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>{t('layouts.dashboardLayout.search')}</TooltipContent>
              </Tooltip>
            </div>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarGroup>

      {openSearchDialog && <SearchDialog open={openSearchDialog} handleClose={() => setOpenSearchDialog(false)} />}
    </>
  )
}
