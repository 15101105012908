import { DialogWrapper } from '@/components/dialogWrapper'
import { auth } from 'config/firebaseClient'
import { sendPasswordResetEmail } from 'firebase/auth'
import { Account } from 'gen/firestore'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PUBLIC_ROUTE } from 'routing'

type Props = {
  open: boolean
  account: Account
  handleClose: () => void
}

export const ResetPasswordDialog: FC<Props> = ({ open, account, handleClose }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const actionCodeSettings = {
        url: `${window.location.origin}${PUBLIC_ROUTE.LOGIN}`,
      }
      auth.languageCode = account.language
      await sendPasswordResetEmail(auth, account.email, actionCodeSettings)
      enqueueSnackbar(t('features.settings.myAccount.resetPasswordDialog.messageSuccess'), { severity: 'success' })
    } catch (err) {
      enqueueSnackbar(t('features.settings.myAccount.resetPasswordDialog.messageError'), { severity: 'error' })
      notifySentry(err, { inputEmail: account.email })
    } finally {
      setLoading(false)
      handleClose()
    }
  }

  return (
    <DialogWrapper
      open={open}
      handleClose={handleClose}
      title={t('features.settings.myAccount.resetPasswordDialog.title')}
      mainContent={<p className='text-sm'>{t('features.settings.myAccount.resetPasswordDialog.description', { email: account.email })}</p>}
      onSubmit={handleSubmit}
      loading={loading}
      cancelButtonText={t('features.settings.myAccount.resetPasswordDialog.cancel')}
      submitButtonText={t('features.settings.myAccount.resetPasswordDialog.submit')}
    />
  )
}
