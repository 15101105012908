import * as React from 'react'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from './ui/breadcrumb'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
  title: string
  origin?: { title: string; path: string }
  menuComponent?: React.ReactNode
}

export const PageHeader: FC<Props> = ({ title, origin, menuComponent }) => {
  const navigate = useNavigate()

  return (
    <>
      <header className='flex h-14 shrink-0 items-center gap-2 sticky top-0 bg-white z-10 border-b px-6'>
        <div className='flex flex-1 items-center gap-2'>
          <Breadcrumb>
            <BreadcrumbList>
              {origin && (
                <>
                  <BreadcrumbItem className='hidden md:block'>
                    <BreadcrumbLink onClick={() => navigate(origin.path)} className='cursor-pointer'>
                      {origin.title}
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                  <BreadcrumbSeparator className='hidden md:block' />
                </>
              )}
              <BreadcrumbItem>
                <BreadcrumbPage>{title}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
        <div className='flex items-center ml-auto'>{menuComponent}</div>
      </header>
    </>
  )
}
