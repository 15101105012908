import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Table, TableBody, TableCell, TableHeader, TableRow } from '@/components/ui/table'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { CustomerSegmentService } from '@/gen/proto/customer_segment/customer_segment_pb'
import { useAuthUser } from '@/hooks/useAuthUser'
import { useCustomSnackbar } from '@/hooks/useCustomSnackbar'
import { useGrpcClient } from '@/hooks/useGrpcClient'
import { useSentryNotifier } from '@/hooks/useSentryNotifier'
import { SkeletonTable } from 'components/skeletonTable'
import { UpgradeRecommendDialog } from 'components/upgradeRecommendDialog'
import { billingStatus } from 'config/plan'
import dayjs from 'dayjs'
import { getIdToken } from 'firebase/auth'
import { orderBy, query } from 'firebase/firestore'
import { ShopBillingStatus } from 'gen/firestore'
import { useAccount } from 'hooks/useAccount'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCsvDownload } from 'hooks/useCsvDownload'
import { useCurrency } from 'hooks/useCurrency'
import { useCollection } from 'hooks/useFirestoreData'
import { Download } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { formatDate } from 'utils/timeUtil'
import { MonthlyCohortSummaryType, convertMonthlyCohortSummary } from '../../types/monthlyCohort'
import { calculateStartEndYearMonth, TimeRange } from '../../types/timeRange'

enum MetricsIndex {
  LTV = 'ltv',
  F2_REPEAT_RATE = 'f2RepeatRate',
  F3_REPEAT_RATE = 'f3RepeatRate',
  F4_REPEAT_RATE = 'f4RepeatRate',
  F5_REPEAT_RATE = 'f5RepeatRate',
}

export const MonthlyCohort = () => {
  const { t, i18n } = useTranslation()
  const authUser = useAuthUser()
  const { account, shop } = useAccount()
  const { formatCurrency } = useCurrency()
  const shopBillingStatus = billingStatus(shop, dayjs())
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const [monthlyCohortSummary, setMonthlyCohortSummary] = useState<MonthlyCohortSummaryType>()
  const [customerSegmentId, setCustomerSegmentId] = useState<string>(searchParams.get('customer_segment_id') || '')
  const [timeRange, setTimeRange] = useState<TimeRange>(TimeRange.last12Months)
  const [metricsIndex, setMetricsIndex] = useState<MetricsIndex>(MetricsIndex.LTV)
  const [upgradeRecommendDialogOpen, setUpgradeRecommendDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const customerSegmentService = useGrpcClient(CustomerSegmentService)
  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: customerSegments } = useCollection(query(customerSegmentsRef, orderBy('createdAt', 'desc')))

  useEffect(() => {
    setLoading(true)
    const { startYearMonth, endYearMonth } = calculateStartEndYearMonth(timeRange)
    const querySet = customerSegments?.find((cs) => cs.ref.id === customerSegmentId)?.querySet || ''
    const fetch = async () => {
      try {
        const token = await getIdToken(authUser!)
        const resp = await customerSegmentService.estimateMonthlyCohort(
          { querySet, startYearMonth, endYearMonth },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        setMonthlyCohortSummary(convertMonthlyCohortSummary(resp, i18n.language))
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      } finally {
        setLoading(false)
      }
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegmentId, timeRange])

  const getStepValue = (step: number): string => {
    return step === -1 ? t('features.cohorts.monthlyCohort.stepFirst') : t('features.cohorts.monthlyCohort.stepNth', { step: step })
  }

  const getMetricsValue = (step: number, metrics: number): string => {
    switch (metricsIndex) {
      case MetricsIndex.LTV:
        return formatCurrency(metrics) || ''
      case MetricsIndex.F2_REPEAT_RATE:
      case MetricsIndex.F3_REPEAT_RATE:
      case MetricsIndex.F4_REPEAT_RATE:
      case MetricsIndex.F5_REPEAT_RATE:
        // Do not display the Fn repeat rate of the first purchase because it is always 0%
        if (step === -1) return '-'
        return `${(metrics * 100).toFixed(1)}%`
    }
  }

  const getOpacityClassName = (step: number, value: number): string => {
    if (step === -1 && metricsIndex !== MetricsIndex.LTV) return 'bg-opacity-0'
    const maxMetricValue = monthlyCohortSummary
      ? Math.max(...monthlyCohortSummary.monthlyResult.flatMap((mr) => mr.stepAndMetricsList.map((sm) => sm[metricsIndex])))
      : 0
    const scale = value / maxMetricValue
    if (scale >= 0.8) return 'bg-opacity-100'
    if (scale >= 0.6) return 'bg-opacity-80'
    if (scale >= 0.4) return 'bg-opacity-60'
    if (scale >= 0.2) return 'bg-opacity-40'
    return 'bg-opacity-20'
  }

  const { handleDownload, loading: downloadLoading } = useCsvDownload({
    data:
      monthlyCohortSummary &&
      monthlyCohortSummary.monthlyResult.flatMap((mr) => {
        return mr.stepAndMetricsList.map((sm) => {
          return {
            firstOrderYearMonth: mr.firstOrderYearMonth,
            step: getStepValue(sm.step),
            ltv: sm.ltv,
            orderValue: sm.orderValue,
            customerCount: sm.customerCount,
            f2CustomerCount: sm.f2CustomerCount,
            f3CustomerCount: sm.f3CustomerCount,
            f4CustomerCount: sm.f4CustomerCount,
            f5CustomerCount: sm.f5CustomerCount,
          }
        })
      }),
    headers: [
      t('features.cohorts.monthlyCohort.firstOrderYearMonth'),
      t('features.cohorts.monthlyCohort.step'),
      t('features.cohorts.monthlyCohort.ltv'),
      t('features.cohorts.monthlyCohort.sales'),
      t('features.cohorts.monthlyCohort.firstCustomerCount'),
      t('features.cohorts.monthlyCohort.f2CustomerCount'),
      t('features.cohorts.monthlyCohort.f3CustomerCount'),
      t('features.cohorts.monthlyCohort.f4CustomerCount'),
      t('features.cohorts.monthlyCohort.f5CustomerCount'),
    ],
    fileName: t('features.cohorts.monthlyCohort.downloadFileName', { date: formatDate(dayjs(), i18n.language) }),
    page: 'cohort_monthly',
  })

  return (
    <>
      <div className='flex items-center justify-between mb-6'>
        <div className='flex items-center gap-2'>
          <Select value={customerSegmentId} onValueChange={(value) => setCustomerSegmentId(value)} disabled={!customerSegments}>
            <SelectTrigger>
              <SelectValue placeholder={t('features.cohorts.segment_all')} />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>{t('features.cohorts.segment')}</SelectLabel>
                {customerSegments?.map((segment) => (
                  <SelectItem key={segment.ref.id} value={segment.ref.id}>
                    {segment.name}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
          <Select
            value={timeRange}
            onValueChange={(value) => {
              setTimeRange(value as TimeRange)
            }}
          >
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>{t('features.cohorts.timeRange')}</SelectLabel>
                {Object.values(TimeRange).map((timeRange) => (
                  <SelectItem key={timeRange} value={timeRange}>
                    {t(`features.cohorts.timeRange_${timeRange}`)}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
          <Select
            value={metricsIndex}
            onValueChange={(value) => {
              setMetricsIndex(value as MetricsIndex)
            }}
          >
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>{t('features.cohorts.metric')}</SelectLabel>
                {Object.values(MetricsIndex).map((metric) => (
                  <SelectItem key={metric} value={metric}>
                    {t(`features.cohorts.monthlyCohort.${metric}`)}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant='ghost'
              size='icon'
              disabled={downloadLoading || !monthlyCohortSummary || monthlyCohortSummary.avgResult.length === 0}
              onClick={() => {
                if (shopBillingStatus === ShopBillingStatus.free) {
                  setUpgradeRecommendDialogOpen(true)
                  return
                }
                handleDownload()
              }}
            >
              <Download />
            </Button>
          </TooltipTrigger>
          <TooltipContent>{t('features.customerSegments.overview.download')}</TooltipContent>
        </Tooltip>
      </div>

      {loading || !monthlyCohortSummary ? (
        <Card className='p-6'>
          <SkeletonTable rowCount={15} columnCount={14} />
        </Card>
      ) : (
        monthlyCohortSummary && (
          <Card className='p-6'>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableCell className='min-w-[120px]'>{t('features.cohorts.monthlyCohort.firstOrderYearMonth')}</TableCell>
                  <TableCell className='min-w-[80px] text-center'>{t('features.cohorts.monthlyCohort.customerCount')}</TableCell>
                  {monthlyCohortSummary.avgResult.map((ar) => (
                    <TableCell key={`monthlyCohort-${ar.step}`} className='text-center'>
                      {getStepValue(ar.step)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {monthlyCohortSummary.monthlyResult.map((mr) => (
                  <TableRow key={`monthlyCohort-${mr.firstOrderYearMonth}`}>
                    <TableCell>{mr.firstOrderYearMonth}</TableCell>
                    <TableCell className='text-center'>{mr.stepAndMetricsList[0].customerCount}</TableCell>
                    {mr.stepAndMetricsList.map((sm) => (
                      <TableCell
                        key={`monthlyCohort-${mr.firstOrderYearMonth}-${sm.step}`}
                        className={`text-center bg-indigo-200 ${getOpacityClassName(sm.step, sm[metricsIndex])}`}
                      >
                        {getMetricsValue(sm.step, sm[metricsIndex])}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
                <TableRow className='bg-gray-50'>
                  <TableCell>{t('features.cohorts.monthlyCohort.all')}</TableCell>
                  <TableCell className='text-center'>{monthlyCohortSummary.avgResult[0].customerCount}</TableCell>
                  {monthlyCohortSummary.avgResult.map((ar) => (
                    <TableCell
                      key={`monthlyCohort-avg-${ar.step}`}
                      className={`text-center bg-indigo-300 ${getOpacityClassName(ar.step, ar[metricsIndex])}`}
                    >
                      {getMetricsValue(ar.step, ar[metricsIndex])}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </Card>
        )
      )}

      {upgradeRecommendDialogOpen && (
        <UpgradeRecommendDialog open={upgradeRecommendDialogOpen} handleClose={() => setUpgradeRecommendDialogOpen(false)} referrer='monthlyCohort' />
      )}
    </>
  )
}
