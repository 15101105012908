import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table'
import { GuideIcon } from 'components/guideIcon'
import { CustomerSegmentState } from 'features/customerSegments/types/types'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getImagePath } from 'utils/imageUtil'
import { formatDateTime, timestampToDayjs } from 'utils/timeUtil'
import { ActivateAutoSyncDialog } from './components/activateAutoSyncDialog'
import { DeactivateAutoSyncDialog } from './components/deactivateAutoSyncDialog'

type Props = {
  customerSegment: CustomerSegmentState | undefined
  currentTagRunning: boolean
  otherTagsRunning: boolean
}

export const Sync: FC<Props> = ({ customerSegment, currentTagRunning, otherTagsRunning }) => {
  const { t, i18n } = useTranslation()

  const [activateDialogOpen, setActivateDialogOpen] = useState(false)
  const [deactivateDialogOpen, setDeactivateDialogOpen] = useState(false)

  const tagProcessTimePerCustomerInMinutes = 18 / 50 / 60
  const estimatedProcessingDurationInMinutes = customerSegment?.metrics?.count
    ? Math.ceil(customerSegment.metrics.count * tagProcessTimePerCustomerInMinutes) + 3
    : 5 // 50 customers per 18 seconds plus 3 min for initializing gcp batch

  return (
    <>
      <Card>
        <CardHeader>
          <div className='flex items-center justify-between'>
            <div className='flex items-center gap-1'>
              <img src={getImagePath('shopify_logo.svg')} alt='shopify-logo' width='20px' />
              <CardTitle>{t('features.customerSegments.detail.sync.title')}</CardTitle>
              <GuideIcon guideType='SegmentSync' />
            </div>
            <Button
              variant={customerSegment?.tagOperationSetting?.isActive ? 'outline' : 'default'}
              size='sm'
              disabled={currentTagRunning || otherTagsRunning}
              onClick={() => (customerSegment?.tagOperationSetting?.isActive ? setDeactivateDialogOpen(true) : setActivateDialogOpen(true))}
            >
              {customerSegment?.tagOperationSetting?.isActive
                ? t('features.customerSegments.detail.sync.deactivate')
                : t('features.customerSegments.detail.sync.activate')}
            </Button>
          </div>
        </CardHeader>
        <CardContent>
          {customerSegment && customerSegment.tagOperationSetting && (
            <Table>
              <TableBody>
                <TableRow className='border-none'>
                  <TableCell className='w-[180px]'>{t('features.customerSegments.detail.sync.status')}</TableCell>
                  <TableCell>
                    {currentTagRunning
                      ? t('features.customerSegments.detail.sync.running', { duration: estimatedProcessingDurationInMinutes })
                      : customerSegment.tagOperationSetting.isActive
                        ? t('features.customerSegments.detail.sync.active')
                        : t('features.customerSegments.detail.sync.inactive')}
                  </TableCell>
                </TableRow>
                <TableRow className='border-none'>
                  <TableCell>{t('features.customerSegments.detail.sync.tag')}</TableCell>
                  <TableCell>{customerSegment.tagOperationSetting.tagName || '-'}</TableCell>
                </TableRow>
                <TableRow className='border-none'>
                  <TableCell>{t('features.customerSegments.detail.sync.lastSyncedAt')}</TableCell>
                  <TableCell>
                    {customerSegment.tagOperationSetting.lastSyncedAt
                      ? formatDateTime(timestampToDayjs(customerSegment.tagOperationSetting.lastSyncedAt), i18n.language)
                      : '-'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>

      {activateDialogOpen && customerSegment && customerSegment.tagOperationSetting && (
        <ActivateAutoSyncDialog
          open={activateDialogOpen}
          handleClose={() => setActivateDialogOpen(false)}
          customerSegmentId={customerSegment.id}
          customerSegmentName={customerSegment.name}
          estimatedProcessingDurationInMinutes={estimatedProcessingDurationInMinutes}
        />
      )}

      {deactivateDialogOpen && customerSegment && customerSegment.tagOperationSetting && (
        <DeactivateAutoSyncDialog
          open={deactivateDialogOpen}
          handleClose={() => setDeactivateDialogOpen(false)}
          customerSegmentId={customerSegment.id}
          tagName={customerSegment.tagOperationSetting.tagName}
          estimatedProcessingDurationInMinutes={estimatedProcessingDurationInMinutes}
        />
      )}
    </>
  )
}
